import { inject } from '@angular/core';
import { NavigationService } from '../../../navigation/navigation.service';
import { SolisSessionService } from '../../../session/solis-session.service';

export const annuityCompareGuard = () => {
  const allowCompare = inject(SolisSessionService).getSettingValue<boolean>('ProductCompare.Enabled');
  if (allowCompare) {
    return true;
  }
  // This prevents navigation to home if settings have not been initialized
  if (allowCompare === null) {
    inject(NavigationService).navigateHome();
  }
  return false;
};
