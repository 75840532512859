<div [class]="'simple-modal ' + simpleModal.class">
  <div class="header">
    <span>{{ simpleModal.title }}</span>
    @if (simpleModal.closable) {
      <span class="close-button fa-solid fa-xmark" (click)="valueSubject.complete()"></span>
    }
  </div>

  <div [class]="'message ' + (simpleModal.messageClass ? simpleModal.messageClass : '')">
    <i [class]="'icon ' + simpleModal.icon"></i>
    <span>{{ simpleModal.message }}</span>
    @if (innerMessageHtml) {
      <div [innerHtml]="innerMessageHtml"></div>
    }
  </div>

  <div [class]="'button-container ' + (simpleModal.buttonContainerClass ? simpleModal.buttonContainerClass : '')">
    @for (button of simpleModal.buttons; track button) {
      <div>
        <button [class]="button.class" (click)="valueSubject.next(button.value)">{{ button.label }}</button>
      </div>
    }
  </div>
</div>
