import { Injectable } from '@angular/core';
import { Subject, filter, map, Subscription } from 'rxjs';
import { BusEvent, BusRequest } from './event-bus.models';

@Injectable({
  providedIn: 'root'
})
export class EventBus {
  private currentValues: Map<string, any> = new Map<string, any>();
  private subjects: Map<string, Subject<any>> = new Map<string, Subject<any>>();
  private requestHandlers: Map<string, any> = new Map<string, any>();

  emit<T = void>(event: BusEvent<T>): void {
    // Get subject for this event type
    let subject = this.subjects.get(event.type);

    if (!subject) {
      // If subject doesn't exist yet, create it
      subject = new Subject<BusEvent<T>>();
      this.subjects.set(event.type, subject);
    }

    // Set the "current value" of the subject
    // This mimics a BehaviorSubject but allows individual subscribers
    // to choose whether they want the current value replayed on subsription
    this.currentValues.set(event.type, event);

    // Fire event
    subject.next(event);
  }

  on<T = void>(eventType: string, action: any, replayCurrentValueOnSubscribe: boolean = false): Subscription {
    let subject = this.subjects.get(eventType);

    if (!subject) {
      // If it doesn't exist yet, create it primed with a null value
      // The filter below will make sure it doesn't get emitted
      subject = new Subject<BusEvent<T>>();
      this.subjects.set(eventType, subject);

      // Set the "current value" of the subject
      // This mimics a BehaviorSubject but allows individual subscribers
      // to choose whether they want the current value replayed on subsription
      this.currentValues.set(eventType, null);
    }

    // Hook up the subscription
    const subscription = subject
      .pipe(
        filter((e) => e !== null && e !== undefined),
        map((e) => (e as BusEvent<T>).payload)
      )
      .subscribe(action);

    // The caller who is currently subscribing may want to get the current value
    // for init purposes. If provided, send out the current value
    const currentValue = this.currentValues.get(eventType);

    if (replayCurrentValueOnSubscribe && currentValue) {
      action(currentValue.payload);
    }

    return subscription;
  }

  hasRequestHandler(requestType: string): boolean {
    const handler = this.requestHandlers.get(requestType);

    return handler !== undefined && handler !== null;
  }

  sendRequest<TRequest = void, TResponse = void>(request: BusRequest<TRequest>): TResponse {
    const handler = this.requestHandlers.get(request.type);

    if (!handler) {
      throw Error(`There is no handler registered for '${request.type}'.`);
    }

    return handler(request);
  }

  registerRequestHandler<TRequest = void, TResponse = void>(
    type: string,
    requestHandler: (request: BusRequest<TRequest>) => TResponse
  ): void {
    const handler = this.requestHandlers.get(type);

    if (handler) {
      throw Error(
        `There is already a handler registered for '${type}'. You may have forgotten to unregister the handler via '${this.unregisterRequestHandler.name}' when the component was last destroyed.`
      );
    }

    this.requestHandlers.set(type, requestHandler);
  }

  unregisterRequestHandler(type: string): void {
    this.requestHandlers.delete(type);
  }

  clearValues(type: string): void {
    this.currentValues.delete(type);
  }
}
