import { Component, ElementRef, ViewChild, Injector } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ComponentBase } from 'framework';

import { PartnerNavbarComponent } from '../../components/partner/navbar/partner-navbar.component';
import { PartnerFooterComponent } from '../../components/partner/footer/partner-footer.component';
import { PartnerSubHeaderComponent } from '../../components/partner/sub-header/partner-sub-header.component';
import { ImpersonationComponent } from '../../components/common/impersonation/impersonation.component';
import { PartnerLayoutViewModel } from './partner-layout.view-models';

@Component({
  selector: 'app-partner-layout',
  standalone: true,
  imports: [RouterModule, PartnerNavbarComponent, ImpersonationComponent, PartnerSubHeaderComponent, PartnerFooterComponent],
  providers: [PartnerLayoutViewModel],
  templateUrl: './partner-layout.component.html',
  styleUrls: ['./partner-layout.component.scss']
})
export class PartnerLayoutComponent extends ComponentBase<PartnerLayoutViewModel> {
  @ViewChild('partnerContent') set contentWindow(value: ElementRef) {
    this.viewModel.contentWindow = value;
  }
  constructor(
    protected override injector: Injector,
    viewModel: PartnerLayoutViewModel
  ) {
    super(injector, viewModel);
  }
}
