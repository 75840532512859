export class RequestApiCallCancelEvent {
  apiCallNames: string[] = [];

  constructor(...apiCallNames: string[]) {
    this.apiCallNames = apiCallNames; // May not be needed -> || [];
  }
}

export class ApiActivityChangedEvent {
  inFlightRequests: string[];

  public get inFlightRequestCount(): number {
    return this.inFlightRequests.length;
  }

  public get isApiBusy(): boolean {
    return this.inFlightRequestCount > 0;
  }

  constructor(inFlightRequests: string[]) {
    this.inFlightRequests = inFlightRequests;
  }

  isInFlight(apiCallName: string) {
    return this.inFlightRequests.includes(apiCallName);
  }
}
