import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PostMessageService {
  private defaultSendingPlatform: string = 'fidx';
  private defaultSendingApplication: string = 'fidx-protection-intelligence';

  constructor() {}

  sendCloseMessage(originUrl = '*') {
    this.sendPostMessage(
      {
        type: 'close-and-exit',
        component: 'website',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: {}
      },
      originUrl
    );
  }

  sendContractToResearchCenterMessage(cusip: string, productCode: string, originUrl = '*'): void {
    this.sendPostMessage(
      {
        type: 'contract-to-researchCenter',
        component: 'productselection',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { cusip, productCode }
      },
      originUrl
    );
  }

  sendProposalStrategyDecisionTreeMessage(
    status: string,
    platformProposalId: string,
    proposalDetailId: string,
    source: string,
    proposalStrategyTypes: string | null,
    compensationType: string | null,
    annuityType: string | null,
    originUrl = '*'
  ): void {
    this.sendPostMessage(
      {
        type: 'decision-tree-responses',
        component: 'decision-tree',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: {
          status,
          platformProposalId,
          proposalDetailId,
          source,
          proposalStrategyTypes,
          compensationType,
          annuityType
        }
      },
      originUrl
    );
  }

  sendCaseProfileToResearchCenterMessage(cusip: string, productCode: string, originUrl = '*'): void {
    this.sendPostMessage(
      {
        type: 'case-profile-to-researchCenter',
        component: 'productselection',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { cusip, productCode }
      },
      originUrl
    );
  }

  sendCaseProfileContinueMessage(platformProposalId: string, proposalRequestId: string, originUrl = '*'): void {
    this.sendPostMessage(
      {
        type: 'continue-button',
        component: 'productdetail',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { proposalRequestId, platformProposalId }
      },
      originUrl
    );
  }

  sendSaveForCompareMessage(
    cusip: string,
    productCode: string,
    platformProposalId: string,
    proposalRequestId: string,
    platformProductId: string,
    originUrl = '*'
  ): void {
    this.sendPostMessage(
      {
        type: 'save-proposal-details-and-compare',
        component: 'productdetail',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { cusip, productCode, platformProposalId, proposalRequestId, platformProductId }
      },
      originUrl
    );
  }

  sendSaveForLaterMessage(
    cusip: string,
    productCode: string,
    platformProposalId: string,
    proposalRequestId: string,
    platformProductId: string,
    originUrl = '*'
  ): void {
    this.sendPostMessage(
      {
        type: 'save-proposal-details',
        component: 'productdetail',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { cusip, productCode, platformProposalId, proposalRequestId, platformProductId }
      },
      originUrl
    );
  }

  sendSaveAndResearchMessage(
    cusip: string,
    productCode: string,
    platformProposalId: string,
    proposalRequestId: string,
    platformProductId: string,
    originUrl = '*'
  ): void {
    this.sendPostMessage(
      {
        type: 'save-proposal-details-and-research',
        component: 'productdetail',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { cusip, productCode, platformProposalId, proposalRequestId, platformProductId }
      },
      originUrl
    );
  }

  sendResearchProductMessage(cusip: string, productCode: string, originUrl = '*'): void {
    this.sendPostMessage(
      {
        type: 'research-product',
        component: 'productselection',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { cusip, productCode }
      },
      originUrl
    );
  }

  sendSelectProductMessage(cusip: string, productCode: string, originUrl = '*'): void {
    this.sendPostMessage(
      {
        type: 'select-product',
        component: 'productselection',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { cusip, productCode }
      },
      originUrl
    );
  }

  sendSelectGuidanceDeskProductMessage(firmName: string, firmType: string, dtccMemberCode: string, aliasId: string, originUrl = '*'): void {
    this.sendPostMessage(
      {
        type: 'select-product',
        component: 'productselection',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { firmName, firmType, dtccMemberCode, aliasId }
      },
      originUrl
    );
  }

  sendGuidanceDeskDetailsMessage(
    firmName: string,
    firmType: string,
    dtccMemberCode: string,
    aliasId: string,
    caseInstructions: string,
    originUrl = '*'
  ): void {
    this.sendPostMessage(
      {
        type: 'save-imo-oid-details',
        component: 'productdetails',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { firmName, firmType, dtccMemberCode, aliasId, caseInstructions }
      },
      originUrl
    );
  }

  sendIllustrationCreatedMessage(
    cusip: string,
    productCode: string,
    bypassIllustrationReason: string,
    illustrationDocumentId: string,
    platformProductId: string,
    platformProposalId: string,
    proposalIllustrationId: number,
    proposalIllustrationRequestId: string, // ProposalDetailId
    proposalRequestId: string,
    originUrl = '*'
  ): void {
    this.sendPostMessage(
      {
        type: 'illustration-created',
        component: 'productdetail',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: {
          bypassIllustrationReason,
          cusip,
          illustrationDocumentId,
          platformProductId,
          platformProposalId,
          productCode,
          proposalIllustrationId,
          proposalIllustrationRequestId,
          proposalRequestId
        }
      },
      originUrl
    );
  }

  sendGuidanceDeskSelectedMessage(platformProposalId: string, proposalRequestId: string, originUrl = '*'): void {
    this.sendPostMessage(
      {
        type: 'partner-selected',
        component: 'productselection',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { proposalRequestId, platformProposalId }
      },
      originUrl
    );
  }

  sendGuidanceDeskRejectedMessage(platformProposalId: string, proposalRequestId: string, originUrl = '*'): void {
    this.sendPostMessage(
      {
        type: 'partner-rejected',
        component: 'productdetail',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { proposalRequestId, platformProposalId }
      },
      originUrl
    );
  }

  sendThemeChangeMessage(mode: string, originUrl = '*'): void {
    this.sendPostMessage(
      {
        type: 'theme',
        sendingPlatform: this.defaultSendingPlatform,
        sendingApplication: this.defaultSendingApplication,
        payload: { mode }
      },
      originUrl
    );
  }

  private sendPostMessage(data: any, originUrl: string): void {
    // TODO: Can remove console log if no longer has any need for debugging
    console.log(`IX sending post message to ${originUrl}`, JSON.stringify(data));
    parent.postMessage(data, originUrl);
  }
}
