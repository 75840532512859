export enum SolisLaunchMethod {
  Embed = 'Embed', // Was launched from SSO and embedded (iframed) into another website
  Standalone = 'Standalone', // Was launched from SSO but is showing in its own window
  Direct = 'Direct' // Was not launched from SSO
}

export enum SolisFlow {
  PurchaseAnnuity = 'PurchaseAnnuity',
  AdHocIllustration = 'AdHocIllustration',
  InforceTransaction = 'InforceTransation',
  ProductReplacement = 'ProductReplacement'
}

export enum SolisDestinationPage {
  ProductSelection = 'ProductSelection',
  ProductDetails = 'ProductDetails',
  ProductCompare = 'ProductCompare',
  ProductResearch = 'ProductResearch',
  ProductReplacement = 'ProductReplacement',
  Dashboard = 'Dashboard',
  AnnuityContract = 'AnnuityContract',
  Inforce = 'Inforce',
  AccountOpening = 'AccountOpening',
  ProposalSummary = 'ProposalSummary',
  InsuranceWorkbench = 'InsuranceWorkbench',
  LandingPage = 'LandingPage',
  CaseProfile = 'CaseProfile',
  ProposalStrategy = 'ProposalStrategy',
  IXHub = 'IXHub'
}

export enum SolisSessionType {
  Advisor = 'Advisor',
  Partner = 'Partner'
}
