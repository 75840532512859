export class FormHelper {
  // SET THE POSITION OF THE CURSOR BASED ON THE TYPE OF INPUT
  static setCursorPosition(event: any, type: string): void {
    const startPosition = event.target.selectionStart;
    const endPosition = event.target.selectionEnd;
    const valLengthWithoutMask = event.target.value.replace(/\D+/g, '').length;

    switch (type.toUpperCase()) {
      case 'SSN':
        FormHelper.cursorPositionSsn(event, startPosition, endPosition, valLengthWithoutMask);
        break;
      case 'TIN':
        FormHelper.cursorPositionTin(event, startPosition, endPosition, valLengthWithoutMask);
        break;
      case 'MOBILE_DASH':
        FormHelper.cursorPositionMobileDash(event, startPosition, endPosition, valLengthWithoutMask);
        break;
      case 'PHONE':
        FormHelper.cursorPositionPhone(event, startPosition, endPosition, valLengthWithoutMask);
        break;
      case 'ZIPCODE':
      case 'MOBILE':
      case 'SSN_NO_DASH':
        FormHelper.cursorPositionOther(event, startPosition, endPosition, valLengthWithoutMask);
        break;
      default:
        break;
    }
  }

  static cursorPositionSsn(event: any, start: number, end: number, lengthWithoutMask: number): void {
    FormHelper.processMultiCursorPosition(event, start, end, lengthWithoutMask, 3, 5, 1, 2, 0);
  }

  static cursorPositionTin(event: any, start: number, end: number, lengthWithoutMask: number): void {
    FormHelper.processMultiCursorPosition(event, start, end, lengthWithoutMask, 2, 3, 1, 1, 0);
  }

  static cursorPositionPhone(event: any, start: number, end: number, lengthWithoutMask: number): void {
    FormHelper.processMultiCursorPosition(event, start, end, lengthWithoutMask, 3, 6, 3, 4, 1);
  }

  static cursorPositionMobileDash(event: any, start: number, end: number, lengthWithoutMask: number): void {
    FormHelper.processMultiCursorPosition(event, start, end, lengthWithoutMask, 3, 6, 1, 2, 0);
  }

  static cursorPositionOther(event: any, start: number, end: number, lengthWithoutMask: number): void {
    FormHelper.processSingleCursorPosition(event, start, end, lengthWithoutMask);
  }

  static processSingleCursorPosition(event: any, start: number, end: number, lengthWithoutMask: number): void {
    event.target.selectionEnd = event.target.selectionStart = lengthWithoutMask;
    if (start <= lengthWithoutMask) {
      event.target.startPosition = event.target.selectionStart = start;
      event.target.endPosition = event.target.selectionEnd = end;
    }
  }

  static processMultiCursorPosition(
    event: any,
    start: number,
    end: number,
    lengthNoMask: number,
    lowRange: number,
    highRange: number,
    extMiddle: number,
    extHigh: number,
    extLow: number
  ): void {
    if (start > lengthNoMask) {
      if (lengthNoMask >= lowRange && lengthNoMask < highRange) {
        event.target.selectionEnd = event.target.selectionStart = lengthNoMask + extMiddle;
      } else if (lengthNoMask >= highRange) {
        event.target.selectionEnd = event.target.selectionStart = lengthNoMask + extHigh;
      } else {
        event.target.selectionEnd = event.target.selectionStart = lengthNoMask + extLow;
      }
    } else {
      event.target.startPosition = event.target.selectionStart = start;
      event.target.endPosition = event.target.selectionEnd = end;
    }
  }
}
