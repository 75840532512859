import { Injectable, Injector, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventBus } from '../services/event-bus/event-bus';
import { BusEvent, BusRequest } from '../services/event-bus/event-bus.models';
import { SubscriptionService } from '../services/subscription-service/subscription.service';
import { ToastService } from '../services/toast-service/toast.service';

@Injectable()
export abstract class ViewModelBase implements IStringIndex {
  protected injector!: Injector;

  /* #region Lazy Loaded Services *******************************/

  protected get route(): ActivatedRoute {
    this._route = this._route ?? this.injector.get(ActivatedRoute);
    return this._route;
  }

  protected get router(): Router {
    this._router = this._router ?? this.injector.get(Router);
    return this._router;
  }

  protected get toastService(): ToastService {
    this._toastService = this._toastService ?? this.injector.get(ToastService);
    return this._toastService;
  }

  protected get eventBus(): EventBus {
    this._eventBus = this._eventBus ?? this.injector.get(EventBus);
    return this._eventBus;
  }

  protected get subscriptionService(): SubscriptionService {
    this._subscriptionService = this._subscriptionService ?? new SubscriptionService(this.injector);
    return this._subscriptionService;
  }

  protected _toastService: ToastService | null = null;
  protected _route: ActivatedRoute | null = null;
  protected _router: Router | null = null;
  protected _eventBus: EventBus | null = null;
  protected _subscriptionService: SubscriptionService | null = null;

  /* #endregion *************************************************/

  public isInitializing: boolean = true;
  public isInitialized: boolean = false;

  // override this to check things to see if the user should be here or not.
  // Anything not null will cause a toast to be displayed and the user will be navigated back to their home
  public validateState(): boolean {
    return true;
  }

  // Implement this to initialize everything
  public abstract initialize(): void;

  // override this to create subscriptions
  public initializeSubscriptions(subscriptionService: SubscriptionService) {}

  // override this for custom AfterViewInit logic
  public afterInitialize() {}

  // override this for custom OnDestroy logic
  public destroy() {}

  // override this to handle when OnChanges occurs for a particular property
  public changed(name: string, previousValue: any, newValue: any, firstChange: boolean) {}

  // override this for custom OnChanges logic
  public changes(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName) && this.hasOwnProperty(propName)) {
        (this as IStringIndex)[propName] = changes[propName].currentValue;
        this.changed(propName, changes[propName].previousValue, changes[propName].currentValue, changes[propName].firstChange);
      }
    }
  }

  // Utilizes the internal EventBus to Emit a BusEvent type and payload
  protected sendEvent<T>(type: string, payload: T | null = null) {
    this.eventBus.emit(new BusEvent<T>(type, payload));
  }

  // Utilized the internal EventBus to SendRequest of a given BusRequest and payload
  protected sendRequest<T = null, U = void>(type: string, payload: T | null = null) {
    return this.eventBus.sendRequest<T, U>(new BusRequest<T>(type, payload));
  }

  /* #region Internal Functionality that should not be called or overridden in a ViewModel implementation */

  public reinitialize() {
    this.subscriptionService.destroy();
    this.internalInitializeProcess();
  }

  public internalInject(injector: Injector) {
    this.injector = injector;
  }

  public internalInitializeProcess() {
    this.isInitializing = true;

    this.internalInitialize();
    this.initializeSubscriptions(this.subscriptionService);

    this.isInitializing = false;
    this.isInitialized = true;
  }

  abstract internalValidateState(): void;

  // should only be overridden by internal types such as FormViewModel
  protected internalInitialize() {
    this.initialize();
  }

  public internalDestroy() {
    this.destroy();
    this.subscriptionService.destroy();
  }

  /* #endregion */
}

interface IStringIndex {
  [key: string]: any;
}
