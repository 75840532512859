import { Component, Injector, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { TermsAndConditionsViewModel } from './terms-and-conditions.view-models';
import { ComponentBase, IModalValueProvider } from 'framework';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [CheckboxModule, FormsModule, ReactiveFormsModule],
  providers: [TermsAndConditionsViewModel],
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss'
})
export class TermsAndConditionsComponent extends ComponentBase<TermsAndConditionsViewModel> implements IModalValueProvider<any> {
  @Input() set valueSubject(value: Subject<any | undefined>) {
    this.viewModel.valueSubject = value;
  }
  constructor(
    protected override injector: Injector,
    viewModel: TermsAndConditionsViewModel
  ) {
    super(injector, viewModel);
  }
}
