export class SolisSessionTeamMember {
  role?: SolisSessionTeamMemberRole;
  platformUniqueId?: string | null = null;
  email?: string | null = null;
  initialAllocation?: number | null = null;
  finalAllocation?: number | null = null;
  firstName?: string | null = null;
  lastName?: string | null = null;
  ssn?: string | null = null;
  npn?: string | null = null;
  phoneNumber1?: string | null = null;
  includeInProposal?: boolean | null = null;
  residentStateCode?: string | null = null;
  userId?: string | null = null;

  constructor(init?: Partial<SolisSessionTeamMember>) {
    Object.assign(this, init);
  }
}

export enum SolisSessionTeamMemberRole {
  Primary = 'Primary',
  Additional = 'Additional'
}

export enum SolisSessionPartnerUserRole {
  Admin = 'Admin',
  Agent = 'Agent',
  Assistant = 'Assistant',
  Reviewer = 'Reviewer'
}
