import { ProposalDetailPartyPersonDto } from '../services/gateway-api-client/dtos/proposal.dtos';

interface IProposalTeamModel {
  team?: ProposalDetailPartyPersonDto[];
}

export class ProposalTeamModel implements IProposalTeamModel {
  team: ProposalDetailPartyPersonDto[];

  constructor(options: IProposalTeamModel = {}) {
    this.team = options.team || [];
  }
}

interface ILicensingConfiguration {
  lockPrimaryAdvisor?: boolean;
  lockSelectedAdvisor?: boolean;
  excludeZeroSplitPercent?: boolean;
}

export class LicensingConfiguration {
  lockPrimaryAdvisor: boolean;
  lockSelectedAdvisor: boolean;
  excludeZeroSplitPercent: boolean;

  constructor(options: ILicensingConfiguration = {}) {
    this.lockPrimaryAdvisor = options.lockPrimaryAdvisor || false;
    this.lockSelectedAdvisor = options.lockSelectedAdvisor || false;
    this.excludeZeroSplitPercent = options.excludeZeroSplitPercent || false;
  }
}
