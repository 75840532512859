import { Injectable, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd } from '@angular/router';

import { SubscriptionService } from 'framework';

import { EventNames } from '../../constants/event-names';

import { SolisViewModelBase } from '../../components/solis-view-model.base';

@Injectable()
export class AdvisorLayoutViewModel extends SolisViewModelBase {
  contentWindow!: ElementRef;
  isNavbarVisible: boolean = true;
  isSubHeaderVisible: boolean = true;
  isImpersonationVisible: boolean = true;
  isFooterVisible: boolean = true;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  initialize() {
    this.configureImpersonation();
  }

  override initializeSubscriptions(subscriptionService: SubscriptionService) {
    subscriptionService.subscribeRouterEvent((event): void => {
      if (!(this.contentWindow && event instanceof NavigationEnd)) {
        return;
      }
      (this.contentWindow.nativeElement as Element).scrollTo({ top: 0, behavior: 'smooth' });
    });

    subscriptionService.subscribeEvent(EventNames.siteLayoutChanged, (data: string[]) => this.configureLayout(data), true);

    subscriptionService.subscribeEvent(EventNames.impersonationChanged, () => this.configureImpersonation());

    subscriptionService.subscribeEvent(EventNames.hideSubHeader, () => this.hideSubHeader());
  }

  private configureImpersonation(): void {
    this.isImpersonationVisible = this.solisSessionService.isImpersonationActive;
  }

  private configureLayout(sections: string[]): void {
    this.isNavbarVisible = sections.includes('navbar');
    this.isSubHeaderVisible = sections.includes('subheader');
    this.isFooterVisible = sections.includes('footer');
  }

  private hideSubHeader(): void {
    this.isSubHeaderVisible = false;
    this.changeDetectorRef.detectChanges();
  }
}
