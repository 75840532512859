import { AllowedRelationshipDto } from '../services/gateway-api-client/dtos/datamart.dtos';
import { ProposalDetailRiskDto } from '../services/gateway-api-client/dtos/proposal.dtos';

import { EnvRiskDataModel, FidxRiskDataModel } from '../components/risk/risk.models';

interface IAnnuityDetailModel {
  cusip?: string | null;
  productCode?: string | null;
  name?: string;
  dtccMemberCode?: string | null;
  morningstarCompanyId?: string | null;
  morningstarPolicyId?: string | null;
  usesAdvancedIllustration?: boolean;
  risks?: ProposalDetailRiskDto[] | null;
  envModelRiskData?: EnvRiskDataModel | null;
  envPortfolioRiskData?: EnvRiskDataModel | null;
  fidxRiskData?: FidxRiskDataModel | null;
  riders?: AnnuityDetailRiderModel[];
  overlays?: AnnuityDetailOverlayModel[];
  subaccounts?: AnnuityDetailSubaccountModel[];
  action?: AnnuityDetailAction;
  allowedRelationships?: AllowedRelationshipDto[] | null;
  compareId?: string;
  isComplete?: boolean;
}

export class AnnuityDetailModel implements IAnnuityDetailModel {
  cusip: string;
  productCode: string;
  name: string;
  dtccMemberCode: string;
  morningstarCompanyId: string;
  morningstarPolicyId: string;
  usesAdvancedIllustration: boolean;
  risks: ProposalDetailRiskDto[];
  envModelRiskData: EnvRiskDataModel | null;
  envPortfolioRiskData: EnvRiskDataModel | null;
  fidxRiskData: FidxRiskDataModel | null;
  riders: AnnuityDetailRiderModel[];
  overlays: AnnuityDetailOverlayModel[];
  subaccounts: AnnuityDetailSubaccountModel[];
  action?: AnnuityDetailAction;
  allowedRelationships: AllowedRelationshipDto[];
  compareId?: string;
  isComplete?: boolean;

  constructor(options: IAnnuityDetailModel = {}) {
    this.isComplete = true;
    this.cusip = options.cusip || '';
    this.productCode = options.productCode || '';
    this.name = options.name || '';
    this.dtccMemberCode = options.dtccMemberCode || '';
    this.morningstarCompanyId = options.morningstarCompanyId || '';
    this.morningstarPolicyId = options.morningstarPolicyId || '';
    this.usesAdvancedIllustration = options.usesAdvancedIllustration !== undefined ? options.usesAdvancedIllustration : true;
    this.risks = options.risks || [];
    this.envModelRiskData = options.envModelRiskData || null;
    this.envPortfolioRiskData = options.envPortfolioRiskData || null;
    this.fidxRiskData = options.fidxRiskData || null;
    this.riders = options.riders || [];
    this.overlays = options.overlays || [];
    this.subaccounts = options.subaccounts || [];
    this.action = options.action;
    this.allowedRelationships = options.allowedRelationships || [];
    this.compareId = options.compareId;
  }
}

interface IAnnuityDetailRiderModel {
  riderKey?: string | null;
}

export class AnnuityDetailRiderModel implements IAnnuityDetailRiderModel {
  riderKey: string;

  constructor(options: IAnnuityDetailRiderModel = {}) {
    this.riderKey = options.riderKey || '';
  }
}

interface IAnnuityDetailOverlayModel {
  riderSubaccountGroupKey?: string;
}

export class AnnuityDetailOverlayModel implements IAnnuityDetailOverlayModel {
  riderSubaccountGroupKey: string;

  constructor(options: IAnnuityDetailOverlayModel = {}) {
    this.riderSubaccountGroupKey = options.riderSubaccountGroupKey || '';
  }
}

interface IAnnuityDetailSubaccountModel {
  subaccountKey?: string | null;
  subaccountProductCode?: string | null;
  investProductKey?: string | null;
  subaccountSelectionType?: string | null;
  morningStarInvestmentVehicleId?: string | null;
  allocationPct?: number | null;
}

export class AnnuityDetailSubaccountModel implements IAnnuityDetailSubaccountModel {
  subaccountKey: string;
  subaccountProductCode: string;
  investProductKey: string;
  subaccountSelectionType: string;
  morningStarInvestmentVehicleId: string;
  allocationPct?: number | null;

  constructor(options: IAnnuityDetailSubaccountModel = {}) {
    this.subaccountKey = options.subaccountKey || '';
    this.subaccountProductCode = options.subaccountProductCode || '';
    this.investProductKey = options.investProductKey || '';
    this.subaccountSelectionType = options.subaccountSelectionType || '';
    this.morningStarInvestmentVehicleId = options.morningStarInvestmentVehicleId || '';
    this.allocationPct = options.allocationPct;
  }
}

export enum AnnuityDetailAction {
  AccountOpening = 'AccountOpening',
  Illustration = 'Illustration',
  ResearchCenter = 'ResearchCenter',
  Compare = 'Compare',
  UpdateCompare = 'UpdateCompare',
  SaveForLater = 'SaveForLater',
  SaveAndNext = 'SaveAndNext'
}
