import { Injectable } from '@angular/core';
import { SubscriptionService } from 'framework';

import { EventNames } from '../../../constants/event-names';
import { SolisViewModelBase } from '../../solis-view-model.base';

@Injectable()
export class ImpersonationViewModel extends SolisViewModelBase {
  firstName: string | null = null;
  lastName: string | null = null;

  constructor() {
    super();
  }

  initialize() {
    if (this.solisSessionService.isImpersonationActive) {
      this.firstName = this.solisSessionService.user.firstName || null;
      this.lastName = this.solisSessionService.user.lastName || null;
    }
  }

  override initializeSubscriptions(subscriptionService: SubscriptionService) {
    subscriptionService.subscribeEvent(EventNames.impersonationChanged, () => this.initialize());
  }

  // END ON BEHALF OF FUNCTIONALITY
  async stopImpersonation(): Promise<void> {
    if (
      await this.modalService.confirm(
        'Ending work on behalf of another user will navigate you home and you may lose unsaved work. Do you want to continue?'
      )
    ) {
      await this.solisSessionService.endImpersonation();
      const success = await this.navigationService.navigateHome();
      if (!success) {
        // Unsuccessfully navigating home means we are already on the home page for the impersation.
        // Need to reload the page to update any necessary changes due to impersonation
        this.navigationService.reloadPage(this.navigationService.buildHomeRoute());
      }
    }
  }
}
