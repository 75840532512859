export class BusEvent<T = void> {
  readonly type: string;
  readonly payload?: T | null;

  constructor(type: string, payload: T | null = null) {
    this.type = type;
    this.payload = payload;
  }
}

export class BusRequest<T = void> {
  readonly type: string;
  readonly request?: T | null;

  constructor(type: string, request: T | null = null) {
    this.type = type;
    this.request = request;
  }
}
