export enum FirmType {
  DtccDistributor = 'DtccDistributor',
  Oid = 'Oid',
  Imo = 'Imo'
}

export enum GenderType {
  Male = 'Male',
  Female = 'Female'
}

export enum IllustrationOptionType {
  Bypass = 'Bypass',
  Upload = 'Upload',
  Generate = 'Generate'
}

export enum OwnershipType {
  Individual = 'Individual',
  Joint = 'Joint',
  Trust = 'Trust',
  Corporation = 'Corporation',
  Custodian = 'Custodian',
  UtmaUgma = 'UtmaUgma'
}

export enum ProposalDetailPartyAddressType {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

export enum ProposalPartyRoleType {
  Owner = 'Owner',
  Annuitant = 'Annuitant',
  JointAnnuitant = 'JointAnnuitant',
  JointOwner = 'JointOwner',
  Beneficiary = 'Beneficiary',
  PrimaryAdvisor = 'PrimaryAdvisor',
  TeamMember = 'TeamMember',
  Distributor = 'Distributor',
  PartnerFirm = 'PartnerFirm',
  PartnerAdvisor = 'PartnerAdvisor'
}

export enum ProposalPartyType {
  Person = 'Person',
  Organization = 'Organization',
  Trust = 'Trust'
}

export enum ProposalStatusType {
  ProductSelection = 'ProductSelection',
  Illustration = 'Illustration',
  AccountOpening = 'AccountOpening'
}

export enum ProposalDetailStatusType {
  ProductSelection = 'ProductSelection',
  IllustrationDataEntry = 'IllustrationDataEntry',
  IllustrationDataEntryError = 'IllustrationDataEntryError',
  Illustration = 'Illustration',
  IllustrationError = 'IllustrationError',
  Application = 'Application',
  ApplicationError = 'ApplicationError',
  IllustrationDataEntryComplete = 'IllustrationDataEntryComplete',
  AwaitingIllustration = 'AwaitingIllustration',
  Marketplace = 'Marketplace',
  ClientDetail = 'ClientDetail'
}

export enum ProposalCaseType {
  Imo = 'Imo',
  Oid = 'Oid'
}

export enum ProposalCaseStatusType {
  New = 'New',
  Assigned = 'Assigned',
  IllustrationRequestCancelled = 'IllustrationRequestCancelled',
  IllustrationSubmittedToAdvisor = 'IllustrationSubmittedToAdvisor',
  IllustrationInProgress = 'IllustrationInProgress',
  IllustrationMarkedFinal = 'IllustrationMarkedFinal',
  FinalIllustrationRejected = 'FinalIllustrationRejected',
  AccountOpeningReady = 'AccountOpeningReady',
  AppDataEntry = 'AppDataEntry',
  AppInSignatures = 'AppInSignatures',
  AppInReview = 'AppInReview',
  AppSubmitted = 'AppSubmitted',
  AppRejected = 'AppRejected',
  AppCancelled = 'AppCancelled',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
  AppSubmittedToCarrier = 'AppSubmittedToCarrier',
  ReadyForSignatures = 'ReadyForSignatures',
  SignaturesComplete = 'SignaturesComplete',
  AppSentToReview = 'AppSentToReview',
  AppPendingReview = 'AppPendingReview',
  AppRejectedInReview = 'AppRejectedInReview',
  SubmittedToAdvisorForApproval = 'SubmittedToAdvisorForApproval'
}

export enum ProposalDetailDocumentIllustrationStatusType {
  New = 'New',
  Final = 'Final',
  SubmittedToAdvisor = 'SubmittedToAdvisor',
  Rejected = 'Rejected',
  AutoRejected = 'AutoRejected'
}

export enum ProposalDetailApplicationStatusType {
  DataEntry = 'DataEntry',
  InSignature = 'InSignature',
  NewApplication = 'NewApplication',
  Error = 'Error',
  AppInReview = 'AppInReview',
  AppSubmitted = 'AppSubmitted',
  AppRejected = 'AppRejected',
  AppCancelled = 'AppCancelled',
  AppSubmittedToCarrier = 'AppSubmittedToCarrier',
  ReadyForSignatures = 'ReadyForSignatures',
  SignaturesComplete = 'SignaturesComplete',
  AppPendingReview = 'AppPendingReview',
  AppRejectedInReview = 'AppRejectedInReview'
}

export enum ProposalDetailDocumentType {
  Illustration = 'Illustration',
  Attachment = 'Attachment',
  PlatformDocument = 'PlatformDocument',
  ProductComparison = 'ProductComparison'
}
