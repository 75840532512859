export class SessionKeys {
  static readonly activeFirm = 'activeFirm';
  static readonly activeFlow = 'activeFlow';
  static readonly adHocFirm = 'adHocFirm';
  static readonly advisorAllowableProducts = 'advisorAllowableProducts';
  static readonly advisorRiskData = 'advisorRiskData';
  static readonly annuityComparisonData = 'annuityComparisonData';
  static readonly annuityDetails = 'annuityDetails';
  static readonly annuityProfile = 'annuityProfile';
  static readonly breadcrumbs = 'breadcrumbs';
  static readonly clientProfile = 'clientProfile';
  static readonly contractReplacement = 'contractReplacement';
  static readonly createProposalOptions = 'createProposalOptions';
  static readonly insuranceWorkbenchFlow = 'insuranceWorkbenchFlow';
  static readonly impersonatedUserId = 'impersonatedUserId';
  static readonly lastSessionLocation = 'lastSessionLocation';
  static readonly launchMethod = 'launchMethod';
  static readonly licenseDetails = 'licenseDetails';
  static readonly newIllustrationName = 'newIllustrationName';
  static readonly proposalFlowDetail = 'proposalFlowDetail';
  static readonly proposalProfile = 'proposalProfile';
  static readonly proposalTeam = 'proposalTeam';
  static readonly privatePlacement = 'privatePlacement';
  static readonly privatePlacementSubmission = 'privatePlacementSubmission';
  static readonly sessionId = 'sessionId';
  static readonly selectedGuidanceDesk = 'selectedGuidanceDesk';
  static readonly insuranceExchangeData = 'insuranceExchangeData';
  static readonly decisionTreeSelections = 'decisionTreeSelections';
  static readonly advancedIllustrationFailure = 'advancedIllustrationFailure';
  static readonly comparePdf = 'comparePdf';
}
