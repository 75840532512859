import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Subject } from 'rxjs';

import { IModalValueProvider } from './modal.service';

@Component({
  standalone: true,
  templateUrl: './simple-modal.component.html',
  imports: [],
  styleUrls: ['./simple-modal.component.scss']
})
export class SimpleModalComponent<T> implements IModalValueProvider<T>, OnInit {
  @Input() valueSubject!: Subject<T | undefined>;
  @Input() simpleModal!: SimpleModal<T>;

  innerMessageHtml: SafeHtml | null = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.simpleModal.messageHtml) {
      this.innerMessageHtml = this.sanitizer.bypassSecurityTrustHtml(this.simpleModal.messageHtml);
    }
  }
}

export enum SimpleModalIcon {
  None = '',
  Information = 'fa-regular fa-circle-info text-primary',
  Warning = 'fa-regular fa-triangle-exclamation text-warning',
  Error = 'fa-sharp fa-solid fa-circle-exclamation text-danger'
}

export class SimpleModalButton<T> {
  class?: string = '';
  label: string = '';
  value!: T;

  constructor(init?: Partial<SimpleModalButton<T>>) {
    Object.assign(this, init);
  }
}

export enum SimpleModalPosition {
  Center = 'center',
  Left = 'left',
  Top = 'top',
  Bottom = 'bottom',
  Right = 'right',
  TopLeft = 'topleft',
  TopRight = 'topright',
  BottomLeft = 'bottomleft',
  BottomRight = 'bottomright'
}

export class SimpleModal<T> {
  buttons?: SimpleModalButton<T>[];
  buttonContainerClass?: string = '';
  class?: string = '';
  closable? = false;
  closeOnEscape? = false;
  icon?: SimpleModalIcon = SimpleModalIcon.None;
  message?: string = '';
  messageClass?: string = '';
  messageHtml?: string | null;
  position?: SimpleModalPosition;
  style?: string = '';
  title?: string | null = '';
}
