import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

export class GatewayApiResponse {
  readonly status: number = 0;
  readonly errors: Map<string, Array<string>> | null = null;

  get isSuccessStatusCode(): boolean {
    return this.status >= 200 && this.status < 300;
  }

  get wasCancelled(): boolean {
    return this.status === 999;
  }

  private constructor(status: number, errors: any) {
    this.status = status;
    if (errors) {
      this.errors = new Map<string, Array<string>>(Object.entries(errors));
    }
  }

  static cancelled(): GatewayApiResponse {
    return new GatewayApiResponse(999, null);
  }

  static fromHttpResponse(httpResponse: HttpResponse<Object>): GatewayApiResponse {
    return new GatewayApiResponse(httpResponse.status, null);
  }

  static fromHttpErrorResponse(errorResponse: HttpErrorResponse): GatewayApiResponse {
    return new GatewayApiResponse(errorResponse.status, errorResponse.error.errors);
  }
}

export class GatewayApiResultResponse<TResult> {
  readonly status: number = 0;
  readonly errors: Map<string, Array<string>> | null = null;
  readonly result: TResult | null;

  get isSuccessStatusCode(): boolean {
    return this.status >= 200 && this.status < 300;
  }

  get wasCancelled(): boolean {
    return this.status === 999;
  }

  private constructor(status: number, result: TResult | null, errors: any) {
    this.status = status;
    this.result = result;
    if (errors) {
      this.errors = new Map<string, Array<string>>(Object.entries(errors));
    }
  }

  static cancelled<TResult>(): GatewayApiResultResponse<TResult> {
    return new GatewayApiResultResponse<TResult>(999, null, null);
  }

  static fromHttpResponse<TResult>(httpResponse: HttpResponse<TResult>) {
    return new GatewayApiResultResponse<TResult>(httpResponse.status, httpResponse.body, null!);
  }

  static fromHttpErrorResponse<TResult>(errorResponse: HttpErrorResponse): GatewayApiResultResponse<TResult> {
    return new GatewayApiResultResponse(errorResponse.status, null!, errorResponse.error.errors);
  }
}
