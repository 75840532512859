import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { SubscriptionService } from 'framework';

import { EventNames } from '../../../constants/event-names';

import { UserInfoDto } from '../../../services/gateway-api-client/dtos/infrastructure.dtos';

import { SolisViewModelBase } from '../../solis-view-model.base';

@Injectable()
export class PartnerNavbarViewModel extends SolisViewModelBase {
  currentTheme: string = 'light';
  themeFilePath: string = 'assets/themes/';
  logoFile: string = '/img/IX_LogoOfficial.png';
  userId: string = '';
  userFirstName: string = '';
  isCollapsed: boolean = true;
  isUserManager: boolean = false;
  isAoeReviewer: boolean = false;
  screenWidth: number = 0;
  newScreenWidth: number = 0;
  isImpersonationActive: boolean = false;
  impersonateUsers: UserInfoDto[] = [];

  constructor(private oidcSecurityService: OidcSecurityService) {
    super();
  }

  initialize() {
    this.initializeNavbarData();
  }

  override initializeSubscriptions(subscriptionService: SubscriptionService) {
    // Execute theme switch if user changes it
    subscriptionService.subscribeEvent(EventNames.userPreferencesChanged, () => this.applyTheme());

    // Add "on behalf of" subcription
    subscriptionService.subscribeEvent(EventNames.impersonationChanged, () => this.initializeNavbarData());

    // Add "user Info changed" subscription
    subscriptionService.subscribeEvent(EventNames.userInfoChanged, () => this.initializeNavbarData());
  }

  initializeNavbarData(): void {
    this.initializeSessionData();
    this.screenWidth = window.innerWidth;
    this.newScreenWidth = window.innerWidth + 1;
    this.applyTheme();
  }

  initializeSessionData(): void {
    this.currentTheme = this.solisSessionService.userPreferences.theme || 'light';
    this.userId = this.solisSessionService.user!.id!;
    this.userFirstName =
      this.solisSessionService.impersonatorUser?.firstName ||
      this.solisSessionService.user!.firstName ||
      this.solisSessionService.user!.userName!;
    this.isUserManager = this.solisSessionService.userHasCapability('solis_user_manager');
    this.isAoeReviewer = this.solisSessionService.userHasCapability('aoe_reviewer');
    this.isImpersonationActive = this.solisSessionService.isImpersonationActive;
    this.impersonateUsers = this.solisSessionService.user.canImpersonateUsers ?? [];
    this.updateLogo();
  }

  updateLogo(): void {
    const logoUrl = this.solisSessionService.getSettingValue<string>('Logo', false) ?? '';
    this.logoFile = logoUrl !== '' ? logoUrl : `${this.themeFilePath}${this.currentTheme}/img/IX_LogoOfficial.png`;
  }

  collapse($event: { stopPropagation: () => void }): void {
    this.collapseIcon($event);
    if (this.screenWidth < this.newScreenWidth) {
      this.isCollapsed = true;
    }
  }

  collapseIcon($event: { stopPropagation: () => void }): void {
    $event.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
  }

  // SWITCH THEME
  applyTheme(): void {
    this.currentTheme = this.solisSessionService.userPreferences!.theme!;
  }

  async impersonate(userId: string): Promise<void> {
    if (
      await this.modalService.confirm(
        'Working on behalf of another user will navigate you home and you may lose unsaved work. Do you want to continue?'
      )
    ) {
      this.executeImpersonationAndNavigateHome(userId);
    }
  }

  async executeImpersonationAndNavigateHome(userId: string): Promise<void> {
    await this.solisSessionService.beginImpersonation(userId);
    this.navigationService.navigateHome();
  }

  logout(): void {
    this.oidcSecurityService.logoff().subscribe(() => {
      // Kill solis session
      this.solisSessionService.end();
    });
  }
}
