export enum ExternalLaunchMethod {
  Embed = 'Embed',
  Standalone = 'Standalone'
}

export enum PartyType {
  Person = 'Person',
  Organization = 'Organization',
  Trust = 'Trust'
}

export enum PartyRoleType {
  Owner = 'Owner',
  Annuitant = 'Annuitant',
  JointAnnuitant = 'JointAnnuitant',
  JointOwner = 'JointOwner',
  Beneficiary = 'Beneficiary',
  PrimaryAdvisor = 'PrimaryAdvisor',
  TeamMember = 'TeamMember',
  Distributor = 'Distributor',
  PartnerFirm = 'PartnerFirm',
  PartnerAdvisor = 'PartnerAdvisor'
}

export enum RiskType {
  Other = 'Other',
  Model = 'Model',
  Portfolio = 'Portfolio'
}

export enum ProposalUserPermission {
  Illustration = 'Illustration',
  OpenAnnuity = 'OpenAnnuity'
}

export enum DecisionTreeStatus {
  Complete = 'Complete',
  Modified = 'Modified',
  Bypass = 'Bypass'
}

export enum ProposalStrategyType {
  AnnuityOnly = 'AnnuityOnly',
  ManagedAccountOnly = 'ManagedAccountOnly',
  AnnuityAndManagedAccount = 'AnnuityAndManagedAccount'
}

export enum AnnuityCompensationType {
  CommissionBased = 'CommissionBased',
  FeeBased = 'FeeBased'
}

export enum AnnuityProductType {
  Variable = 'Variable',
  NonVariable = 'NonVariable'
}

export enum SolisDocumentType {
  Sis = 'Sis',
  Illustration = 'Illustration',
  OptimizerCombinedIllustration = 'OptimizerCombinedIllustration',
  Isp = 'Isp',
  Adv = 'Adv',
  Adv2b = 'Adv2b',
  FormCrs = 'FormCrs',
  AdvBrochure = 'AdvBrochure',
  TermsAndConditions = 'TermsAndConditions',
  AdditionalOfferings = 'AdditionalOfferings'
}
