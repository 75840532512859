import { Injectable } from '@angular/core';
import { SolisViewModelBase } from '../../solis-view-model.base';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';

@Injectable()
export class GlobalViewModel extends SolisViewModelBase {
  termsVersion: string = '1.0';
  constructor() {
    super();
  }

  async initialize() {
    if (!this.isEmbeddedSession && !this.solisSessionService.isImpersonationActive) {
      await this.initializeTermsAndConditions();
    }
  }

  async initializeTermsAndConditions() {
    const response = await this.apiClient.getTermsAccepted(this.termsVersion);

    if ((response.result?.version ?? null) === null) {
      // Always show modal until user agrees to terms
      const accepted = await this.modalService.show<boolean>(TermsAndConditionsComponent, {});

      if (accepted) {
        const upsertResponse = await this.apiClient.upsertTermsAccepted({ version: this.termsVersion, dateAccepted: new Date() });
      }
    }
  }
}
