<footer class="footer belowCard">
  <div class="container-fluid">
    <div class="my-0"></div>
    <div class="row footer-container">
      <div class="col-md-3 col-lg-2 ps-2 copyright">
        <ul class="nav">
          <li class="nav-item">
            <a class="nav-link px-0 cursor" (click)="viewModel.showDisclosureDialog()">Show Disclosures</a>
          </li>
        </ul>
      </div>
      <div class="col-md-9 col-lg-10 text-end">
        <div class="copyright row mx-0">
          <div class="col-12 px-0">
            © {{ viewModel.copyrightYear }},
            <span>{{ viewModel.copyrightName }}</span>
            <span>&nbsp;All rights reserved.&nbsp;&nbsp;|</span>
            <ul class="nav">
              <li class="nav-item">
                <a class="nav-link" target="_new" [href]="viewModel.termsUrl">
                  &nbsp;Privacy Policy&nbsp;&nbsp;|&nbsp;&nbsp;Terms of Use
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
