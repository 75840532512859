<div class="subnavbar-sticky sub-nav-bg-scroll container-fluid card rounded-0">
  <div class="row my-2">
    <div class="col-md-6 col-lg-5 px-sm-smallscreen-0">
      <h6 class="mb-0 pt-0 fw-bold">{{ viewModel.subheaderTitle }}</h6>
      <p-breadcrumb
        [hidden]="viewModel.breadcrumbItems.length === 0"
        styleClass="border-none p-0 f-breadcrumb"
        [model]="viewModel.breadcrumbItems"
        [home]="viewModel.home">
        <ng-template pTemplate="separator">
          <span class="breadcrumb-separator">/</span>
        </ng-template>
      </p-breadcrumb>
    </div>

    <div class="col-md-6 col-lg-7 text-end px-sm-smallscreen-0 pt-lg-2 pt-md-2"></div>
  </div>
</div>
