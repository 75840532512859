import { Routes } from '@angular/router';
import { annuityCompareGuard } from '../../services/guards/advisor/annuity-compare/annuity-compare.guard';
import { lombardGuard } from '../../services/guards/advisor/lombard/lombard.guard';

/* istanbul ignore next */
export const AdvisorRoutes: Routes = [
  {
    path: '',
    title: 'Home',
    data: { layout: ['navbar', 'footer'], breadcrumb: { label: 'Home' } },
    loadComponent: () => import('../../pages/advisor/home/advisor-home-page.component').then((m) => m.AdvisorHomePageComponent)
  },
  // SHARED PAGES
  {
    path: 'products',
    title: 'Research & Apply for an Annuity',
    data: {
      breadcrumb: { label: 'Annuity Selection' }
    },
    loadComponent: () =>
      import('../../pages/shared/product-selection/product-selection-page.component').then((m) => m.ProductSelectionPageComponent)
  },
  {
    path: 'products/:cusipProductCode/details',
    title: 'Research & Apply for an Annuity',
    data: {
      breadcrumb: { label: 'Annuity Details' }
    },
    loadComponent: () => import('../../pages/shared/product-detail/product-detail-page.component').then((m) => m.ProductDetailPageComponent)
  },
  {
    path: 'products/:cusipProductCode/research',
    title: 'Research & Apply for an Annuity',
    data: {
      breadcrumb: { label: 'Research Center' }
    },
    loadComponent: () =>
      import('../../pages/shared/research-center/research-center-page.component').then((m) => m.ResearchCenterPageComponent)
  },
  {
    path: 'products/compare',
    canActivate: [annuityCompareGuard],
    title: 'Research & Apply for an Annuity',
    data: {
      breadcrumb: { label: 'Annuity Compare' }
    },
    loadComponent: () =>
      import('../../pages/shared/product-compare/product-compare-page.component').then((m) => m.ProductComparePageComponent)
  },
  {
    path: 'client-profile',
    title: 'Client Profile',
    data: {
      breadcrumb: { label: 'Client Profile' }
    },
    loadComponent: () => import('../../pages/shared/client-profile/client-profile-page.component').then((m) => m.ClientProfilePageComponent)
  },
  {
    path: 'proposal-team-management',
    title: 'Proposal Team Management',
    data: {
      breadcrumb: { label: 'Proposal Team Mgmt' }
    },
    loadComponent: () =>
      import('../../pages/shared/proposal-team-management/proposal-team-management-page.component').then(
        (m) => m.ProposalTeamManagementPageComponent
      )
  },
  {
    path: 'create-proposal',
    title: 'Creating Proposal',
    data: {
      breadcrumb: { label: 'Creating Proposal' }
    },
    loadComponent: () =>
      import('../../pages/shared/create-proposal/create-proposal-page.component').then((m) => m.CreateProposalPageComponent)
  },
  {
    path: 'illustrations',
    title: 'Illustration',
    data: {
      breadcrumb: { label: 'Illustration' }
    },
    loadComponent: () => import('../../pages/shared/illustration/illustration-page.component').then((m) => m.IllustrationPageComponent)
  },
  {
    path: 'account-opening',
    title: 'Account Opening',
    data: {
      breadcrumb: { label: 'Account Opening' }
    },
    loadComponent: () =>
      import('../../pages/shared/account-opening/account-opening-page.component').then((m) => m.AccountOpeningPageComponent)
  },
  {
    path: 'users',
    title: 'Users',
    data: { breadcrumb: { label: 'User List', topLevelNavigation: true }, capabilities: { all: ['solis_user_manager'] } },
    loadComponent: () => import('../../pages/shared/profiles-list/profiles-list-page.component').then((m) => m.ProfilesListPageComponent)
  },
  {
    path: 'users/add',
    title: 'Add User',
    data: {
      breadcrumb: { label: 'Add User' }
    },
    loadComponent: () =>
      import('../../pages/shared/profile-settings/profile-settings-page.component').then((m) => m.ProfileSettingsPageComponent)
  },
  {
    path: 'users/:userId',
    title: 'Profile Settings',
    data: { breadcrumb: { label: 'Profile Settings', topLevelNavigation: true } },
    loadComponent: () =>
      import('../../pages/shared/profile-settings/profile-settings-page.component').then((m) => m.ProfileSettingsPageComponent)
  },
  {
    path: 'account-setup',
    title: 'Account Setup',
    data: {
      layout: ['footer']
    },
    loadComponent: () => import('../../pages/shared/account-setup/account-setup-page.component').then((m) => m.AccountSetupPageComponent)
  },
  {
    path: 'review',
    title: 'Review',
    data: { breadcrumb: { label: 'Review', topLevelNavigation: true } },
    loadComponent: () => import('../../pages/shared/review/review-page.component').then((m) => m.ReviewPageComponent)
  },
  {
    path: 'proposal-strategy',
    title: 'Proposal Strategy',
    loadComponent: () =>
      import('../../pages/advisor/proposal-strategy/proposal-strategy-page.component').then((m) => m.ProposalStrategyPageComponent)
  },
  // Advisor Routes
  {
    path: 'products/replacement',
    title: 'Research & Apply for an Annuity',
    data: {
      breadcrumb: { label: 'Product Replacement' }
    },
    loadComponent: () =>
      import('../../pages/advisor/product-replacement/product-replacement-page.component').then((m) => m.ProductReplacementPageComponent)
  },
  {
    path: 'guidance-desks/:dtccMemberCodeFirmType',
    title: 'Research & Apply for an Annuity',
    data: {
      breadcrumb: { label: 'Guidance Desk Detail' }
    },
    loadComponent: () => import('../../pages/advisor/firm-detail/firm-detail-page.component').then((m) => m.FirmDetailPageComponent)
  },
  {
    path: 'insurance-workbench',
    title: 'Insurance Workbench',
    data: { breadcrumb: { label: 'Insurance Workbench', topLevelNavigation: true } },
    loadComponent: () =>
      import('../../pages/advisor/insurance-workbench/insurance-workbench-page.component').then((m) => m.InsuranceWorkbenchPageComponent)
  },
  {
    path: 'annuity-marketplace',
    title: 'Research & Apply for an Annuity',
    data: { breadcrumb: { label: 'Marketplace' } },
    loadComponent: () =>
      import('../../pages/advisor/annuity-marketplace/annuity-marketplace-page.component').then((m) => m.AnnuityMarketplacePageComponent)
  },
  {
    path: 'dashboard',
    title: 'Dashboard',
    data: { breadcrumb: { label: 'Dashboard', topLevelNavigation: true } },
    loadComponent: () =>
      import('../../pages/advisor/dashboard/advisor-dashboard-page.component').then((m) => m.AdvisorDashboardPageComponent)
  },
  {
    path: 'annuity-contracts/:contractNumber',
    title: 'Contract',
    data: { breadcrumb: { label: 'Contract' } },
    loadComponent: () =>
      import('../../pages/advisor/annuity-contract/annuity-contract-page.component').then((m) => m.AnnuityContractPageComponent)
  },
  {
    path: 'proposal-details/:platformProposalId',
    title: 'Proposal',
    data: { breadcrumb: { label: 'Proposal' } },
    loadComponent: () =>
      import('../../pages/advisor/annuity-proposal/annuity-proposal-page.component').then((m) => m.AnnuityProposalPageComponent)
  },
  {
    path: 'platform-proposal-summary',
    title: 'Proposal Summary',
    data: {
      layout: [],
      breadcrumb: { label: 'Proposal Summary' }
    },
    loadComponent: () =>
      import('../../pages/advisor/platform-proposal-summary/platform-proposal-summary-page.component').then(
        (m) => m.PlatformProposalSummaryPageComponent
      )
  },
  {
    path: 'case-profiles/:caseProposalId',
    title: 'Case Profile',
    data: { breadcrumb: { label: 'Case Profile' } },
    loadComponent: () => import('../../pages/advisor/case-profile/case-profile-page.component').then((m) => m.CaseProfilePageComponent)
  },
  {
    path: 'lombard',
    canActivateChild: [lombardGuard],
    children: [
      {
        path: 'submitted',
        title: 'Private Placement',
        data: {
          layout: ['footer'],
          breadcrumb: { label: 'Lombard Private Placement Submitted' }
        },
        loadComponent: () =>
          import('../../pages/advisor/lombard/lombard-submission-confirmation/lombard-submission-confirmation.component').then(
            (m) => m.LombardSubmissionConfirmationComponent
          )
      },
      {
        path: 'case-profile',
        title: 'Private Placement',
        data: {
          layout: ['subheader', 'footer'],
          breadcrumb: { label: 'Lombard Case Profile' }
        },
        loadComponent: () =>
          import('../../pages/advisor/lombard/lombard-case-profile/lombard-case-profile-page.component').then(
            (m) => m.LombardCaseProfilePageComponent
          )
      },
      {
        path: 'products/:cusip',
        title: 'Private Placement',
        data: {
          layout: ['subheader', 'footer'],
          breadcrumb: { label: 'Lombard Product Details' }
        },
        loadComponent: () =>
          import('../../pages/advisor/lombard/lombard-product-detail/lombard-product-detail-page.component').then(
            (m) => m.LombardProductDetailPageComponent
          )
      },
      {
        path: '',
        title: 'Lombard',
        data: {
          layout: ['subheader', 'footer'],
          breadcrumb: { label: 'Lombard', topLevelNavigation: true }
        },
        loadComponent: () =>
          import('../../pages/advisor/lombard/lombard-detail/lombard-detail-page.component').then((m) => m.LombardDetailPageComponent)
      }
    ]
  },
  {
    path: 'annuity-contracts/:contractNumber/inforce-management',
    title: 'In-Force Management',
    data: { breadcrumb: { label: 'In-Force Management', groups: ['dashboard'], showHome: false } },
    loadComponent: () =>
      import('../../pages/advisor/inforce-management/inforce-management-page.component').then((m) => m.InforceManagementPageComponent)
  },
  {
    path: 'inforce-management',
    title: 'In-Force Management - Strategy Modification',
    data: { breadcrumb: { label: 'In-Force Management', showHome: false } },
    loadComponent: () =>
      import('../../pages/advisor/inforce-management/inforce-management-page.component').then((m) => m.InforceManagementPageComponent)
  },
  {
    path: 'ixhub',
    title: 'Insurance Exchange',
    data: { layout: ['navbar', 'footer'], breadcrumb: { label: 'Insurance Exchange', topLevelNavigation: true } },
    loadComponent: () => import('../../pages/advisor/ixhub/ixhub-page.component').then((m) => m.IxhubPageComponent)
  },
  {
    path: 'annuity-profile',
    title: 'Annuity Profile',
    data: { breadcrumb: { label: 'Annuity Profile' } },
    loadComponent: () =>
      import('../../pages/shared/annuity-profile/annuity-profile-page.component').then((m) => m.AnnuityProfilePageComponent)
  },
  {
    path: 'client-search',
    title: 'Client Search',
    data: { breadcrumb: { label: 'Client Search' } },
    loadComponent: () => import('../../pages/advisor/client-search/client-search-page.component').then((m) => m.ClientSearchPageComponent)
  },
  // IXHUB Routes
  {
    title: 'Transact',
    path: 'transact',
    data: {
      ixHub: {
        label: 'Transact',
        // Need to uncomment when Product Team decides appropriate roles for each cards in IX Hub
        //roles: ['Advisor', 'Assistant', 'SuperUser'],
        description: 'Start a new or manage an existing annuity contract',
        class: 'transact',
        image: 'assets/img/ixhub/transact.jpg'
      }
    },
    children: [
      {
        path: 'annuity-profile/commissionable',
        title: 'Annuity Profile',
        data: {
          breadcrumb: { label: 'Annuity Profile' },
          ixHub: {
            label: 'Commissionable Annuity'
          }
        },
        redirectTo: '/annuity-profile?compensationType=commission'
      },
      {
        path: 'annuity-profile/fee-based',
        title: 'Annuity Profile',
        data: {
          breadcrumb: { label: 'Annuity Profile' },
          ixHub: {
            label: 'Fee-Based Annuity',
            capabilities: ['None']
          }
        },
        redirectTo: '/annuity-profile?compensationType=fee'
      },
      {
        path: 'client-search/spia',
        title: 'Client Search',
        data: {
          breadcrumb: { label: 'Client Search' },
          ixHub: {
            label: 'SPIA'
          }
        },
        redirectTo: '/client-search?transact=spia'
      },
      {
        path: 'client-search/dia',
        title: 'Client Search',
        data: {
          breadcrumb: { label: 'Client Search' },
          ixHub: {
            label: 'DIA'
          }
        },
        redirectTo: '/client-search?transact=dia'
      },
      {
        path: 'ixhub/subpays/dashboard',
        data: {
          ixHub: {
            label: 'Subsequent Premium'
          }
        },
        redirectTo: '/dashboard'
      }
    ]
  },
  {
    title: 'Manage',
    path: 'manage',
    data: {
      ixHub: {
        label: 'Manage',
        // Need to uncomment when Product Team decides appropriate roles for each cards in IX Hub
        //roles: ['Advisor', 'Assistant', 'SuperUser'],
        description: 'Review and take action on existing annuity contracts',
        class: 'manage',
        image: 'assets/img/ixhub/manage.jpg'
      }
    },
    children: [
      {
        path: 'review',
        title: 'Review Queue',
        data: {
          breadcrumb: { label: 'Review' },
          ixHub: {
            label: 'Review Queue'
            // roles: ['aoe_reviewer']
          }
        },
        redirectTo: '/review'
      },
      {
        path: 'dashboard',
        title: 'Application Dashboard',
        data: {
          breadcrumb: { label: 'Application Dashboard' },
          ixHub: {
            label: 'Application Dashboard'
          }
        },
        redirectTo: '/dashboard'
      },
      {
        path: 'annuity-lifecycle',
        title: 'Annuity Lifecycle',
        data: {
          breadcrumb: { label: 'Annuity Lifecycle' },
          ixHub: {
            label: 'Annuity Lifecycle'
          }
        },
        redirectTo: '/annuity-lifecycle'
      },
      {
        path: 'annuity-training',
        title: 'Annuity Training',
        data: {
          breadcrumb: { label: 'Annuity Training' },
          ixHub: {
            label: 'Annuity Training'
          }
        },
        redirectTo: '/annuity-training'
      }
    ]
  },
  {
    title: 'Learn',
    path: 'learn',
    data: {
      ixHub: {
        label: 'Learn',
        // Need to uncomment when Product Team decides appropriate roles for each cards in IX Hub
        //roles: ['Advisor', 'Assistant', 'SuperUser'],
        description: 'Access research, education, and training resources',
        class: 'learn',
        image: 'assets/img/ixhub/learn.jpg'
      }
    },
    children: [
      {
        path: 'annuity-research',
        title: 'Annuity Research',
        data: {
          breadcrumb: { label: 'Annuity Research' },
          ixHub: {
            label: 'Annuity Research'
          }
        },
        redirectTo: '/annuity-research'
      },
      {
        path: 'resource-center',
        title: 'Resource Center',
        data: {
          breadcrumb: { label: 'Resource Center' },
          ixHub: {
            label: 'Resource Center'
          }
        },
        redirectTo: '/resource-center'
      },
      {
        path: 'annuity-training',
        title: 'Annuity Training',
        data: {
          breadcrumb: { label: 'Annuity Training' },
          ixHub: {
            label: 'Annuity Training'
          }
        },
        redirectTo: '/annuity-training'
      }
    ]
  },
  // REDIRECT ROUTES FROM OLD SITE
  {
    path: 'product/research/:cusip/:productCode',
    loadComponent: () => import('../../pages/error/error-page.component').then((m) => m.ErrorPageComponent)
  }
];
