import { SolisSessionDto } from '../gateway-api-client/dtos/client-application.dtos';
import { GatewayApiClient } from '../gateway-api-client/gateway-api-client.service';

export class PersistedSession {
  private sessionCache: Map<string, any> = new Map<string, any>();

  constructor(private apiClient: GatewayApiClient, private sessionId: string) {}

  public async initialize(): Promise<void> {
    const response = await this.apiClient.getSession(this.sessionId);

    if (response.isSuccessStatusCode) {
      const sessionData = response.result!.sessionData!;
      if (Object.keys(sessionData).length > 0) {
        this.sessionCache = new Map(Object.entries(response.result!.sessionData!));
      }
    }
  }

  public get<T>(key: string): T | null {
    const sessionValue = this.sessionCache.get(key);

    if (sessionValue) {
      return sessionValue as T;
    }

    return null;
  }

  public getAll(): Map<string, any> {
    return this.sessionCache;
  }

  public async setAsync(key: string, value: any): Promise<void> {
    // Make sure the local cache is up to date immediately in case
    // the caller hasn't await the api call. It'll still be available
    // via get even if it hasn't gotten to the server yet
    this.sessionCache.set(key, value);

    // Create a new map that has just the one key being set
    // The server is able to set one key at a time
    const sessionData = new Map<string, any>();
    sessionData.set(key, value);

    const serverDto = new SolisSessionDto();
    const sessionDataObject = Object.fromEntries(sessionData);
    serverDto.sessionData = sessionDataObject;

    await this.apiClient.upsertSession(this.sessionId, serverDto);
  }
}
