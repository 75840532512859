import { Injectable } from '@angular/core';

import { SolisViewModelBase } from '../../solis-view-model.base';

@Injectable()
export class AdvisorFooterViewModel extends SolisViewModelBase {
  copyrightName: string = 'Fiduciary Exchange LLC.';
  copyrightYear: number = new Date().getFullYear();
  title: string = 'Disclosure';
  disclosureHtml: string = disclosureHtml;
  termsUrl: string = 'https://fidx.io/terms';

  initialize() {}

  async showDisclosureDialog() {
    await this.modalService.showSimpleModal<boolean>({
      class: 'p-dialog-width',
      closable: true,
      title: this.title,
      messageHtml: this.disclosureHtml,
      buttons: [
        {
          label: 'OK',
          class: 'btn btn-primary btn-caution',
          value: true
        }
      ]
    });
  }
}

const disclosureHtml: string = `
<div class="scrollable-body">
<p class="text-justify mt-2">
  Information regarding fees, charges, costs and other product expenses are received from sources believed reliable but cannot be
  guaranteed. Fiduciary Exchange LLC and its affiliates are not responsible for errors or omissions relating to the information
  provided. Please review product information received from the insurance company for complete information about the insurance products
  described in this proposal including fees and expenses.
</p>
<br />
<p class="text-justify">
  Information and product documentation provided through Fiduciary Exchange LLC is owned by the party who created the information and
  product documentation, is subject to change and is not guaranteed to be accurate or complete. Some of the information may have been
  supplied by Morningstar, Inc. That information is proprietary to Morningstar and its content providers and may not be copied or
  redistributed. Fiduciary Exchange LLC, Morningstar and Morningstar's content providers are not responsible for any damages or losses
  arising from the use of the information or product documentation.
</p>
<br />
<p class="text-justify">
  The product documentation and information has been provided by the Fiduciary Exchange LLC and remains owned by the party who provided
  the product documentation and information. The product documentation and information is subject to change and is not guaranteed to be
  accurate and complete. Please contact the insurance carrier for complete information about the insurance product or distributor data
  you are reviewing to verify that it is up to date and accurate.
</p>
<br />
<p class="text-justify">
  Fiduciary Exchange LLC and Envestnet Asset Management, Inc. are affiliates and Fiduciary Exchange LLC receives compensation for
  administrative and operational services relating to insurance and annuity transactions it receives through the Envestnet platform.
</p>
<br />
</div>`;
