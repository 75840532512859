import { AfterViewInit, Component, ComponentRef, Injectable, OnDestroy, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { SimpleModal, SimpleModalComponent, SimpleModalIcon } from './simple-modal.component';

// In order to turn a Component into a modal: Implement this interface and define this ValueSubject as an @Input.
// To return a value from the Component to the awaiting end: call valueSubject.next() and provide a value.
// To implement a cancel behavior, call valueSubject.complete() and undefined will be returned.
export interface IModalValueProvider<T> {
  valueSubject: Subject<T | undefined>;
}

// ModalService -- Turns a component into an awaitable modal control that will return an emitted typed value.
//
// The ModalService will first use the DialogService to create an instance of ModalHostComponent<T> component.
// The reason for this is so that the Control will not have to handle injecting and awkwardly using the
// DynamicDialogConfig to pass values and the DynamicDialogRef in order to close the modal. The design was to
// essentially have the target component only need to report a typed value at any given point of time.
// You can optionally set any of the other configurable values of the modal with the optional parameter, along with
// passing @Input values into your component by adding objects into the config.data.
@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private dialogService: DialogService) {}

  public async show<T>(type: Type<IModalValueProvider<T>>, config?: DynamicDialogConfig): Promise<T | undefined> {
    return new Promise<T | undefined>((resolve, reject) => {
      try {
        const cfg: DynamicDialogConfig = {
          ...(config ? config : {}),
          baseZIndex: 99999,
          modal: true,
          data: {
            ...(config && config.data ? config.data : {}),
            ...{ __type: type }
          },
          duplicate: true
        };

        const ref: DynamicDialogRef = this.dialogService.open(ModalHostComponent<T>, cfg);

        ref.onClose.subscribe({
          next: (result: T) => resolve(result),
          complete: () => resolve(undefined)
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  public async showSimpleModal<T>(simpleModal: SimpleModal<T>) {
    return await this.show<T>(SimpleModalComponent, {
      closeOnEscape: simpleModal.closeOnEscape,
      position: simpleModal.position,
      data: {
        simpleModal
      }
    });
  }

  public async confirm(
    message: string,
    title: string = 'Confirmation',
    icon = SimpleModalIcon.Warning,
    yesButtonLabel: string = 'Yes',
    noButtonLabel: string = 'No'
  ): Promise<boolean> {
    return (
      (await this.showSimpleModal<boolean>({
        class: 'p-dialog-popup-width p-dialog-width',
        icon,
        message,
        title,
        buttons: [
          {
            label: yesButtonLabel,
            class: `btn btn-primary ${this.buttonIcon(icon)}`,
            value: true
          },
          {
            label: noButtonLabel,
            class: 'btn btn-secondary',
            value: false
          }
        ]
      })) ?? false
    );
  }

  public async message(
    message: string,
    title: string = '',
    buttonLabel: string = 'OK',
    icon = SimpleModalIcon.Information
  ): Promise<boolean> {
    return (
      (await this.showSimpleModal<boolean>({
        class: 'p-dialog-popup-width p-dialog-width',
        icon,
        message,
        title,
        buttons: [
          {
            label: buttonLabel,
            class: `btn btn-primary ${this.buttonIcon(icon)}`,
            value: true
          }
        ]
      })) ?? false
    );
  }

  private buttonIcon(icon: SimpleModalIcon): string {
    return icon === SimpleModalIcon.Error ? 'btn-error' : icon === SimpleModalIcon.Warning ? 'btn-caution' : '';
  }
}

// @ViewChild is not accessible until during the ngAfterViewInit method.
// The type that was passed into the ModalService and used in the DialogService.open()
// call is retrieved from the injected DynamicDialogConfig.data.type and used in the
// ViewContainerRef component, which will instantiate the desired type and have an
// observable subscribed to the final component so that when it receives the value
// then it will close the PrimeNG Dynamic Modal.
@Component({
  template: `<ng-template #modalHost></ng-template>`,
  styleUrls: ['./modal.service.scss']
})
export class ModalHostComponent<T> implements AfterViewInit, OnDestroy {
  @ViewChild('modalHost', { read: ViewContainerRef })
  private modalHostContainer!: ViewContainerRef;
  private modalRef: ComponentRef<any> | null = null;
  private valueSubject: Subject<T | undefined> | null = null;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  ngAfterViewInit() {
    const inputs = Object.keys(this.config.data).reduce((acc: { [key: string]: any }, key) => {
      if (key != '__type') {
        acc[key] = this.config.data[key];
      }
      return acc;
    }, {});

    this.valueSubject = new Subject<T | undefined>();
    this.valueSubject.asObservable().subscribe({
      next: (value: T | undefined) => this.ref.close(value),
      complete: () => this.ref.close()
    });

    this.modalRef = this.modalHostContainer.createComponent(this.config.data!.__type);
    this.modalRef.setInput('valueSubject', this.valueSubject);

    for (const input in inputs) {
      this.modalRef.setInput(input, inputs[input]);
    }

    // trigger the components change detector because we have explicitly set inputs after component creation.
    // this will prevent the development mode error 'ExpressionChangedAfterItHasBeenCheckedError'
    this.modalRef.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.modalRef?.destroy();
    this.valueSubject?.unsubscribe();
  }
}
