<div class="p-2" [style.min-width.px]="viewModel.minWidthCalc" [formGroup]="viewModel.form">
  @if (viewModel.header && viewModel.header.length) {
    <h5 class="mb-3 fw-bold">{{ viewModel.header }}</h5>
  }
  @if (viewModel.subHeader && viewModel.subHeader.length) {
    <h6 class="mb-3 fw-bold">{{ viewModel.subHeader }}</h6>
  }
  @if (viewModel.isMultiLine) {
    <textarea
      type="text"
      [maxlength]="viewModel.maxChars!"
      (keyup)="viewModel.updateRemaining()"
      pInputText
      formControlName="text"
      class="form-control mb-2 w-100"
      [ngStyle]="{ height: viewModel.height + 'px' }"
      [value]="viewModel.placeHolder"></textarea>
  } @else {
    <input
      type="text"
      [maxlength]="viewModel.maxChars!"
      (keyup)="viewModel.updateRemaining()"
      pInputText
      formControlName="text"
      class="form-control mb-2 w-100"
      [value]="viewModel.placeHolder" />
  }
  @if (viewModel.isControlTouchedAndInvalid('text')) {
    <small class="error-text"> </small>
  }
  @if (viewModel.maxChars && viewModel.maxChars > 0) {
    <div>
      <em
        ><small>{{ viewModel.remainingChars }} character(s) left</small></em
      >
    </div>
  }
</div>

<div class="w-100 d-flex flex-row justify-content-end">
  <button
    type="button"
    label="Continue"
    [disabled]="!viewModel.isFormValid()"
    class="btn btn-primary btn-caution"
    (click)="viewModel.submitForm()">
    {{ viewModel.continueButtonText }}
  </button>
  <button type="button" label="Cancel" class="btn btn-secondary me-2" (click)="viewModel.cancel()">Cancel</button>
</div>
