import { Injectable } from '@angular/core';

import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { lastValueFrom } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '../../../../solis-web/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnections = new Map<string, HubConnection>();
  // The first Map: the key is the uuid for the hub.
  // The second Map: the key is the name of the signalr method
  // The third Map: key is a uuid and the value is the actual function
  // The reasoning here is that we want to allow having multiple people registered to a single method
  // and we want to differentiate the connections to that method
  private hubsHandlers = new Map<string, Map<string, Map<string, (message: any) => void>>>();

  constructor(private oidcSecurityService: OidcSecurityService) {}

  private async getAccessToken() {
    return await lastValueFrom(this.oidcSecurityService.getAccessToken());
  }

  async start(url: string): Promise<string> {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.gatewayApiBaseUrl}/${url}`, {
        accessTokenFactory: async () => await this.getAccessToken()
      })
      .withAutomaticReconnect()
      .build();

    await hubConnection.start();

    const hubId = uuidv4();

    this.hubConnections.set(hubId, hubConnection);
    return hubId;
  }

  async stop(hubId: string): Promise<void> {
    const hub = this.hubConnections.get(hubId);
    if (hub) {
      this.unregisterAllMessageHandlers(hubId);

      await hub.stop();

      this.hubConnections.delete(hubId);
    }
  }

  register<T>(hubId: string, methodName: string, method: (message: T) => void): string | null {
    let methodId = null;
    const hub = this.hubConnections.get(hubId);
    if (hub) {
      let hubHandler = this.hubsHandlers.get(hubId);
      if (!hubHandler) {
        hubHandler = new Map<string, Map<string, (message: any) => void>>();
        this.hubsHandlers.set(hubId, hubHandler);
      }

      let methodHandler = hubHandler.get(methodName);
      if (!methodHandler) {
        methodHandler = new Map<string, (message: any) => void>();
        hubHandler.set(methodName, methodHandler);
      }

      methodId = uuidv4();

      methodHandler.set(methodId, method);
      hub.on(methodName, method);
    }

    return methodId;
  }

  // remove a specific handler for a given hub. No need to pass in the methodName, it will be located without it
  unregisterMessageHandler(hubId: string, methodId: string) {
    const hub = this.hubConnections.get(hubId);
    if (hub) {
      let hubHandler = this.hubsHandlers.get(hubId);
      if (hubHandler) {
        for (const [methodName, map] of hubHandler) {
          const method = map.get(methodId);
          if (method) {
            hub.off(methodName, method);
            map.delete(methodId);
            break;
          }
        }
      }
    }
  }

  // remove all handlers for a given method on a given hub
  unregisterMessageHandlers(hubId: string, methodName: string) {
    const hub = this.hubConnections.get(hubId);
    if (hub) {
      let hubHandler = this.hubsHandlers.get(hubId);
      if (hubHandler) {
        let methodHandler = hubHandler.get(methodName);
        if (methodHandler) {
          for (const [_, method] of methodHandler) {
            hub.off(methodName, method);
          }

          hubHandler.delete(methodName);
        }
      }
    }
  }

  unregisterAllMessageHandlers(hubId: string) {
    const hub = this.hubConnections.get(hubId);
    if (hub) {
      let hubHandler = this.hubsHandlers.get(hubId);
      if (hubHandler) {
        for (const [methodName, _] of hubHandler) {
          hub.off(methodName);
        }
        hubHandler.clear();
      }
    }
  }

  async send<T>(hubId: string, methodName: string, message: T): Promise<void> {
    const hub = this.hubConnections.get(hubId);
    if (hub) {
      await hub.send(methodName, message);
    }
  }

  async sendAndReceive<T, U>(hubId: string, methodName: string, message: T): Promise<U | null> {
    const hub = this.hubConnections.get(hubId);
    if (hub) {
      return await hub.invoke(methodName, message);
    }

    return null;
  }
}
