import { Component, Injector } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MenubarModule } from 'primeng/menubar';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { ComponentBase } from 'framework';

import { AdvisorNavbarViewModel } from './advisor-navbar.view-models';

@Component({
  selector: 'app-advisor-navbar',
  standalone: true,
  imports: [RouterModule, FormsModule, MenubarModule, NgbModule, ToggleButtonModule],
  providers: [AdvisorNavbarViewModel],
  templateUrl: './advisor-navbar.component.html',
  styleUrls: ['./advisor-navbar.component.scss']
})
export class AdvisorNavbarComponent extends ComponentBase<AdvisorNavbarViewModel> {
  constructor(
    protected override injector: Injector,
    viewModel: AdvisorNavbarViewModel
  ) {
    super(injector, viewModel);
  }
}
