import { Component, ViewChild, ElementRef, Injector } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ComponentBase } from 'framework';

import { GlobalComponent } from '../../components/common/global/global.component';
import { AdvisorNavbarComponent } from '../../components/advisor/navbar/advisor-navbar.component';
import { AdvisorSubHeaderComponent } from '../../components/advisor/sub-header/advisor-sub-header.component';
import { AdvisorFooterComponent } from '../../components/advisor/footer/advisor-footer.component';
import { ImpersonationComponent } from '../../components/common/impersonation/impersonation.component';
import { AdvisorLayoutViewModel } from './advisor-layout.view-models';

@Component({
  selector: 'app-advisor-layout',
  standalone: true,
  imports: [
    RouterModule,
    GlobalComponent,
    AdvisorNavbarComponent,
    ImpersonationComponent,
    AdvisorSubHeaderComponent,
    AdvisorFooterComponent
  ],
  providers: [AdvisorLayoutViewModel],
  templateUrl: './advisor-layout.component.html',
  styleUrls: ['./advisor-layout.component.scss']
})
export class AdvisorLayoutComponent extends ComponentBase<AdvisorLayoutViewModel> {
  @ViewChild('advisorContent') set contentWindow(value: ElementRef) {
    this.viewModel.contentWindow = value;
  }
  constructor(
    protected override injector: Injector,
    viewModel: AdvisorLayoutViewModel
  ) {
    super(injector, viewModel);
  }
}
