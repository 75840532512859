import { SolisDestinationPage, SolisFlow } from '../../session/solis-session-service.enums';
import {
  ExternalLaunchMethod,
  PartyRoleType,
  PartyType,
  ProposalStrategyType,
  RiskType,
  SolisDocumentType
} from './client-application.enums';
import { AddressType } from './infrastructure.enums';
import { OwnershipType } from './proposal.enums';

export class LaunchDto {
  id: string = '';
  userName?: string | null = null;
  source?: string | null = null;
  destination?: string | null;
  payload?: LaunchPayloadDto | null = null;

  constructor(init?: Partial<LaunchDto>) {
    Object.assign(this, init);
  }
}

export class SolisUserPreferencesDto {
  theme?: string | null = null;
  gridPreferences?: Map<string, SolisGridPreferencesDto> | null = null;

  constructor(init?: Partial<SolisUserPreferencesDto>) {
    Object.assign(this, init);
  }
}

export class SolisGridPreferencesDto {
  columns: string[] = [];

  constructor(init?: Partial<SolisGridPreferencesDto>) {
    Object.assign(this, init);
  }
}

export class LaunchPayloadDto {
  proposal?: SolisLaunchProposalDto | null = null;
  parties?: SolisLaunchPartyDto[] | null = null;
  risk?: SolisLaunchRiskDto[] | null = null;
  contract?: SolisLaunchContractDto | null = null;
  inforceManagement?: SolisLaunchInforceManagementDto | null = null;
  configuration?: SolisLaunchConfigurationDto | null = null;
  platform?: SolisLaunchPlatformDto | null = null;

  constructor(init?: Partial<LaunchPayloadDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchProposalDto {
  proposalDetailId?: string | null = null;
  proposalCaseId?: string | null = null;
  proposalCreatedDate?: Date | null = null;
  cusip?: string | null = null;
  productCode?: string | null = null;
  proposalName?: string | null = null;
  teamCode?: string | null = null;
  ownershipType?: OwnershipType | null = null;
  contractState?: string | null = null;
  solicitationState?: string | null = null;
  premium?: number | null = null;
  exchangeAmount?: number | null = null;
  rateOfReturn?: number | null = null;
  federalTaxRate?: number | null = null;
  exchangeIssueDate?: Date | null = null;
  taxQualification?: boolean | null = null;
  brokerageAccountNumber?: string | null = null;
  subaccounts?: SolisLaunchSubaccountDto[] | null = null;
  riders?: string[] | null = null;
  compensationTypes?: string[] | null = null;
  aoeExtensions?: any | null = null;
  advisorDiscretion?: SolisLaunchAdvisorDiscretionDto | null = null;

  constructor(init?: Partial<SolisLaunchProposalDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchPartyDto {
  partyType?: PartyType | null = null;
  partyRoleType?: PartyRoleType | null = null;
  firstName?: string | null = null;
  lastName?: string | null = null;
  middleName?: string | null = null;
  gender?: string | null = null;
  dateOfBirth?: Date | null = null;
  ssn?: string | null = null;
  npn?: string | null = null;
  maritalStatus?: string | null = null;
  investorClassification?: string | null = null;
  age?: number | null = null;
  residentStateCode?: string | null = null;
  platformUniqueId?: string | null = null;
  relationshipToOwner?: string | null = null;
  relationshipToAnnuitant?: string | null = null;
  isOwner?: boolean | null = null;
  includeInProposal?: boolean | null = null;
  phoneNumber1?: string | null = null;
  phoneNumber2?: string | null = null;
  email?: string | null = null;
  initialAllocation?: number | null = null;
  finalAllocation?: number | null = null;
  entityName?: string | null = null;
  tin?: string | null = null;
  trustFormationDate?: Date | null = null;
  addresses?: SolisLaunchAddressDto[] | null = null;

  constructor(init?: Partial<SolisLaunchPartyDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchAddressDto {
  addressType: AddressType = AddressType.Primary;
  addressLine1?: string | null = null;
  addressLine2?: string | null = null;
  city?: string | null = null;
  state?: string | null = null;
  zipCode?: string | null = null;
  countryCode?: string | null = null;

  constructor(init?: Partial<SolisLaunchAddressDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchRiskDto {
  riskType?: RiskType;
  riskObjective?: string | null = null;
  platformRiskProfileId?: string | null = null;
  platformRiskScaleId?: string | null = null;
  lowRiskTolerance?: number | null = null;
  highRiskTolerance?: number | null = null;
  riskScore?: number | null = null;
  riskScale?: SolisLaunchRiskScaleDto[] | null = null;

  constructor(init?: Partial<SolisLaunchRiskDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchRiskScaleDto {
  lowRisk?: number | null = null;
  highRisk?: number | null = null;
  name?: string | null = null;

  constructor(init?: Partial<SolisLaunchRiskScaleDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchContractDto {
  contractNumber?: string | null = null;
  carrierCode?: string | null = null;

  constructor(init?: Partial<SolisLaunchRiskScaleDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchConfigurationDto {
  launchMethod?: ExternalLaunchMethod | null = null;
  flow?: SolisFlow | null = null;
  destinationPage?: SolisDestinationPage | null = null;
  allowableProducts?: SolisLaunchAllowableProductDto[] | null = null;
  allowableContracts?: string[] | null = null;
  proposalStrategyTypes?: ProposalStrategyType[] | null = null;
  proposalUserPermissions?: string[] | null = null;
  customFilters?: string[] | null = null;
  annuityTypes?: string[] | null = null;
  benefitTypes?: string[] | null = null;
  firmDtccMemberCode?: string | null = null;
  firmType?: string | null = null;
  firmName?: string | null = null;

  constructor(init?: Partial<SolisLaunchConfigurationDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchAllowableProductDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  riskTreatment?: string | null = null;

  constructor(init?: Partial<SolisLaunchAllowableProductDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchInforceManagementDto {
  allowInforceTransaction?: boolean | null = null;
  workflow?: string | null = null;
  carrierCode?: string | null = null;

  constructor(init?: Partial<SolisLaunchInforceManagementDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchPlatformContractDto {
  contractNumber?: string | null = null;
  platformContractId?: string | null = null;
  carrierCode?: string | null = null;

  constructor(init?: Partial<SolisLaunchPlatformContractDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchPlatformDto {
  platformCode?: string | null = null;
  platformFirmCode?: string | null = null;
  platformAdvisorId?: string | null = null;
  platformAuthenticationId?: string | null = null;
  platformMessageDomain?: string | null = null;
  platformProposalId?: string | null = null;
  platformProductId?: string | null = null;
  platformCustomerId?: string | null = null;
  platformBranchId?: string | null = null;
  platformAccountId?: string | null = null;
  platformModelId?: string | null = null;
  platformContractId?: string | null = null;
  platformRiskProfileId?: string | null = null;
  platformRiskScaleId?: string | null = null;
  documents?: SolisLaunchPlatformDocumentDto[] | null = null;
  contracts?: SolisLaunchPlatformContractDto[] | null = null;

  constructor(init?: Partial<SolisLaunchPlatformDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchPlatformDocumentDto {
  solisDocumentType: SolisDocumentType = SolisDocumentType.Sis;
  platformDocumentId?: string | null = null;
  documentTitle?: string | null = null;
  platformDocumentType?: string | null = null;
  encodedContent?: string | null = null;

  constructor(init?: Partial<SolisLaunchPlatformDocumentDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchSubaccountDto {
  name?: string;
  amount?: number;
  fee?: number;
  subaccountKey?: string | null = null;
  morningstarInvestmentVehicleId?: string | null = null;
  subaccountProductCode?: string | null = null;
  subaccountSelectionType?: string | null = null;
  allocationPct?: number | null = null;

  constructor(init?: Partial<SolisLaunchSubaccountDto>) {
    Object.assign(this, init);
  }
}

export class SolisLaunchAdvisorDiscretionDto {
  discretionSelection?: boolean;

  constructor(init?: Partial<SolisLaunchAdvisorDiscretionDto>) {
    Object.assign(this, init);
  }
}

export class SolisSessionDto {
  sessionData?: any;

  constructor(init?: Partial<SolisSessionDto>) {
    Object.assign(this, init);
  }
}

export class SolisTermsAcceptedDto {
  version?: string | null = null;
  dateAccepted?: Date | null = null;

  constructor(init?: Partial<SolisTermsAcceptedDto>) {
    Object.assign(this, init);
  }
}
