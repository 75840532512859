import { AddressType, FirmType, SettingEntityType, SettingValueType } from './infrastructure.enums';

export class UserAddressDto {
  addressTypeCode?: AddressType | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;

  constructor(init?: Partial<UserAddressDto>) {
    Object.assign(this, init);
  }
}

export class FirmDto {
  dtccMemberCode?: string | null = null;
  type: FirmType = FirmType.DtccDistributor;
  name?: string | null = null;
  isDtccMember: boolean = false;
  detailName?: string | null = null;
  description?: string | null = null;
  govtTaxId?: string | null = null;
  phone?: string | null = null;
  emailAddress?: string | null = null;
  isEnabled: boolean = false;
  addresses?: FirmAddressDto[] | null = null;
  aliases?: FirmAliasDto[] | null = null;

  constructor(init?: Partial<FirmDto>) {
    Object.assign(this, init);
  }
}

export class FirmAliasDto {
  id: string = '';
  name?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<FirmAliasDto>) {
    Object.assign(this, init);
  }
}

export class FirmAddressDto {
  addressLine1?: string | null = null;
  addressLine2?: string | null = null;
  city?: string | null = null;
  state?: string | null = null;
  zipCode?: string | null = null;

  constructor(init?: Partial<FirmAddressDto>) {
    Object.assign(this, init);
  }
}

export class FormDefinitionDto {
  code?: string | null = null;
  description?: string | null = null;
  definition?: string | null = null;

  constructor(init?: Partial<FormDefinitionDto>) {
    Object.assign(this, init);
  }
}

export class FormSubmissionDto {
  id: string = '';
  formDefinitionId: string = '';
  value?: string | null = null;
  referenceType?: string | null = null;
  referenceKey?: string | null = null;

  constructor(init?: Partial<FormSubmissionDto>) {
    Object.assign(this, init);
  }
}

export class FormSubmissionCreateDto {
  formDefinitionCode?: string | null = null;
  value?: string | null = null;
  referenceType?: string | null = null;
  referenceKey?: string | null = null;

  constructor(init?: Partial<FormSubmissionCreateDto>) {
    Object.assign(this, init);
  }
}

export class JurisdictionDto {
  id: string = '';
  code?: string | null = null;
  name?: string | null = null;

  constructor(init?: Partial<JurisdictionDto>) {
    Object.assign(this, init);
  }
}

export class PlatformDto {
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<PlatformDto>) {
    Object.assign(this, init);
  }
}

export class SettingDto {
  settingValueType: SettingValueType = SettingValueType.Boolean;
  key?: string | null = null;
  value?: any = null;

  constructor(init?: Partial<SettingDto>) {
    Object.assign(this, init);
  }
}

export class SettingEntityDto {
  type: SettingEntityType = SettingEntityType.Platform;
  code?: string | null = null;

  constructor(init?: Partial<SettingEntityDto>) {
    Object.assign(this, init);
  }
}

export class SettingSearchCriteriaDto {
  keys?: string[] | null = [];
  entities?: SettingEntityDto[] | null = [];
  tags?: string[] | null = [];

  constructor(init?: Partial<SettingSearchCriteriaDto>) {
    Object.assign(this, init);
  }
}

export class UserDto {
  id: string = '';
  userName?: string | null = null;
  firstName?: string | null = null;
  middleName?: string | null = null;
  lastName?: string | null = null;
  email?: string | null = null;
  emailConfirmed: boolean = false;
  phoneNumber?: string | null = null;
  phoneNumberConfirmed: boolean = false;
  ssn?: string | null = null;
  npn?: string | null = null;
  residentState?: string | null = null;
  sourceSystem?: string | null = null;
  isBlocked: boolean = false;
  isDeleted: boolean = false;
  capabilities?: Record<string, string[]> | null = null;
  personas?: Record<string, string[]> | null = null;
  isTestUser: boolean = false;
  platformCodes?: string[] | null = null;
  canImpersonateUsers?: UserInfoDto[] | null = null;
  firms?: FirmDto[] | null = null;
  addresses?: UserAddressDto[] | null = null;

  constructor(init?: Partial<UserDto>) {
    Object.assign(this, init);
  }
}

export class PersonaDto {
  id: string = '';
  displayName: string = '';
  personaCode: string = '';
}

export class PlatformCredentialsRequestDto {
  firstName?: string | null = null;
  lastName?: string | null = null;
  emailAddress?: string | null = null;
  userId?: string | null = null;
  firmId?: string | null = null;

  constructor(init?: Partial<PlatformCredentialsRequestDto>) {
    Object.assign(this, init);
  }
}

export class PlatformCredentialsResponseDto {
  validationResult: boolean = false;
  message?: string | null = null;

  constructor(init?: Partial<PlatformCredentialsResponseDto>) {
    Object.assign(this, init);
  }
}

export class UserUpsertDto {
  userName?: string | null = null;
  email?: string | null = null;
  firstName?: string | null = null;
  middleName?: string | null = null;
  lastName?: string | null = null;
  phoneNumber?: string | null = null;
  otherPhone?: string | null = null;
  ssn?: string | null = null;
  npn?: string | null = null;
  residentState?: string | null = null;
  firmDtccMemberCode?: string | null = null;
  firmType?: FirmType | null = null;
  isBlocked: boolean = false;
  addresses?: UserAddressDto[] | null = [];

  constructor(init?: Partial<UserUpsertDto>) {
    Object.assign(this, init);
  }
}

export class ManageUserPersonasDto {
  addPersonas?: string[] | null = [];
  removePersonas?: string[] | null = [];

  constructor(init?: Partial<ManageUserPersonasDto>) {
    Object.assign(this, init);
  }
}

export class UserImpersonationsDto {
  dtccMemberCode?: string | null = null;
  firmType: FirmType = FirmType.DtccDistributor;
  impersonations?: string[] | null = [];

  constructor(init?: Partial<UserImpersonationsDto>) {
    Object.assign(this, init);
  }
}

export class UserInfoDto {
  id: string = '';
  userName?: string | null = null;
  firstName?: string | null = null;
  middleName?: string | null = null;
  lastName?: string | null = null;
  email?: string | null = null;
  phoneNumber?: string | null = null;
  residentState?: string | null = null;
  ssn?: string | null = null;
  npn?: string | null = null;
  capabilities?: Record<string, string[]> | null = null;
  personas?: Record<string, string[]> | null = null;
  platformCodes?: string[] | null = null;
  isBlocked: boolean = false;
  isTestUser: boolean = false;

  constructor(init?: Partial<UserInfoDto>) {
    Object.assign(this, init);
  }
}

export class UserSearchCriteriaDto {
  userName?: string | null = null;
  email?: string | null = null;
  dtccMemberCode?: string | null = null;
  firmType: FirmType = FirmType.DtccDistributor;
  npn?: string | null = null;
  ssn?: string | null = null;
  personas?: string[] | null = null;
  capabilities?: string[] | null = null;

  constructor(init?: Partial<UserSearchCriteriaDto>) {
    Object.assign(this, init);
  }
}

export class TeamDto {
  teamCode?: string | null = null;
  aoeRequireMultiRep: boolean = false;
  producerUserTeamMultiRepOverride: boolean = false;
  members?: TeamUserDto[] | null = null;

  constructor(init?: Partial<TeamDto>) {
    Object.assign(this, init);
  }
}

export class TeamUserDto {
  repCode?: string | null = null;
  allocationPct: number = 0;
  isPrimary: boolean = false;
  firstName?: string | null = null;
  lastName?: string | null = null;
  ssn?: string | null = null;
  npn?: string | null = null;
  providerCarrierCode?: string | null = null;
  providerAdvisorId?: string | null = null;
  branchCode?: string | null = null;

  constructor(init?: Partial<TeamUserDto>) {
    Object.assign(this, init);
  }
}

export class ThirdPartyNotificationDto {
  payload?: any = null;
  type?: string | null = null;

  constructor(init?: Partial<ThirdPartyNotificationDto>) {
    Object.assign(this, init);
  }
}
