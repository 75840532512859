import { inject } from '@angular/core';
import { SolisSessionService } from '../../../session/solis-session.service';

export const lombardGuard = () => {
  const allowLombard = inject(SolisSessionService).getSettingValue<boolean>('Integration.Lombard.Enabled');
  if (allowLombard) {
    return true;
  }
  return false;
};
