import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { SolisSessionService } from '../session/solis-session.service';

export const capabilityGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
  const router = inject(Router);
  const solisSessionService = inject(SolisSessionService);
  const anyCapabilities: Array<string> = [];
  const allCapabilities: Array<string> = [];

  function notAuthorized(): UrlTree {
    return router.parseUrl('/errors/403');
  }

  let currentRoute: ActivatedRouteSnapshot | null = route;

  while (currentRoute) {
    let currentRouteCapabilities = currentRoute.data['capabilities'] as any;

    if (currentRouteCapabilities) {
      if (currentRouteCapabilities.any) {
        anyCapabilities.push(...currentRouteCapabilities.any);
      }
      if (currentRouteCapabilities.all) {
        allCapabilities.push(...currentRouteCapabilities.all);
      }
    }

    currentRoute = currentRoute.parent;
  }

  // No capabilities specified at all, unguarded route
  if (allCapabilities.length === 0 && anyCapabilities.length === 0) {
    return true;
  }

  // Ensure there is a user to check before continuing
  if (!solisSessionService.user) {
    return false;
  }

  // If "all" are specified, bail if user doesn't have all
  if (allCapabilities.length > 0 && !solisSessionService.userHasAllCapabilities(...allCapabilities)) {
    return notAuthorized();
  }

  // If no "any" capabilitie were specified, consider the route unguarded
  if (anyCapabilities.length === 0) {
    return true;
  }

  if (anyCapabilities.length > 0 && !solisSessionService.userHasAnyCapability(...anyCapabilities)) {
    return notAuthorized();
  }

  return true;
};

export const capabilityGuardChild = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => capabilityGuard(route, state);
