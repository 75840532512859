<div class="p-2" [style.min-width.px]="viewModel.minWidthCalc" [style.max-width.px]="viewModel.maxWidthCalc" [formGroup]="viewModel.form">
  <h5 class="fw-bold terms-header">{{ viewModel.header }}</h5>
  <div class="description-text">We have updated our Terms of Use and Privacy Policy. Please verify and agree to the following:</div>
  <div class="terms-checkbox">
    <p-checkbox checkboxIcon="fa-solid fa-check" formControlName="terms" [binary]="true" inputId="terms"></p-checkbox>
    <label class="terms-label" for="terms">
      I agree to abide by the <a [href]="viewModel.termsUrl" target="_blank">Terms of Use</a> <span class="form-required spc"></span>
    </label>
  </div>
  <div class="terms-checkbox">
    <p-checkbox checkboxIcon="fa-solid fa-check" formControlName="privacy" [binary]="true" inputId="privacy"></p-checkbox>
    <label class="terms-label" for="privacy">
      I agree to the Insurance Exchange <a [href]="viewModel.privacyUrl" target="_blank">Privacy Policy</a>
      <span class="form-required spc"></span>
    </label>
  </div>
</div>

<div class="w-100 d-flex flex-row justify-content-end">
  <button type="button" label="Continue" [disabled]="!viewModel.isFormValid()" class="btn btn-primary" (click)="viewModel.submitForm()">
    Submit
  </button>
</div>
