import { Component, Injector } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DropdownModule } from 'primeng/dropdown';

import { ComponentBase } from 'framework';

import { AdvisorSubHeaderViewModel } from './advisor-sub-header.view-models';

@Component({
  selector: 'app-advisor-sub-header',
  standalone: true,
  imports: [RouterModule, BreadcrumbModule, DropdownModule],
  providers: [AdvisorSubHeaderViewModel],
  templateUrl: './advisor-sub-header.component.html',
  styleUrls: ['./advisor-sub-header.component.scss']
})
export class AdvisorSubHeaderComponent extends ComponentBase<AdvisorSubHeaderViewModel> {
  constructor(
    protected override injector: Injector,
    viewModel: AdvisorSubHeaderViewModel
  ) {
    super(injector, viewModel);
  }
}
