import { Component, Injector, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';

import { InputTextModule } from 'primeng/inputtext';

import { ComponentBase, IModalValueProvider } from 'framework';

import { ModalTextInputMode, ModalTextInputViewModel } from './model-text-input.view-model';

@Component({
  selector: 'app-modal-text-input',
  standalone: true,
  imports: [CommonModule, InputTextModule, FormsModule, ReactiveFormsModule],
  providers: [ModalTextInputViewModel],
  templateUrl: './modal-text-input.component.html',
  styleUrls: ['./modal-text-input.component.scss']
})
export class ModalTextInputComponent extends ComponentBase<ModalTextInputViewModel> implements IModalValueProvider<string> {
  @Input() set valueSubject(value: Subject<string | undefined>) {
    this.viewModel.valueSubject = value;
  }
  @Input() set header(value: string | undefined) {
    this.viewModel.header = value;
  }
  @Input() set subHeader(value: string | undefined) {
    this.viewModel.subHeader = value;
  }
  @Input() set maxChars(value: number | undefined) {
    this.viewModel.maxChars = value;
  }
  @Input() set minWidth(value: number | undefined) {
    this.viewModel.minWidth = value;
  }
  @Input() set placeHolder(value: string | undefined) {
    this.viewModel.placeHolder = value;
  }
  @Input() set continueButtonText(value: string) {
    this.viewModel.continueButtonText = value;
  }
  @Input() set mode(value: ModalTextInputMode | undefined) {
    this.viewModel.mode = value;
  }
  @Input() set required(value: boolean) {
    this.viewModel.required = value;
  }
  @Input() set height(value: number | undefined) {
    this.viewModel.height = value;
  }

  constructor(protected override injector: Injector, viewModel: ModalTextInputViewModel) {
    super(injector, viewModel);
  }
}
