import { SessionKeys } from '../../constants/session-keys';

export class LocalSession {
  public get<T>(key: string) {
    const sessionValue = sessionStorage.getItem(key);
    if (sessionValue) {
      return JSON.parse(sessionValue) as any as T;
    }

    return null;
  }

  public async set(key: string, value: any): Promise<void> {
    // Only allow certain types to be set
    if (['number', 'boolean', 'string', 'object'].indexOf(typeof value) > -1) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  public clearSessionKeys(): void {
    // Remove all the local session key objects
    const keys = Object.keys(SessionKeys);
    keys.forEach((sessionKey) => {
      sessionStorage.setItem(sessionKey, JSON.stringify(null));
    });
  }
}
