import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';

import { SolisSessionType } from './services/session/solis-session-service.enums';

import { AdvisorLayoutComponent } from './layouts/advisor/advisor-layout.component';
import { AdvisorRoutes } from './layouts/advisor/advisor-routes';
import { PartnerLayoutComponent } from './layouts/partner/partner-layout.component';
import { PartnerRoutes } from './layouts/partner/partner-routes';

import { capabilityGuardChild } from './services/guards/capability.guard';
import { sessionTypeGuardChild } from './services/guards/session-type.guard';

const ADVISOR_CAPABILITIES: any = { any: ['annuity_purchaser', 'case_creator'], all: [] };
const ADVISOR_LAYOUT = ['navbar', 'subheader', 'advisor-tools', 'proposal-info', 'footer'];
export const ADVISOR_ROOT_PREFIX = '';

const PARTNER_CAPABILITIES: any = {
  any: ['case_illustrator', 'case_assistant', 'aoe_reviewer', 'case_admin', 'case_creator'],
  all: []
};
const PARTNER_LAYOUT = ['navbar', 'subheader', 'footer'];
export const PARTNER_ROOT_PREFIX = 'partners';

export const routes: Routes = [
  {
    path: ADVISOR_ROOT_PREFIX,
    component: AdvisorLayoutComponent,
    title: 'FIDx Insurance Exchange',
    canActivateChild: [capabilityGuardChild, sessionTypeGuardChild],
    data: { capabilities: ADVISOR_CAPABILITIES, sessionType: SolisSessionType.Advisor, layout: ADVISOR_LAYOUT },
    children: AdvisorRoutes
  },
  {
    path: PARTNER_ROOT_PREFIX,
    component: PartnerLayoutComponent,
    title: 'FIDx Partner Portal',
    canActivateChild: [capabilityGuardChild, sessionTypeGuardChild],
    data: { capabilities: PARTNER_CAPABILITIES, sessionType: SolisSessionType.Partner, layout: PARTNER_LAYOUT },
    children: PartnerRoutes
  },
  {
    path: 'form-demo',
    title: 'Form Demo',
    data: { breadcrumb: { label: 'Form Demo' } },
    loadComponent: () => import('./pages/form-demo/form-demo.component').then((m) => m.FormDemoComponent)
  },
  {
    path: 'errors/:errorCode',
    data: { title: 'FIDx Error' },
    loadComponent: () => import('./pages/error/error-page.component').then((m) => m.ErrorPageComponent)
  },
  {
    path: 'mock-ui-test',
    title: 'Local UI Testing',
    loadComponent: () => import('./pages/mock-ui-test/mock-ui-test.component').then((m) => m.MockUiTestComponent)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/errors/404',
    data: { title: 'Not Found' }
  }
];

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.createTitle(routerState.root, '', ' - ');
    if (title) {
      this.title.setTitle(title.substring(3));
    }
  }

  private createTitle(route: ActivatedRouteSnapshot, title: string, separator: string): string {
    if (!route) {
      return title;
    }

    const subTitle = this.getResolvedTitleForRoute(route);

    if (subTitle) {
      title = `${title}${separator}${subTitle}`;
    }

    title = this.createTitle(route.children[0], title, separator);

    return title;
  }
}
