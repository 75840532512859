import { Injectable } from '@angular/core';

import { MenuItem } from 'primeng/api';

import { SubscriptionService } from 'framework';

import { EventNames } from '../../../constants/event-names';
import { RequestNames } from '../../../constants/request-names';

import { BreadcrumbState } from '../../../services/navigation/navigation.models';
import { SolisViewModelBase } from '../../solis-view-model.base';

@Injectable()
export class PartnerSubHeaderViewModel extends SolisViewModelBase {
  home: MenuItem | undefined;
  breadcrumbItems: MenuItem[] = [];
  subheaderTitle: string = '';
  advisorToolsTitle: string = 'Advisor Tools';
  previousUrl: string | null = null;
  currentUrl: string | null = null;
  proposalName: string = '';
  navigateUrl: string = '';
  modalHeaderText: string = '';
  modalBodyText: string = '';
  displayModal: boolean = false;
  annuities: any[] = [];

  constructor() {
    super();
  }

  initialize() {}

  override initializeSubscriptions(subscriptionService: SubscriptionService): void {
    subscriptionService.subscribeEvent<BreadcrumbState>(
      EventNames.websiteBreadcrumbsChanged,
      (data: BreadcrumbState) => {
        this.breadcrumbItems = [...data.breadcrumbItems];
        this.home = data.home;
      },
      true
    );

    subscriptionService.subscribeEvent<BreadcrumbState>(
      EventNames.pageTitleChanged,
      (data: string) => {
        this.subheaderTitle = data;
      },
      true
    );

    subscriptionService.registerRequestHandler<string, void>(RequestNames.setPageTitle, (data) => {
      this.subheaderTitle = data.request ?? '';
    });
  }

  hasRoute(route: string): boolean {
    return this.router.url.includes(route);
  }

  // NAVIGATE OUT
  navigateTo(): void {
    this.displayModal = false;
    setTimeout(() => {
      this.navigationService.navigateNewTab(this.navigateUrl);
    }, 100);
  }
}
