import { Component, Injector, OnDestroy, OnInit, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { ViewModelBase } from './view-model.base';

@Component({ template: '' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ComponentBase<T extends ViewModelBase> implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  protected viewModel: T;

  constructor(protected injector: Injector, viewModel: ViewModelBase) {
    this.viewModel = viewModel as any;
  }

  ngOnInit() {
    this.viewModel.internalInject(this.injector);
    this.viewModel.internalValidateState();
    this.viewModel.internalInitializeProcess();
  }

  ngAfterViewInit() {
    this.viewModel.afterInitialize();
  }

  ngOnDestroy() {
    this.viewModel.internalDestroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.viewModel.changes(changes);
  }
}
