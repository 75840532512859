<header class="sticky">
  <nav class="navbar navbar-expand-lg navbar-sticky nav-bg-scroll">
    @if (viewModel.showIxHubMenu) {
      <div class="container-fluid">
        <div class="navbar-wrapper">
          <a class="navbar-brand cursor ms-0">
            <img style="max-height: 2rem" [routerLink]="['/ixhub']" [src]="viewModel.logoFile" />
          </a>
        </div>
        <div>
          <p-menubar [model]="viewModel.ixHubMenuItems" styleClass="ixhub-menu">
            <ng-template pTemplate="submenuicon">
              <i class="fa-solid fa-caret-down"></i>
            </ng-template>
          </p-menubar>
        </div>
      </div>
    } @else {
      <div class="container-fluid">
        <div class="navbar-wrapper">
          <a class="navbar-brand cursor ms-0">
            <img style="max-height: 2rem" [routerLink]="['/']" [src]="viewModel.logoFile" />
          </a>
        </div>
        <button
          aria-label="Toggle navigation"
          class="navbar-toggler"
          (click)="viewModel.collapseIcon($event)"
          [attr.aria-expanded]="!viewModel.isCollapsed"
          aria-controls="collapseExample"
          id="navigation"
          type="button">
          <span class="navbar-toggler-bar navbar-kebab"> </span>
          <span class="navbar-toggler-bar navbar-kebab"> </span>
          <span class="navbar-toggler-bar navbar-kebab"> </span>
        </button>
        <div class="navbar-collapse menu_container justify-content-between" [ngbCollapse]="viewModel.isCollapsed" id="navigation">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="viewModel.collapse($event)"
                [routerLink]="['/dashboard']"
                ngbDropdownItem>
                Dashboard
              </a>
            </li>
            @if (viewModel.allowCompare) {
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  (click)="viewModel.collapse($event)"
                  [routerLink]="['/products/compare']"
                  ngbDropdownItem>
                  Annuity Compare
                </a>
              </li>
            }
            @if (this.viewModel.isUserManager) {
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  (click)="viewModel.collapse($event)"
                  [routerLink]="['/users']"
                  ngbDropdownItem>
                  Users
                </a>
              </li>
            }
            @if (this.viewModel.isAoeReviewer && !this.viewModel.isImpersonationActive) {
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  (click)="viewModel.collapse($event)"
                  [routerLink]="['review']"
                  ngbDropdownItem>
                  Review
                </a>
              </li>
            }
          </ul>
          <ul class="navbar-nav ms-auto text-end-lg">
            <li ngbDropdown>
              <a class="nav-link cursor profile" data-toggle="dropdown" ngbDropdownToggle>
                <span class="mx-2 navbarActiveColor">Hi, {{ viewModel.userFirstName }}</span>
                <b class="caret d-none d-lg-block d-xl-block"></b>
              </a>
              <ul class="dropdown-navbar dropdownBackground dropdown-menu-end" ngbDropdownMenu>
                @if (!viewModel.isImpersonationActive && viewModel.impersonateUsers.length > 0) {
                  <li class="dropdown-header fw-bold mt-2 mb-2">Work on behalf of:</li>
                  @for (user of viewModel.impersonateUsers; track user) {
                    <li>
                      <button class="dropdown-item" (click)="viewModel.impersonate(user.id!)" ngbDropdownItem>
                        {{ user.firstName }} {{ user.lastName }}
                      </button>
                    </li>
                  }
                }
                @if (!viewModel.isImpersonationActive && viewModel.impersonateUsers.length > 0) {
                  <hr class="ms-2 me-2" />
                }
                <li>
                  <button
                    class="dropdown-item"
                    (click)="viewModel.collapse($event)"
                    [routerLink]="['/users', viewModel.userId]"
                    ngbDropdownItem>
                    Profile Settings
                  </button>
                </li>
                <li>
                  <button class="dropdown-item" (click)="viewModel.collapse($event); viewModel.logout()" ngbDropdownItem>Log out</button>
                </li>
              </ul>
            </li>
            <li class="separator d-lg-none"></li>
          </ul>
        </div>
      </div>
    }
  </nav>
</header>
