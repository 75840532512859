import { OwnershipType } from '../services/gateway-api-client/dtos/proposal.enums';

interface IAnnuityProfileModel {
  premium?: number | null;
  ownershipType?: OwnershipType | null;
  primaryOwner?: string | null;
  jurisdiction?: string | null;
  solicitationState?: string | null;
  taxQualification?: boolean | null;
  taxQualificationType?: string | null;
  brokerageAccountNumber?: string | null;
  isAnnuitantOwner?: boolean | null;
  annuitantDateOfBirth?: Date | null;
  annuitantRelationshipToOwner?: string | null;
  ownerDateOfBirth?: Date | null;
  jointOwnerDateOfBirth?: Date | null;
  jointOwnerRelationshipToOwner?: string | null;
  hasJointAnnuitant?: boolean | null;
  jointAnnuitantDateOfBirth?: Date | null;
  jointAnnuitantRelationshipToOwner?: string | null;
  jointAnnuitantRelationshipToAnnuitant?: string | null;
  hasBeneficiary?: boolean | null;
  primaryBeneficiaryDateOfBirth?: Date | null;
  primaryBeneficiaryRelationshipToOwner?: string | null;
  primaryBeneficiaryRelationshipToAnnuitant?: string | null;
  isReplacingExistingContract?: boolean | null;
  annuityType?: string[] | null;
  insuranceCompany?: string[];
  benefitType?: string[] | null;
  compensationType?: string[] | null;
  solutions?: string[];
  filterByDiscretion?: boolean[];
  isAdhocInitialized?: boolean | null;
  repCode?: string | null;
}

export class AnnuityProfileModel {
  premium: number | null;
  ownershipType: OwnershipType | null;
  primaryOwner: string | null;
  jurisdiction: string | null;
  solicitationState: string | null;
  taxQualification: boolean | null;
  taxQualificationType: string | null;
  brokerageAccountNumber: string | null;
  isAnnuitantOwner: boolean | null;
  annuitantDateOfBirth: Date | null;
  annuitantRelationshipToOwner?: string | null;
  ownerDateOfBirth: Date | null;
  jointOwnerDateOfBirth: Date | null;
  jointOwnerRelationshipToOwner: string | null;
  hasJointAnnuitant: boolean | null;
  jointAnnuitantDateOfBirth: Date | null;
  jointAnnuitantRelationshipToOwner: string | null;
  jointAnnuitantRelationshipToAnnuitant: string | null;
  hasBeneficiary: boolean | null;
  primaryBeneficiaryDateOfBirth: Date | null;
  primaryBeneficiaryRelationshipToOwner: string | null;
  primaryBeneficiaryRelationshipToAnnuitant: string | null;
  isReplacingExistingContract: boolean | null;
  annuityType: string[];
  insuranceCompany: string[];
  benefitType: string[];
  compensationType: string[];
  solutions: string[];
  filterByDiscretion: boolean[];
  isAdhocInitialized: boolean | null;
  repCode: string | null;

  constructor(options: IAnnuityProfileModel = {}) {
    this.premium = options.premium || null;
    this.ownershipType = options.ownershipType || null;
    this.primaryOwner = options.primaryOwner || null;
    this.jurisdiction = options.jurisdiction || null;
    this.solicitationState = options.solicitationState || null;
    this.taxQualificationType = options.taxQualificationType || null;
    this.taxQualification = this.getTaxQualification() ?? options.taxQualification ?? null;
    this.brokerageAccountNumber = options.brokerageAccountNumber || null;
    this.isAnnuitantOwner = (options.isAnnuitantOwner ?? null) === null ? null : (options.isAnnuitantOwner as unknown) === true;
    this.annuitantDateOfBirth = options.annuitantDateOfBirth || null;
    this.annuitantRelationshipToOwner = options.annuitantRelationshipToOwner || null;
    this.ownerDateOfBirth = options.ownerDateOfBirth || null;
    this.jointOwnerDateOfBirth = options.jointOwnerDateOfBirth || null;
    this.jointOwnerRelationshipToOwner = options.jointOwnerRelationshipToOwner || null;
    this.hasJointAnnuitant = options.hasJointAnnuitant || false;
    this.jointAnnuitantDateOfBirth = options.jointAnnuitantDateOfBirth || null;
    this.jointAnnuitantRelationshipToOwner = options.jointAnnuitantRelationshipToOwner || null;
    this.jointAnnuitantRelationshipToAnnuitant = options.jointAnnuitantRelationshipToAnnuitant || null;
    this.hasBeneficiary = options.hasBeneficiary || null;
    this.primaryBeneficiaryDateOfBirth = options.primaryBeneficiaryDateOfBirth || null;
    this.primaryBeneficiaryRelationshipToOwner = options.primaryBeneficiaryRelationshipToOwner || null;
    this.primaryBeneficiaryRelationshipToAnnuitant = options.primaryBeneficiaryRelationshipToAnnuitant || null;
    this.isReplacingExistingContract = options.isReplacingExistingContract ?? null;
    this.annuityType = options.annuityType || [];
    this.insuranceCompany = options.insuranceCompany || [];
    this.benefitType = options.benefitType || [];
    this.compensationType = options.compensationType || [];
    this.solutions = options.solutions || [];
    this.filterByDiscretion = options.filterByDiscretion || [];
    this.isAdhocInitialized = options.isAdhocInitialized || null;
    this.repCode = options.repCode || null;
  }

  getTaxQualification() {
    const taxQualType = this.taxQualificationType?.split(',') ?? [];
    return taxQualType.length > 0 ? taxQualType[0] !== '1' : null;
  }
}

export enum AnnuitySolutions {
  Annuities = 'Annuities',
  InsuranceDesks = 'InsuranceDesks',
  PrivatePlacement = 'PrivatePlacement',
  OffPlatformProducts = 'OffPlatformProducts'
}
