import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { SolisSessionService } from '../session/solis-session.service';
import { SolisSessionType } from '../session/solis-session-service.enums';

import { ADVISOR_ROOT_PREFIX, PARTNER_ROOT_PREFIX } from '../../app-routes';

export const sessionTypeGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
  const router = inject(Router);
  const solisSessionService = inject(SolisSessionService);

  // If session mode hasn't been set yet, reject
  if (!solisSessionService.sessionType) {
    return false;
  }

  let routeSessionType: SolisSessionType | null = null;

  // Get the sessionType for this route. This is defined as metadata on the routes
  let currentRoute: ActivatedRouteSnapshot | null = route;

  while (currentRoute) {
    let currentRouteSessionType = currentRoute.data['sessionType'] as SolisSessionType;

    if (currentRouteSessionType) {
      routeSessionType = currentRouteSessionType;
      break;
    }

    currentRoute = currentRoute.parent;
  }

  // No session type specified on this set of routes, just continue on
  if (!routeSessionType) {
    return true;
  }

  const activeSessionType = solisSessionService.sessionType;

  // If active session is an Advisor session but we're currently trying to go to a Partner route, redirect back to Advisor home
  // This can happen if the user tries to manually navigate to a different part of the site
  if (activeSessionType === SolisSessionType.Advisor && routeSessionType === SolisSessionType.Partner) {
    return router.parseUrl(`/${ADVISOR_ROOT_PREFIX}`);
  }

  // ... and vice versa
  if (activeSessionType === SolisSessionType.Partner && routeSessionType === SolisSessionType.Advisor) {
    return router.parseUrl(`/${PARTNER_ROOT_PREFIX}`);
  }

  return true;
};

export const sessionTypeGuardChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  sessionTypeGuard(route, state);
