import { Injectable } from '@angular/core';

import { SessionKeys } from '../../constants/session-keys';

import { BusEvent, EventBus } from 'framework';

import { EventNames } from '../../constants/event-names';

import { GatewayApiClient } from '../gateway-api-client/gateway-api-client.service';
import {
  LaunchDto,
  SolisLaunchAddressDto,
  SolisLaunchPartyDto,
  SolisUserPreferencesDto
} from '../gateway-api-client/dtos/client-application.dtos';
import { ExternalLaunchMethod, PartyRoleType, PartyType } from '../gateway-api-client/dtos/client-application.enums';
import { FirmDto as InfrastructureFirmDto, UserDto, SettingDto } from '../gateway-api-client/dtos/infrastructure.dtos';
import { AddressType, FirmType } from '../gateway-api-client/dtos/infrastructure.enums';
import {
  FirmDto as ProposalFirmDto,
  ProposalDetailPartyAddressDto,
  ProposalDetailPartyOrganizationDto,
  ProposalDetailPartyPersonDto
} from '../gateway-api-client/dtos/proposal.dtos';
import {
  GenderType,
  OwnershipType,
  ProposalDetailPartyAddressType,
  ProposalPartyRoleType,
  ProposalPartyType
} from '../gateway-api-client/dtos/proposal.enums';

import { SettingsGetter } from '../settings/settings.getter';

import { WebHelper } from '../../helpers/web/web.helper';

import { AnnuityProfileModel } from '../../models/annuity-profile.models';
import { ClientProfileModel } from '../../models/client-profile.models';
import { ProposalProfileModel } from '../../models/proposal-profile.models';

import { LocalSession } from './local-session';
import { PersistedSession } from './persisted-session';

import { SolisFlow, SolisLaunchMethod, SolisSessionType } from './solis-session-service.enums';
import { SolisSessionPartnerUserRole, SolisSessionTeamMember, SolisSessionTeamMemberRole } from './solis-session-service.models';
import { ProposalService } from '../proposal/proposal.service';
import { AnnuityDetailModel } from '../../models/annuity-detail.models';

@Injectable({
  providedIn: 'root'
})
export class SolisSessionService {
  sessionId!: string;
  postLoginRoute: string | null = null;

  impersonatorUser: UserDto | null = null;
  userPreferences!: SolisUserPreferencesDto;
  launch: LaunchDto | null = null;
  team: SolisSessionTeamMember[] = [];
  launchMethod!: SolisLaunchMethod;
  activeFlow!: SolisFlow;
  localSession: LocalSession = new LocalSession();
  persistedSession!: PersistedSession;

  private sessionUser!: UserDto;
  private isSessionInitialized: boolean = false;
  private isAppInitialized: boolean = false;
  private settingsGetter: SettingsGetter | null = null;
  private isInitialLoad: boolean = false;

  get user(): UserDto {
    return this.sessionUser;
  }

  set user(updatedUser: UserDto) {
    this.sessionUser = updatedUser;
    this.refreshTeam();
  }

  get isInitialized(): boolean {
    return this.isSessionInitialized && this.isAppInitialized;
  }

  get sessionType(): SolisSessionType | null {
    const activeFirm = this.getActiveFirm();

    if (!activeFirm) {
      // Firm not set yet, there is no SessionType
      return null;
    }
    return activeFirm.type! === FirmType.DtccDistributor ? SolisSessionType.Advisor : SolisSessionType.Partner;
  }

  get activePlatformCode(): string {
    return this.launch?.payload!.platform!.platformCode || 'solis';
  }

  get isImpersonationActive(): boolean {
    return this.impersonatorUser !== null;
  }

  // Session is initialized when Service is initialized along with app session data and settings.
  get areSettingsLoaded(): boolean {
    return this.settingsGetter !== null;
  }

  constructor(
    private apiClient: GatewayApiClient,
    private proposalService: ProposalService,
    private eventBus: EventBus
  ) {}

  async initialize(postLoginUrl: string | null | undefined, userId: string, launchId: string | null, theme: string | null): Promise<void> {
    // Load information about user from server
    const userResponse = await this.apiClient.getUser(userId);
    this.sessionUser = userResponse.result!;

    // Load user preferences
    const userPreferencesResponse = await this.apiClient.getUserPreferences();
    this.userPreferences = userPreferencesResponse.result!;

    if (theme) {
      this.updateTheme(theme);
    }

    if (launchId) {
      // If this is an SSO / external launch, load full launch payload from server
      const launchResponse = await this.apiClient.getLaunch(launchId);
      this.launch = launchResponse.result!;
    }

    if (postLoginUrl) {
      const url = new URL(postLoginUrl);
      this.postLoginRoute = `${url.pathname}${url.search}`;
    }

    await this.initializeSession();

    this.initializeLaunchMethodAndFlow();

    await this.initializeImpersonation();

    await this.initializeLocalSession();

    if (this.launch?.payload?.proposal?.proposalDetailId && this.isInitialLoad) {
      await this.prepareAndUpsertProposalPackage();
    }

    this.isSessionInitialized = true;
    this.isInitialLoad = false;
  }

  // SET SETTINGS TO SERVICE
  initializeSettings(settings: SettingDto[]): void {
    if (this.settingsGetter) {
      throw Error(
        WebHelper.formatErrorMessage(
          `Session settings have already been initialized. Any settings with a lifetime of the entire session should have the 'solis_init' tag.`
        )
      );
    }

    this.settingsGetter = new SettingsGetter(settings);
  }

  finishAppInitialize() {
    this.isAppInitialized = true;
  }

  async refreshUser(): Promise<void> {
    const userResponse = await this.apiClient.getUser(this.sessionUser.id!);
    this.sessionUser = userResponse.result!;
  }

  async beginImpersonation(userId: string): Promise<void> {
    if (this.impersonatorUser) {
      await this.endImpersonation();
    }

    // Load impersonated user into the main user slot
    const userResponse = await this.apiClient.getUser(userId);

    if (userResponse.isSuccessStatusCode) {
      // Shift current user into impersonating user
      this.impersonatorUser = this.sessionUser;
      this.sessionUser = userResponse.result!;
      this.localSession.set(SessionKeys.impersonatedUserId, this.sessionUser.id);
      await this.initializeTeam();
      this.eventBus.emit(new BusEvent(EventNames.impersonationChanged));

      return;
    }

    throw Error(WebHelper.formatErrorMessage('Unable to load user for impersonation'));
  }

  async endImpersonation(): Promise<void> {
    if (!this.impersonatorUser) {
      return;
    }

    this.sessionUser = this.impersonatorUser;
    this.impersonatorUser = null;
    this.localSession.set(SessionKeys.impersonatedUserId, null);
    await this.initializeTeam();
    this.eventBus.emit(new BusEvent(EventNames.impersonationChanged));
  }

  async copyFromLocalSessionToPersistedSession(keys: string[]): Promise<void> {
    for (const index in keys) {
      const sessionKey = keys[index];
      const value = this.localSession.get(sessionKey);
      if (value) {
        await this.persistedSession.setAsync(sessionKey, value);
      }
    }
  }

  userHasCapability(capability: string): boolean {
    const firm = this.getActiveFirm();
    const memberCode = firm?.dtccMemberCode;
    if (!memberCode || Object.keys(this.sessionUser.capabilities ?? {}).length <= 0) {
      return false;
    }
    return this.sessionUser!.capabilities![`${memberCode.toLowerCase()}:firm`]?.some((r) => r.toLowerCase() === capability.toLowerCase());
  }

  userHasAllCapabilities(...capabilities: string[]): boolean {
    return capabilities.every((r) => this.userHasCapability(r));
  }

  userHasAnyCapability(...capabilities: string[]): boolean {
    return capabilities.some((r) => this.userHasCapability(r));
  }

  getPartnerUserCapability(): SolisSessionPartnerUserRole {
    if (this.userHasCapability('case_admin')) {
      return SolisSessionPartnerUserRole.Admin;
    }

    if (this.userHasCapability('aoe_reviewer')) {
      return SolisSessionPartnerUserRole.Reviewer;
    }

    if (this.userHasAnyCapability('case_creator')) {
      return SolisSessionPartnerUserRole.Agent;
    }

    if (this.userHasAnyCapability('case_assistant')) {
      return SolisSessionPartnerUserRole.Assistant;
    }

    return SolisSessionPartnerUserRole.Assistant;
  }

  // GET ACTIVE FORM FROM SESSION STORAGE
  getActiveFirm(): InfrastructureFirmDto | null {
    const firmJson = sessionStorage.getItem(SessionKeys.activeFirm);

    if (firmJson) {
      return JSON.parse(firmJson);
    }

    return null;
  }

  // SET ACTIVE FIRM TO SESSION STORAGE
  setActiveFirm(dtccMemberCode: string, firmType: string): void {
    const firm = this.sessionUser?.firms?.filter((e) => e.dtccMemberCode === dtccMemberCode && e.type == firmType)[0] || null;
    sessionStorage.setItem(SessionKeys.activeFirm, JSON.stringify(firm));
  }

  getActiveFlow(): SolisFlow | null {
    return this.localSession.get<SolisFlow>(SessionKeys.activeFlow) ?? this.activeFlow;
  }

  setActiveFlow(activeFlow: SolisFlow): void {
    this.activeFlow = activeFlow;
    this.localSession.set(SessionKeys.activeFlow, activeFlow);
  }

  getSettingValue<T>(key: string, required: boolean = true): T | null {
    if (this.settingsGetter) {
      return this.settingsGetter.getSettingValue<T>(key, required);
    }

    return null;
  }

  async updateTheme(theme: string): Promise<void> {
    this.userPreferences.theme = theme;
    await this.saveUserPreferences();
  }

  async saveUserPreferences(): Promise<void> {
    this.eventBus.emit(new BusEvent(EventNames.userPreferencesChanged));
    await this.apiClient.upsertUserPreferences(this.userPreferences);
  }

  reset(): void {
    this.initializeLocalSession();
  }

  end(): void {
    sessionStorage.clear();
  }

  getAdHocSolisLaunchParty(
    roleType: PartyRoleType = PartyRoleType.PrimaryAdvisor,
    dob: Date = new Date(1955, 1, 1),
    firstName = 'Valued',
    lastName = 'Client',
    solicitationState = 'PA',
    email = 'example@email.abc',
    isOwner = false
  ): SolisLaunchPartyDto {
    const person: SolisLaunchPartyDto = {
      addresses: [
        {
          addressType: AddressType.Primary,
          city: 'WeBuiltThis',
          state: solicitationState,
          zipCode: '12345'
        }
      ],
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dob,
      residentStateCode: solicitationState,
      partyType: PartyType.Person,
      partyRoleType: roleType,
      email,
      ssn: '123456789',
      platformUniqueId: this.sessionUser.id,
      includeInProposal: true,
      isOwner,
      phoneNumber1: '6105551212'
    };

    return person;
  }

  getLaunchAnnuityProfile() {
    const annuitant = this.launch?.payload?.parties?.find((p) => p.partyRoleType?.toString() === PartyRoleType.Annuitant.toString());
    const owner = this.launch?.payload?.parties?.find((p) => p.partyRoleType?.toString() === PartyRoleType.Owner.toString());
    const jointAnnuitant = this.launch?.payload?.parties?.find(
      (p) => p.partyRoleType?.toString() === PartyRoleType.JointAnnuitant.toString()
    );
    const jointOwner = this.launch?.payload?.parties?.find((p) => p.partyRoleType?.toString() === PartyRoleType.JointOwner.toString());
    const beneficiary = this.launch?.payload?.parties?.find((p) => p.partyRoleType?.toString() === PartyRoleType.Beneficiary.toString());

    const annuityProfile = new AnnuityProfileModel({
      premium: this.launch?.payload?.proposal?.premium,
      ownershipType: this.launch?.payload?.proposal?.ownershipType,
      primaryOwner: owner?.partyType,
      jurisdiction: this.launch?.payload?.proposal?.contractState,
      solicitationState: this.launch?.payload?.proposal?.solicitationState,
      taxQualification: this.launch?.payload?.proposal?.taxQualification,
      taxQualificationType: '',
      brokerageAccountNumber: this.launch?.payload?.proposal?.brokerageAccountNumber,
      isAnnuitantOwner: annuitant?.isOwner,
      annuitantDateOfBirth: annuitant?.dateOfBirth,
      annuitantRelationshipToOwner: annuitant?.relationshipToOwner,
      ownerDateOfBirth: owner?.dateOfBirth,
      jointOwnerDateOfBirth: jointOwner ? jointOwner.dateOfBirth : null,
      jointOwnerRelationshipToOwner: jointOwner ? jointOwner.relationshipToOwner : null,
      hasJointAnnuitant: jointAnnuitant !== undefined,
      jointAnnuitantDateOfBirth: jointAnnuitant ? jointAnnuitant.dateOfBirth : null,
      jointAnnuitantRelationshipToOwner: jointAnnuitant ? jointAnnuitant.relationshipToOwner : null,
      jointAnnuitantRelationshipToAnnuitant: jointAnnuitant ? jointAnnuitant.relationshipToAnnuitant : null,
      hasBeneficiary: beneficiary !== undefined,
      primaryBeneficiaryDateOfBirth: beneficiary ? beneficiary?.dateOfBirth : null,
      primaryBeneficiaryRelationshipToOwner: beneficiary ? beneficiary.relationshipToOwner : null,
      primaryBeneficiaryRelationshipToAnnuitant: beneficiary ? beneficiary.relationshipToAnnuitant : null,
      compensationType: this.launch?.payload?.proposal?.compensationTypes,
      annuityType: this.launch?.payload?.configuration?.annuityTypes,
      isReplacingExistingContract: false
    });
    return annuityProfile;
  }

  // This is only activated via a session with a launch payload; currentProfile should have all defaults of the model before entering.
  applySessionDataToAnnuityProfileFromLaunch(currentProfile: AnnuityProfileModel, sessionProfile: AnnuityProfileModel | null = null) {
    currentProfile.insuranceCompany = sessionProfile?.insuranceCompany ?? currentProfile.insuranceCompany;
    currentProfile.benefitType = sessionProfile?.benefitType ?? currentProfile.benefitType;
    currentProfile.solutions = sessionProfile?.solutions ?? currentProfile.solutions;
    currentProfile.filterByDiscretion = sessionProfile?.filterByDiscretion ?? currentProfile.filterByDiscretion;
    currentProfile.taxQualificationType = sessionProfile?.taxQualificationType ?? '';
    currentProfile.isReplacingExistingContract = sessionProfile?.isReplacingExistingContract ?? currentProfile.isReplacingExistingContract;
    currentProfile.brokerageAccountNumber =
      (currentProfile.brokerageAccountNumber ?? null) !== null
        ? currentProfile.brokerageAccountNumber
        : sessionProfile?.brokerageAccountNumber ?? null;
    return currentProfile;
  }

  private async initializeLocalSession(): Promise<void> {
    this.initializeAnnuityProfile();
    this.initializeClientProfile();
    this.initializeProposalProfile();
    await this.initializeTeam();
  }

  private initializeAnnuityProfile() {
    // Check to see if we already have an AnnuityProfileModel in session
    let annuityProfileFromSession = this.localSession.get<AnnuityProfileModel>(SessionKeys.annuityProfile);

    // Setup a profile if none saved
    if (!annuityProfileFromSession) {
      annuityProfileFromSession = new AnnuityProfileModel();
      // Setup defaults if this is not a launch
      if (!this.launch) {
        annuityProfileFromSession.ownershipType = OwnershipType.Individual;
      }
      this.localSession.set(SessionKeys.annuityProfile, annuityProfileFromSession);
    }

    if (this.launch) {
      let newAnnuityProfile = annuityProfileFromSession;
      if (this.launchMethod === SolisLaunchMethod.Embed) {
        // If launch is embedded, we treat each entry as an initial load, so there is no need for an
        newAnnuityProfile = this.getLaunchAnnuityProfile();
        newAnnuityProfile = this.applySessionDataToAnnuityProfileFromLaunch(newAnnuityProfile, annuityProfileFromSession);
      } else if (this.launchMethod === SolisLaunchMethod.Standalone && this.isInitialLoad) {
        newAnnuityProfile = this.getLaunchAnnuityProfile();
      }
      this.localSession.set(SessionKeys.annuityProfile, newAnnuityProfile);
    }
  }

  private initializeClientProfile(): void {
    // Check to see if we already have an ClientProfileModel in session
    let clientProfile = this.localSession.get<ClientProfileModel>(SessionKeys.clientProfile);
    const annuityProfile = this.localSession.get<AnnuityProfileModel>(SessionKeys.annuityProfile);

    if (!clientProfile) {
      clientProfile = new ClientProfileModel({
        dtccMemberCode: this.launch?.payload?.configuration?.firmDtccMemberCode,
        ownershipType: this.launch?.payload?.proposal?.ownershipType || annuityProfile?.ownershipType || OwnershipType.Individual
      });
      this.localSession.set(SessionKeys.clientProfile, clientProfile);
    }

    if (this.launch?.payload?.parties) {
      // TODO: Do we need case instructions?
      clientProfile = new ClientProfileModel({
        dtccMemberCode: this.launch.payload?.configuration?.firmDtccMemberCode,
        isOwnerAnnuitant:
          this.launch.payload.parties.find((p) => p.partyRoleType?.toString() === PartyRoleType.Annuitant.toString())?.isOwner === true,
        doesBeneficiaryExist:
          this.launch.payload.parties.find((p) => p.partyRoleType?.toString() === PartyRoleType.Beneficiary.toString()) !== undefined,
        doesJointAnnuitantExist:
          this.launch.payload.parties.find((p) => p.partyRoleType?.toString() === PartyRoleType.JointAnnuitant.toString()) !== undefined,
        ownershipType: annuityProfile?.ownershipType || this.launch.payload?.proposal?.ownershipType || OwnershipType.Individual
      });

      // Update all non-entity parties
      this.launch.payload.parties
        .filter((p) => p.partyType === null || p.partyType === PartyType.Person)
        .forEach((party) => {
          clientProfile!.persons.push(this.convertToDetailPerson(party));
        });

      // Verify if annuitant is owner; if so make certain owner party exists and its unique id matches that of annuitant.
      const annuitant = clientProfile.persons.find((p) => p.proposalPartyRoleType === ProposalPartyRoleType.Annuitant);
      if (clientProfile.isOwnerAnnuitant === true && annuitant?.isOwner) {
        const owner = clientProfile.persons.find((p) => p.proposalPartyRoleType === ProposalPartyRoleType.Owner);
        if (owner === undefined) {
          const owner = { ...annuitant };
          owner.proposalPartyType = ProposalPartyType.Person;
          owner.proposalPartyRoleType = ProposalPartyRoleType.Owner;
          clientProfile.persons.push(owner);
        } else if (owner.platformUniqueId !== annuitant.platformUniqueId) {
          owner.platformUniqueId = annuitant.platformUniqueId;
        }
      }

      // If PrimaryAdvisor exists in persons, we want to make certain it has appropriate data or default it to sessionUser
      const primaryAdvisor = clientProfile.persons.find((p) => p.proposalPartyRoleType === ProposalPartyRoleType.PrimaryAdvisor);
      if (primaryAdvisor) {
        primaryAdvisor.email = primaryAdvisor.email ?? this.sessionUser.email;
        primaryAdvisor.initialAllocation = primaryAdvisor.initialAllocation ?? 1.0;
        primaryAdvisor.firstName = primaryAdvisor.firstName ?? this.sessionUser.firstName;
        primaryAdvisor.lastName = primaryAdvisor.lastName ?? this.sessionUser.lastName;
        primaryAdvisor.ssn = primaryAdvisor.ssn ?? this.sessionUser.ssn;
        primaryAdvisor.npn = primaryAdvisor.npn ?? this.sessionUser.npn;
        primaryAdvisor.phoneNumber1 = primaryAdvisor.phoneNumber1 ?? this.sessionUser.phoneNumber;
        primaryAdvisor.includeInProposal = primaryAdvisor.includeInProposal ?? true;
        primaryAdvisor.residentStateCode = primaryAdvisor.residentStateCode ?? this.sessionUser.residentState;
      }

      // Update all entity parties
      this.launch.payload.parties
        .filter((p) => p.partyType === PartyType.Organization || p.partyType === PartyType.Trust)
        .forEach((party) => {
          clientProfile!.organizations.push(this.convertToDetailOrganization(party));
        });

      this.localSession.set(SessionKeys.clientProfile, clientProfile);
    }
  }

  private initializeProposalProfile(): void {
    let proposalProfile = this.localSession.get<ProposalProfileModel>(SessionKeys.proposalProfile);

    // If this is a direct session we do not want to re-generate id's otherwise refresh from launch
    if (proposalProfile && !this.launch) {
      return;
    }

    // ProposalDetailId is always taken from the launch, and the ProposalId will also be
    // the ProposalDetailId in this case
    // If we don't get a proposal detail id from the launch (or this is a direct login), just generate random ids
    const proposalDetailId = this.launch?.payload?.proposal?.proposalDetailId ?? WebHelper.generateUuid();
    const proposalId = this.launch?.payload?.proposal?.proposalDetailId ?? WebHelper.generateUuid();

    proposalProfile = new ProposalProfileModel({
      proposalId: proposalId,
      platformProposalId: this.launch?.payload?.platform?.platformProposalId ?? proposalId,
      proposalDetailId: proposalDetailId,
      proposalName: this.launch?.payload?.proposal?.proposalName,
      teamCode: this.launch?.payload?.proposal?.teamCode,
      platformAuthenticationId: this.launch?.payload?.platform?.platformAuthenticationId
    });
    this.localSession.set(SessionKeys.proposalProfile, proposalProfile);
  }

  private convertToDetailPerson(party: SolisLaunchPartyDto): ProposalDetailPartyPersonDto {
    return {
      addresses: this.convertSolisAddressToProposalAddress(party.addresses ?? []),
      age: (party.age ?? undefined) !== undefined ? party.age?.toString() : undefined,
      dateOfBirth: party.dateOfBirth,
      email: party.email,
      firstName: party.firstName,
      gender: party.gender as GenderType,
      includeInProposal: party.includeInProposal,
      initialAllocation: party.initialAllocation,
      investorClassification: party.investorClassification,
      isOwner: party.isOwner ?? false,
      lastName: party.lastName,
      maritalStatus: party.maritalStatus,
      middleName: party.middleName,
      npn: party.npn,
      proposalPartyRoleType:
        (party.partyRoleType ?? undefined) !== undefined ? (party.partyRoleType!.toString() as ProposalPartyRoleType) : undefined,
      proposalPartyType:
        (party.partyType ?? undefined) !== undefined ? (party.partyType!.toString() as ProposalPartyType) : ProposalPartyType.Person,
      phoneNumber1: party.phoneNumber1,
      phoneNumber2: party.phoneNumber2,
      platformUniqueId: party.platformUniqueId ?? WebHelper.generateUuid(),
      relationshipToAnnuitant: party.relationshipToAnnuitant,
      relationshipToOwner: party.relationshipToOwner,
      residentStateCode: party.residentStateCode,
      ssn: party.ssn
    };
  }

  private convertToDetailOrganization(party: SolisLaunchPartyDto): ProposalDetailPartyOrganizationDto {
    return {
      addresses: this.convertSolisAddressToProposalAddress(party.addresses ?? []),
      email: party.email,
      entityName: party.entityName,
      initialAllocation: party.initialAllocation,
      isOwner: party.isOwner,
      proposalPartyRoleType:
        (party.partyRoleType ?? undefined) !== undefined ? (party.partyRoleType!.toString() as ProposalPartyRoleType) : undefined,
      proposalPartyType: (party.partyType ?? undefined) !== undefined ? (party.partyType!.toString() as ProposalPartyType) : undefined,
      phoneNumber1: party.phoneNumber1,
      phoneNumber2: party.phoneNumber2,
      platformUniqueId: party.platformUniqueId,
      relationshipToAnnuitant: party.relationshipToAnnuitant,
      relationshipToOwner: party.relationshipToOwner,
      tin: party.tin,
      trustFormationDate: party.trustFormationDate
    };
  }

  private convertSolisAddressToProposalAddress(addresses: SolisLaunchAddressDto[]): ProposalDetailPartyAddressDto[] {
    return addresses.map((a) => {
      return {
        addressLine1: a.addressLine1,
        addressLine2: a.addressLine2,
        city: a.city,
        state: a.state,
        zipCode: a.zipCode,
        countryCode: a.countryCode,
        type: a.addressType === AddressType.Secondary ? ProposalDetailPartyAddressType.Secondary : ProposalDetailPartyAddressType.Primary
      };
    });
  }

  private async initializeTeam(): Promise<void> {
    if (this.activeFlow === SolisFlow.AdHocIllustration) {
      this.team = this.buildTeamForAdHocLaunch();
      return;
    }

    switch (this.launchMethod) {
      case SolisLaunchMethod.Direct:
        this.team = this.buildTeamForDirectLogin();
        break;
      case SolisLaunchMethod.Embed:
      case SolisLaunchMethod.Standalone:
        this.team = await this.buildTeamForSsoLaunch();
        break;
      default:
        throw Error(WebHelper.formatErrorMessage(`Unhandled launch method '${this.launchMethod}'`));
    }
  }

  private buildTeamForDirectLogin(): SolisSessionTeamMember[] {
    const team: SolisSessionTeamMember[] = [];

    // For direct logins we always have a team of one, which is the logged in user
    team.push(this.getTeamMemberFromLoggedInUser());

    return team;
  }

  private async buildTeamForSsoLaunch(): Promise<SolisSessionTeamMember[]> {
    const team: SolisSessionTeamMember[] = [];

    // Check if there are any team members.  Do not include the logged in user.  They will get added below
    const launchTeamMembers = this.launch?.payload!.parties?.filter((party) => party.partyRoleType === PartyRoleType.TeamMember);

    // If we have team members load them into the team
    if (launchTeamMembers && launchTeamMembers.length > 0) {
      launchTeamMembers.forEach((teammember) => team.push(teammember as SolisSessionTeamMember));
    }

    // Get the primary advisor to add to the team.
    const primaryAdvisor = this.launch?.payload!.parties?.find((party) => party.partyRoleType === PartyRoleType.PrimaryAdvisor);

    const owner = this.launch?.payload!.parties?.find((party) => party.partyRoleType === PartyRoleType.Owner);

    // Add primary advisor to team.  There will always be one.
    if (primaryAdvisor) {
      let advisor = <SolisSessionTeamMember>{
        role: SolisSessionTeamMemberRole.Primary,
        platformUniqueId: primaryAdvisor!.platformUniqueId,
        userId: this.sessionUser.id,
        email: primaryAdvisor!.email ?? this.sessionUser.email,
        initialAllocation: primaryAdvisor!.initialAllocation ?? 1.0,
        firstName: primaryAdvisor!.firstName ?? this.sessionUser.firstName,
        lastName: primaryAdvisor!.lastName ?? this.sessionUser.lastName,
        ssn: primaryAdvisor!.ssn ?? this.sessionUser.ssn,
        npn: primaryAdvisor!.npn ?? this.sessionUser.npn,
        phoneNumber1: primaryAdvisor!.phoneNumber1 ?? this.sessionUser.phoneNumber,
        includeInProposal: primaryAdvisor!.includeInProposal ?? true,
        residentStateCode: owner?.addresses?.[0]?.state ?? primaryAdvisor!.residentStateCode ?? this.sessionUser.residentState
      };
      team.push(advisor);
    }

    // If a team code was provided, our data is considered the final source of truth and may
    // be more up to date than whatever a third party launched with. Make a call to retrieve
    // our own team data and update team accordingly
    const teamCode = this.launch?.payload?.proposal?.teamCode;

    if (teamCode) {
      const dtccMemberCode = this.launch!.payload!.configuration!.firmDtccMemberCode!;
      const firmType = this.launch!.payload!.configuration!.firmType!;
      const teamResponse = await this.apiClient.getTeam(dtccMemberCode, firmType, teamCode);

      if (teamResponse.isSuccessStatusCode) {
        // If a team was found on our end, try to find a matching user in the team data
        // that was passed in from the third party
        const multiRepMembers = teamResponse.result!.members!;

        multiRepMembers.forEach((multiRepMember) => {
          const matchingLaunchMember = team.find(
            (m) =>
              (m.ssn && m.ssn.length > 0 && multiRepMember.ssn && multiRepMember.ssn.length > 0 && m.ssn === multiRepMember.ssn) ||
              (m.npn && m.npn.length > 0 && multiRepMember.npn && multiRepMember.npn.length > 0 && m.npn === multiRepMember.npn)
          );

          if (matchingLaunchMember) {
            // Update launch member with our data
            matchingLaunchMember.initialAllocation = multiRepMember.allocationPct;
            matchingLaunchMember.ssn = multiRepMember.ssn && multiRepMember.ssn.length > 0 ? multiRepMember.ssn : matchingLaunchMember.ssn;
            matchingLaunchMember.npn = multiRepMember.npn && multiRepMember.npn.length > 0 ? multiRepMember.npn : matchingLaunchMember.npn;
            matchingLaunchMember.firstName = multiRepMember.firstName;
            matchingLaunchMember.lastName = multiRepMember.lastName;
          }
        });
      }
    }

    return team;
  }

  private buildTeamForAdHocLaunch(): SolisSessionTeamMember[] {
    const team: SolisSessionTeamMember[] = [];
    const primaryAdvisor = this.getAdHocSolisLaunchParty();

    // Add primary advisor to team.  There will always be one.
    let advisor = <SolisSessionTeamMember>{
      role: SolisSessionTeamMemberRole.Primary,
      platformUniqueId: primaryAdvisor!.platformUniqueId,
      userId: this.sessionUser.id,
      email: primaryAdvisor!.email,
      initialAllocation: primaryAdvisor!.initialAllocation,
      firstName: primaryAdvisor!.firstName!,
      lastName: primaryAdvisor!.lastName!,
      ssn: primaryAdvisor!.ssn,
      npn: primaryAdvisor!.npn,
      phoneNumber1: primaryAdvisor!.phoneNumber1,
      includeInProposal: primaryAdvisor!.includeInProposal,
      residentStateCode: primaryAdvisor!.residentStateCode
    };
    team.push(advisor);

    return team;
  }

  private getTeamMemberFromLoggedInUser(): SolisSessionTeamMember {
    return {
      role: SolisSessionTeamMemberRole.Primary,
      platformUniqueId: this.sessionUser!.id!,
      userId: this.sessionUser!.id!,
      email: this.sessionUser!.email,
      initialAllocation: 1.0,
      finalAllocation: 1.0,
      firstName: this.sessionUser!.firstName!,
      lastName: this.sessionUser!.lastName!,
      ssn: this.sessionUser!.ssn,
      npn: this.sessionUser!.npn,
      phoneNumber1: this.sessionUser!.phoneNumber,
      includeInProposal: true,
      residentStateCode: this.sessionUser!.residentState
    };
  }

  private async initializeSession(): Promise<void> {
    // Check sessionStorage to see if we have an existing session id
    let sessionId = this.localSession.get<string>(SessionKeys.sessionId);
    const proposalDetailId = this.launch?.payload?.proposal?.proposalDetailId;
    const platformProposalId = this.launch?.payload?.platform?.platformProposalId;
    const launchSessionId = proposalDetailId ? proposalDetailId : platformProposalId;

    if (sessionId) {
      // If we have a local session and there is a miss-matched id to incoming launch then clear session
      if (this.launch && sessionId != launchSessionId) {
        this.localSession.clearSessionKeys();
        sessionId = launchSessionId!;
        // Clearing session, treat it as initial load
        this.isInitialLoad = true;
      }
    } else {
      // No sessionId locally. Create it
      sessionId = launchSessionId || WebHelper.generateUuid();
      // There is no session, if launch, indicate initial load
      this.isInitialLoad = true;
    }

    this.sessionId = sessionId;
    this.localSession.set(SessionKeys.sessionId, sessionId);
    this.persistedSession = new PersistedSession(this.apiClient, sessionId!);

    await this.persistedSession.initialize();
    this.transferPersistedSessionToLocal();
  }

  private transferPersistedSessionToLocal(): void {
    this.persistedSession.getAll().forEach((value, key) => {
      this.localSession.set(key, value);
    });
  }

  private initializeLaunchMethodAndFlow(): void {
    const activeFlowFromSession = this.localSession.get<SolisFlow>(SessionKeys.activeFlow);
    const launchMethodFromSession = this.localSession.get<SolisLaunchMethod>(SessionKeys.launchMethod);

    if (activeFlowFromSession && launchMethodFromSession) {
      this.activeFlow = activeFlowFromSession;
      this.launchMethod = launchMethodFromSession;
    } else {
      if (this.launch === null) {
        // Was not launched from SSO
        this.launchMethod = SolisLaunchMethod.Direct;
        this.activeFlow = SolisFlow.PurchaseAnnuity;
      } else {
        switch (this.launch!.payload!.configuration!.launchMethod) {
          case ExternalLaunchMethod.Embed:
            // Was launched from SSO and embedded (iframed) into another website
            this.launchMethod = SolisLaunchMethod.Embed;
            break;
          case ExternalLaunchMethod.Standalone:
            // Was launched from SSO but is showing in its own window
            this.launchMethod = SolisLaunchMethod.Standalone;
            break;
        }

        switch (this.launch!.payload!.configuration!.flow) {
          case SolisFlow.PurchaseAnnuity:
            this.activeFlow = SolisFlow.PurchaseAnnuity;
            break;
          case SolisFlow.AdHocIllustration:
            this.activeFlow = SolisFlow.AdHocIllustration;
            break;
          case SolisFlow.InforceTransaction:
            this.activeFlow = SolisFlow.InforceTransaction;
            break;
          case SolisFlow.ProductReplacement:
            this.activeFlow = SolisFlow.ProductReplacement;
            break;
        }
      }
      this.localSession.set(SessionKeys.activeFlow, this.activeFlow);
      this.localSession.set(SessionKeys.launchMethod, this.launchMethod);
    }
  }

  private async initializeImpersonation(): Promise<void> {
    // If impersonation was in progress, we need to restore it
    const impersonatedUserId = this.localSession.get<string>(SessionKeys.impersonatedUserId);

    if (impersonatedUserId) {
      await this.beginImpersonation(impersonatedUserId);
    }
  }

  private refreshTeam() {
    // If there's a team, this user may be a member of the team. That needs to be updated too
    if (this.team) {
      let teamMember = this.team.find((t) => t.userId === this.sessionUser.id);

      if (teamMember) {
        teamMember.email = this.sessionUser.email;
        teamMember.firstName = this.sessionUser.firstName!;
        teamMember.lastName = this.sessionUser.lastName!;
        teamMember.ssn = this.sessionUser.ssn;
        teamMember.npn = this.sessionUser.npn;
        teamMember.phoneNumber1 = this.sessionUser.phoneNumber;
        teamMember.residentStateCode = this.sessionUser.residentState;

        // Default to true and 100% initial
        teamMember.includeInProposal = true;
        teamMember.initialAllocation = 1.0;
      }
    }
  }

  // This method only happens if a launch happens
  private async prepareAndUpsertProposalPackage() {
    if (!this.getActiveFirm() && this.launch!.payload!.configuration) {
      this.setActiveFirm(this.launch!.payload!.configuration!.firmDtccMemberCode!, this.launch!.payload!.configuration!.firmType!);
    }

    const annuityProfile = this.localSession.get<AnnuityProfileModel>(SessionKeys.annuityProfile)!;
    const proposalProfile = this.localSession.get<ProposalProfileModel>(SessionKeys.proposalProfile)!;
    const clientProfile = this.localSession.get<ClientProfileModel>(SessionKeys.clientProfile) ?? undefined;

    await this.proposalService.upsertProposalPackageFromModels(
      proposalProfile,
      annuityProfile,
      undefined,
      clientProfile,
      (this.getActiveFirm() as ProposalFirmDto) ?? undefined,
      this.activePlatformCode,
      this.launch!.payload!
    );
  }
}
