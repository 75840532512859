export class EventNames {
  static readonly annuityComparisonAddAnnuity = 'annuity-comparison-add-annuity';
  static readonly annuityComparisonDataChanged = 'annuity-comparison-data-changed';
  static readonly annuityComparisonEditAnnuity = 'annuity-comparison-edit-annuity';
  static readonly annuityProfileModelChanged = 'annuity-profile-model-changed';
  static readonly apiActivityChanged = 'api-activity-changed';
  static readonly benefitDeSelectionById = 'benefit-deselection-by-id';
  static readonly blockUI = 'blockui-enabled-event';
  static readonly closeAndExit = 'close-and-exit';
  static readonly hideSubHeader = 'hide-subheader';
  static readonly impersonationChanged = 'impersonation-changed';
  static readonly inforceManagementFormUpdated = 'inforce-management-form-updated';
  static readonly inforceManagementResetFields = 'inforce-management-reset-fields';
  static readonly pageTitleChanged = 'page-title-changed';
  static readonly privatePlacementSelection = 'private-placement-selection';
  static readonly privatePlacementSelectionModalAction = 'private-placement-selection-modal-action';
  static readonly privatePlacementProductSelection = 'private-placement-product-selection';
  static readonly proposalCaseAssigned = 'proposal-case-assigned';
  static readonly proposalCaseCancelled = 'proposal-case-cancelled';
  static readonly proposalCaseCreated = 'proposal-case-created';
  static readonly proposalCaseIllustrationFinalized = 'proposal-case-illustration-finalized';
  static readonly proposalCaseIllustrationSubmitted = 'proposal-case-illustration-submitted';
  static readonly proposalCaseIllustrationUpdated = 'proposal-case-illustration-updated';
  static readonly proposalCaseIllustrationStatusCancelled = 'proposal-case-illustration-status-cancelled';
  static readonly proposalCaseRemoved = 'proposal-case-removed';
  static readonly proposalCaseStatusUpdated = 'proposal-case-status-updated';
  static readonly proposalCleared = 'proposal-cleared';
  static readonly proposalProfileChanged = 'proposal-profile-changed';
  static readonly productDetailCheckRisk = 'product-detail-check-risk';
  static readonly productDetailFilterChanged = 'product-detail-filter-changed';
  static readonly productDetailItemSelectionChanged = 'product-detail-item-selection-changed';
  static readonly productDetailItemSelectionStateLoaded = 'product-detail-item-selection-state-loaded';
  static readonly productDetailItemsInitialized = 'product-detail-items-initialized';
  static readonly productDetailSummaryAllocationChanged = 'product-detail-summary-allocation-changed';
  static readonly productDetailSummaryAllocationItemsInitialized = 'product-detail-summary-allocation-items-initalized';
  static readonly productReplacementChooseExistingContract = 'product-replacement-choose-existing-contract';
  static readonly productReplacementExistingOrDescribeChoice = 'product-replacement-existing-or-describe-choice';
  static readonly productSummaryResearch = 'product-summary-research';
  static readonly productSummarySelection = 'product-summary-selection';
  static readonly requestApiCallCancel = 'request-api-call-cancel';
  static readonly siteLayoutChanged = 'site-layout-changed';
  static readonly subaccountAllocationChanged = 'subaccount-allocation-changed';
  static readonly subaccountDeSelectionById = 'subaccount-deselection-by-id';
  static readonly unblockUI = 'blockui-disabled-event';
  static readonly updateRiskLabelScore = 'update-risk-label-score';
  static readonly updateRiskLabelValidationStatus = 'update-risk-label-validation-status';
  static readonly updateRiskSliderScore = 'update-risk-slider-score';
  static readonly updateRiskSliderValidationStatus = 'update-risk-slider-validation-status';
  static readonly userInfoChanged = 'user-info-changed';
  static readonly userPreferencesChanged = 'user-preferences-changed-event';
  static readonly websiteBreadcrumbsChanged = 'website-breadcrumbs-changed';
}
