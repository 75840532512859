import { Component, Injector } from '@angular/core';

import { ComponentBase } from 'framework';

import { AdvisorFooterViewModel } from './advisor-footer.view-models';

@Component({
  selector: 'app-advisor-footer',
  standalone: true,
  imports: [],
  providers: [AdvisorFooterViewModel],
  templateUrl: './advisor-footer.component.html',
  styleUrls: ['./advisor-footer.component.scss']
})
export class AdvisorFooterComponent extends ComponentBase<AdvisorFooterViewModel> {
  constructor(protected override injector: Injector, viewModel: AdvisorFooterViewModel) {
    super(injector, viewModel);
  }
}
