import { NavbarComponent } from './../../common/website/navbar/navbar.component';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import { MenuItem } from 'primeng/api';

import { SubscriptionService } from 'framework';

import { AdvisorRoutes } from '../../../layouts/advisor/advisor-routes';

import { EventNames } from '../../../constants/event-names';

import {
  FirmDto,
  PlatformCredentialsRequestDto,
  UserDto,
  UserInfoDto,
  UserUpsertDto,
  PlatformCredentialsResponseDto
} from '../../../services/gateway-api-client/dtos/infrastructure.dtos';

import { SolisSessionTeamMember, SolisSessionTeamMemberRole } from '../../../services/session/solis-session-service.models';

import { IxHubRouteData } from '../../../models/website.models';
import { WebHelper } from '../../../helpers/web/web.helper';

import { environment } from '../../../../environments/environment';

import { ModalTextInputComponent } from '../../modal-text-input/modal-text-input.component';
import { Route } from '@angular/router';
import { TermsAndConditionsComponent } from '../../common/terms-and-conditions/terms-and-conditions.component';
import { UserService } from '../../../services/user/user.service';

import { SolisViewModelBase } from '../../solis-view-model.base';
import { SolisDestinationPage } from '../../../services/session/solis-session-service.enums';

@Injectable()
export class AdvisorNavbarViewModel extends SolisViewModelBase {
  currentTheme: string = 'light';
  themeFilePath: string = 'assets/themes/';
  logoUrl: string = '';
  logoFile: string = '';

  userId: string = '';
  userFirstName: string = '';
  isCollapsed: boolean = true;
  isUserManager: boolean = false;
  isAoeReviewer: boolean = false;
  screenWidth: number = 0;
  newScreenWidth: number = 0;
  isImpersonationActive: boolean = false;
  impersonateUsers: UserInfoDto[] = [];
  allowCompare: boolean = false;

  ixHubMenuItems: MenuItem[] = [];
  showIxHubMenu: boolean = true;
  termsVersion: string = '1.0';
  areTermsAccepted: boolean = false;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private userService: UserService
  ) {
    super();
  }

  async initialize() {
    const logoUrl = this.solisSessionService.getSettingValue<string>('Logo', false) ?? '';
    this.logoFile = logoUrl !== '' ? logoUrl : `${this.themeFilePath}${this.currentTheme}/img/IX_LogoOfficial.png`;
    this.showIxHubMenu =
      this.solisSessionService.getSettingValue<SolisDestinationPage>('IX.Firmlandingpage', false) === SolisDestinationPage.IXHub;

    this.initializeNavbarData();
    // await this.initializeTermsConditions();
  }

  initializeNavbarData(): void {
    this.initializeSessionData();
    if (this.showIxHubMenu) {
      this.ixHubMenuItems = [];
      this.initializeIxHubNavbar(AdvisorRoutes.filter((ar) => ar.data?.['ixHub'] !== undefined));
    }
    this.screenWidth = window.innerWidth;
    this.newScreenWidth = window.innerWidth + 1;
    this.applyTheme();
  }

  override initializeSubscriptions(subscriptionService: SubscriptionService) {
    // Execute theme switch if user changes it
    subscriptionService.subscribeEvent(EventNames.userPreferencesChanged, () => this.applyTheme());

    // Reinitialize navbar if on behalf of person changes
    subscriptionService.subscribeEvent(EventNames.impersonationChanged, () => this.initializeNavbarData());
  }

  initializeSessionData(): void {
    this.userId = this.solisSessionService.user!.id!;
    this.userFirstName =
      this.solisSessionService.impersonatorUser?.firstName ||
      this.solisSessionService.user!.firstName ||
      this.solisSessionService.user!.userName!;
    this.isUserManager = this.solisSessionService.userHasCapability('solis_user_manager');
    this.isAoeReviewer = this.solisSessionService.userHasCapability('aoe_reviewer');
    this.isImpersonationActive = this.solisSessionService.isImpersonationActive;
    this.impersonateUsers = this.solisSessionService.user.canImpersonateUsers ?? [];
    // Only show the compare navbar if there are compare items added
    this.allowCompare =
      this.solisSessionService.getSettingValue<boolean>('ProductCompare.Enabled')! &&
      this.solisSessionService.getSettingValue<boolean>('Website.Header.Menu.Compare.Show')!;
  }

  initializeIxHubNavbar(routes: Route[] = []): void {
    routes.forEach((r) => {
      if (r.data?.['ixHub']) {
        const data = r.data!['ixHub'] as IxHubRouteData;
        const capabilities: string[] = data.capabilities ?? [];

        // We allow the menu group if no capabilities are found, or any user capabilities exists for the list
        if (capabilities.length === 0 || this.solisSessionService.userHasAnyCapability(...capabilities)) {
          const item: MenuItem = {
            label: data.label,
            styleClass: 'ix-items',
            items: []
          };
          const catPath = `${r.path}/`;

          // Grab links for the section
          (r.children ?? []).forEach((rc) => {
            const childData = (rc.data?.['ixHub'] ?? {}) as IxHubRouteData;
            const capabilities: string[] = childData.capabilities ?? [];

            // Same capability check applies
            if (capabilities.length === 0 || this.solisSessionService.userHasAnyCapability(...capabilities)) {
              const childItem: MenuItem = {
                label: childData.label,
                command: rc.redirectTo ? () => this.navigationService.navigate([`${catPath}${rc.path!}`]) : undefined
              };
              let simonDestination: SimonRelayState | null = null;

              switch (rc.path) {
                case 'annuity-research':
                  simonDestination = SimonRelayState.Marketplace;
                  break;
                case 'annuity-lifecycle':
                  simonDestination = SimonRelayState.Lifecycle;
                  break;
                case 'annuity-training':
                  simonDestination = SimonRelayState.Training;
                  break;
              }

              if (simonDestination) {
                childItem.command = () => this.launchSimonUrl(simonDestination);
              }

              item.items!.push(childItem);
            }
          });

          this.ixHubMenuItems.push(item);
        }
      }
    });

    this.ixHubMenuItems.push({
      separator: true
    });
    this.ixHubMenuItems.push(this.createProfileMenuItem());
  }

  async launchSimonUrl(destination: SimonRelayState | null): Promise<void> {
    if (!destination) {
      return;
    }

    const activeFirm = this.solisSessionService.getActiveFirm();
    const user = await this.userService.getSessionUserOrPrimaryTeamMember(activeFirm!, true);

    if (user && (user?.npn?.length ?? 0 > 0)) {
      const simonEmbeddedFrameUrl = `${environment.embedWebBaseUrl}/single-sign-on?UserName=${user.npn}&FirstName=${user.firstName}&LastName=${user.lastName}&Email=${user.email}&dtccMemberCode=${activeFirm?.dtccMemberCode}&platformCode=SIMON&destination=${destination}`;
      this.navigationService.navigateNewTab(simonEmbeddedFrameUrl);
    }
  }

  createProfileMenuItem(): MenuItem {
    const menuItem: MenuItem = {
      label: `Hi, ${this.userFirstName}`,
      styleClass: 'ix-items user-group',
      items: []
    };

    if (!this.isImpersonationActive && this.impersonateUsers.length > 0) {
      const items: MenuItem[] = [
        {
          label: 'Work on Behalf of:',
          styleClass: 'impersonate-header'
        }
      ];
      this.impersonateUsers.forEach((user) => {
        items.push({
          label: `${user.firstName} ${user.lastName}`,
          styleClass: 'impersonate-item',
          command: () => this.impersonate(user.id!)
        });
      });
      menuItem.items = items;
      menuItem.items!.push({ separator: true });
    }
    menuItem.items!.push({
      label: 'Profile Settings',
      routerLink: ['/users', this.userId]
    });
    menuItem.items!.push({
      label: 'Log out',
      command: () => this.logout()
    });

    return menuItem;
  }

  collapse($event: { stopPropagation: () => void }): void {
    this.collapseIcon($event);
    if (this.screenWidth < this.newScreenWidth) {
      this.isCollapsed = true;
    }
  }

  collapseIcon($event: { stopPropagation: () => void }): void {
    $event.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
  }

  applyTheme(): void {
    this.currentTheme = this.solisSessionService.userPreferences!.theme!;
  }

  // CHANGE TO ON BEHALF OF USER BASED ON USER ID
  async impersonate(userId: string): Promise<void> {
    const result = await this.modalService.confirm(
      'Working on behalf of another user will navigate you home and you may lose unsaved work. Do you want to continue?'
    );

    if (result) {
      this.executeImpersonationAndNavigateHome(userId);
    }
  }

  async executeImpersonationAndNavigateHome(userId: string): Promise<void> {
    await this.solisSessionService.beginImpersonation(userId);
    const success = await this.navigationService.navigateHome();
    if (!success) {
      // Unsuccessfully navigating home means we are already on the home page for the impersation.
      // Need to reload the page to update any necessary changes due to impersonation
      this.navigationService.reloadPage(this.navigationService.buildHomeRoute());
    }
  }

  logout(): void {
    this.oidcSecurityService.logoff().subscribe((_: any) => {
      // Kill solis session
      this.solisSessionService.end();
    });
  }
}

export enum SimonRelayState {
  Marketplace = 'Marketplace',
  Lifecycle = 'Lifecycle',
  Training = 'Training'
}
