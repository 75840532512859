interface IProposalProfileModel {
  proposalId?: string | null;
  platformProposalId?: string | null;
  proposalDetailId?: string | null;
  proposalName?: string | null;
  proposalCaseId?: string | null;
  proposalCaseAgentUserId?: string | null;
  teamCode?: string | null;
  platformAuthenticationId?: string | null;
}

export class ProposalProfileModel implements IProposalProfileModel {
  proposalId: string | null;
  platformProposalId: string | null;
  proposalDetailId: string | null;
  proposalName: string | null;
  proposalCaseId?: string | null;
  proposalCaseAgentUserId?: string | null;
  teamCode?: string | null;
  platformAuthenticationId?: string | null;

  constructor(options: IProposalProfileModel = {}) {
    this.proposalId = options.proposalId || null;
    this.platformProposalId = options.platformProposalId || null;
    this.proposalDetailId = options.proposalDetailId || null;
    this.proposalName = options.proposalName || null;
    this.proposalCaseId = options.proposalCaseId || null;
    this.proposalCaseAgentUserId = options.proposalCaseAgentUserId || null;
    this.teamCode = options.teamCode || null;
    this.platformAuthenticationId = options.platformAuthenticationId || null;
  }
}
