import { Component, Injector } from '@angular/core';
import { ComponentBase } from 'framework';
import { GlobalViewModel } from './global.component.view-models';

@Component({
  selector: 'app-global',
  standalone: true,
  imports: [],
  providers: [GlobalViewModel],
  template: ''
})
export class GlobalComponent extends ComponentBase<GlobalViewModel> {
  constructor(
    protected override injector: Injector,
    viewModel: GlobalViewModel
  ) {
    super(injector, viewModel);
  }
}
