import { GatewayApiClient } from '../services/gateway-api-client/gateway-api-client.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { SolisSessionService } from '../services/session/solis-session.service';

import { ModalService, ViewModelBase } from 'framework';
import { Injectable } from '@angular/core';
import { SolisLaunchMethod } from '../services/session/solis-session-service.enums';

@Injectable()
export abstract class SolisViewModelBase extends ViewModelBase {
  /* #region Lazy Loaded Services *******************************/

  protected get apiClient(): GatewayApiClient {
    this._apiClient = this._apiClient ?? this.injector.get(GatewayApiClient);
    return this._apiClient;
  }

  protected get navigationService(): NavigationService {
    this._navigationService = this._navigationService ?? this.injector.get(NavigationService);
    return this._navigationService;
  }

  protected get solisSessionService(): SolisSessionService {
    this._solisSessionService = this._solisSessionService ?? this.injector.get(SolisSessionService);
    return this._solisSessionService;
  }

  protected get modalService(): ModalService {
    this._modalService = this._modalService ?? this.injector.get(ModalService);
    return this._modalService;
  }

  get isEmbeddedSession(): boolean {
    return this.solisSessionService?.launchMethod === SolisLaunchMethod.Embed;
  }

  /* #endregion *************************************************/

  private _apiClient: GatewayApiClient | null = null;
  private _navigationService: NavigationService | null = null;
  private _solisSessionService: SolisSessionService | null = null;
  private _modalService: ModalService | null = null;

  override internalValidateState() {
    const isValid = this.validateState();
    if (!isValid) {
      this.navigationService.navigateHome();
    }
  }
}
