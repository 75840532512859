import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { SolisFormViewModelBase } from '../solis-form-view-model.base';

@Injectable()
export class ModalTextInputViewModel extends SolisFormViewModelBase {
  valueSubject: Subject<string | undefined> = new Subject();
  header?: string;
  subHeader?: string;
  maxChars?: number;
  minWidth?: number;
  placeHolder?: string;
  continueButtonText: string = 'Continue';
  mode?: ModalTextInputMode;
  required: boolean = true;
  height?: number = 36;

  remainingChars?: number;

  get minWidthCalc(): number {
    return this.minWidth || 400;
  }

  get isMultiLine(): boolean {
    if (!this.mode) {
      return false;
    }

    return this.mode === ModalTextInputMode.MultiLine;
  }

  constructor() {
    super();
  }

  initialize() {}

  override initializeForm(formBuilder: FormBuilder) {
    return formBuilder.group({
      text: ['']
    });
  }

  override initializeFormData() {
    const requiredValidator = this.required ? [Validators.required] : [];
    this.control('text').addValidators(requiredValidator);
    this.remainingChars = this.maxChars!;

    this.control('text').setValue(this.placeHolder ?? '');

    this.updateRemaining();
  }

  updateRemaining(): void {
    this.remainingChars = this.maxChars! - this.control('text').value.length;
  }

  async submitForm() {
    this.submit(async () => {
      this.valueSubject.next(this.control('text').value);
    });
  }

  cancel(): void {
    this.valueSubject.complete();
  }
}

export enum ModalTextInputMode {
  SingleLine,
  MultiLine
}
