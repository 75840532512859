import { ElementRef, Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';

import { SubscriptionService } from 'framework';

import { EventNames } from '../../constants/event-names';

import { SolisViewModelBase } from '../../components/solis-view-model.base';

@Injectable()
export class PartnerLayoutViewModel extends SolisViewModelBase {
  contentWindow!: ElementRef;
  navbarHidden: boolean = false;
  subHeaderHidden: boolean = false;
  impersonationHidden: boolean = false;
  footerHidden: boolean = false;

  constructor() {
    super();
  }

  initialize() {
    this.configureImpersonation();
  }

  override initializeSubscriptions(subscriptionService: SubscriptionService) {
    subscriptionService.subscribeRouterEvent((event): void => {
      if (!(this.contentWindow && event instanceof NavigationEnd)) {
        return;
      }
      (this.contentWindow.nativeElement as Element).scrollTo({ top: 0, behavior: 'smooth' });
    });

    subscriptionService.subscribeEvent(EventNames.siteLayoutChanged, (data: string[]) => this.configureLayout(data), true);

    subscriptionService.subscribeEvent(EventNames.impersonationChanged, () => this.configureImpersonation());
  }

  private configureImpersonation(): void {
    this.impersonationHidden = !this.solisSessionService.isImpersonationActive;
  }

  private configureLayout(sections: string[]): void {
    this.navbarHidden = !sections.includes('navbar');
    this.subHeaderHidden = !sections.includes('subheader');
    this.footerHidden = !sections.includes('footer');
  }
}
