export const environment = {
  name: '#~{SolisWeb-EnvironmentName}~#',
  production: false,
  authority: '#~{SolisWeb-AuthorityUrl}~#',
  gatewayApiBaseUrl: '#~{SolisWeb-GatewayApiBaseUrl}~#',
  embedWebBaseUrl: '#~{SolisWeb-EmbedWebBaseUrl}~#',
  applicationInsights: {
    connectionString: '#~{SolisWeb-ApplicationInsights--ConnectionString}~#',
    correlationHeaderExcludedDomains: ['#~{SolisWeb-ApplicationInsights--CorrelationHeaderExcludedDomains}~#'],
    // Required for end-to-end traces
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
};
