import { Injectable } from '@angular/core';

import { MenuItem } from 'primeng/api';

import { SimpleModalIcon, SimpleModalPosition, SubscriptionService } from 'framework';

import { PostMessageService } from '../../../services/post-message/post-message.service';
import { ProposalSessionService } from '../../../services/proposal-session/proposal-session.service';

import { ProposalProfileChangedEvent } from '../../../events/proposal-profile.events';

import { EventNames } from '../../../constants/event-names';
import { RequestNames } from '../../../constants/request-names';
import { SessionKeys } from '../../../constants/session-keys';

import { ProposalProfileModel } from '../../../models/proposal-profile.models';
import { BreadcrumbState } from '../../../services/navigation/navigation.models';

import { environment } from '../../../../environments/environment';

import { SolisViewModelBase } from '../../solis-view-model.base';
import { AdvisorToolsItem } from './models/advisor-sub-header.models';
import { SolisDestinationPage } from '../../../services/session/solis-session-service.enums';

@Injectable()
export class AdvisorSubHeaderViewModel extends SolisViewModelBase {
  advisorToolsHidden: boolean = false;
  advisorToolsTitle: string = 'Advisor Tools';
  advisorItems: AdvisorToolsItem[] = [];
  breadcrumbItems: MenuItem[] = [];
  subheaderTitle: string = '';
  previousUrl: string | null = null;
  currentUrl: string | null = null;
  proposalInfoHidden: boolean = false;
  proposalName: string = '';
  modalHeaderText: string = '';
  modalBodyText: string = '';
  annuities: any[] = [];
  lombardText: string = `<p class="p-m-0">I acknowledge that in order to submit a case to Lombard International my client must be an Accredited Investor and may need to be a Qualified Purchaser. </p>
  <p>Documentation will be required before completing the annuity contract.</p>
  <small>Selecting Confirm will open another website in a new tab.</small>`;
  departText: string = `<p>You are leaving the Insurance Exchange.</p><small>Selecting <b>Confirm</b> will open another website in a new tab.</small>`;
  home: MenuItem | undefined;
  isIxHubFlow: boolean = false;

  get isProposalAvailable(): boolean {
    return this.proposalName.length > 0;
  }

  constructor(
    private proposalSessionService: ProposalSessionService,
    private postMessageService: PostMessageService
  ) {
    super();
  }

  initialize() {
    this.getAdvisorToolsItems();
    this.getProposalProfile();
    this.initializeFlow();
  }

  override initializeSubscriptions(subscriptionService: SubscriptionService): void {
    // List subscribed events
    subscriptionService.subscribeEvent<void>(EventNames.siteLayoutChanged, (data: string[]) => this.configureLayout(data), true);

    subscriptionService.subscribeEvent<BreadcrumbState>(
      EventNames.websiteBreadcrumbsChanged,
      (data: BreadcrumbState) => {
        this.breadcrumbItems = [...data.breadcrumbItems];
        this.home = data.home;
      },
      true
    );

    subscriptionService.subscribeEvent<BreadcrumbState>(
      EventNames.pageTitleChanged,
      (data: string) => {
        this.subheaderTitle = data;
      },
      true
    );

    subscriptionService.subscribeEvent<BreadcrumbState>(EventNames.privatePlacementSelection, () => {
      this.popupNavigateTo('lombard');
    });

    subscriptionService.subscribeEvent<ProposalProfileChangedEvent>(
      EventNames.proposalProfileChanged,
      (data: ProposalProfileChangedEvent) => {
        this.processProposalProfile(data.proposalProfile);
      }
    );

    subscriptionService.subscribeEvent<BreadcrumbState>(EventNames.proposalCaseCreated, () => {
      this.proposalSessionService.clearProposalFromSession();
      this.processProposalProfile(new ProposalProfileModel());
    });

    subscriptionService.subscribeEvent<BreadcrumbState>(EventNames.closeAndExit, () => {
      this.closeAndExit();
    });

    subscriptionService.registerRequestHandler<string, void>(RequestNames.setPageTitle, (data) => {
      this.subheaderTitle = data.request ?? '';
    });
  }

  initializeFlow(): void {
    this.isIxHubFlow =
      this.solisSessionService.getSettingValue<SolisDestinationPage>('IX.FirmLandingPage', false) === SolisDestinationPage.IXHub;
  }

  // SET UP ADVISOR TOOLS DROPDOWN
  getAdvisorToolsItems(): void {
    const blackrockIntegrationEnabled = this.solisSessionService.getSettingValue<boolean>('Integration.Blackrock.Enabled');
    const moneyGuideIntegrationEnabled = this.solisSessionService.getSettingValue<boolean>('Integration.MoneyGuide.Enabled');
    const advisorResourceCenterEnabled = this.solisSessionService.getSettingValue<boolean>(
      'Integration.AdvisorResourceCenter.Enabled',
      false
    );
    const lombardIntegrationEnabled = this.solisSessionService.getSettingValue<boolean>('Integration.Lombard.Enabled', false);

    this.advisorItems = [];

    if (blackrockIntegrationEnabled) {
      this.addAdvisorItemBySetting('Integration.Blackrock.AdvisorToolsLogo', '', 'blackrock');
    }

    if (lombardIntegrationEnabled) {
      this.addAdvisorItemBySetting('Integration.Lombard.AdvisorToolsLogo', 'Integration.Lombard.Url');
    }

    if (moneyGuideIntegrationEnabled) {
      this.addAdvisorItemBySetting('Integration.MoneyGuide.AdvisorToolsLogo', 'Integration.MoneyGuide.Url', 'MGP');
    }

    if (advisorResourceCenterEnabled) {
      this.addAdvisorItemBySetting('AdvisorResourceCenterLogo', 'AdvisorResourceCenterUrl');
    }
  }

  // CREATE AND ADD ADVISOR ITEM TO LIST
  addAdvisorItemBySetting(logoSetting: string, urlSetting: string, singleSignOnPlatform: string = ''): void {
    const logo: string = this.solisSessionService.getSettingValue<string>(logoSetting)!;
    let redirectUrl: string = urlSetting !== '' ? this.solisSessionService.getSettingValue<string>(urlSetting)! : '';

    if (singleSignOnPlatform !== '') {
      redirectUrl = this.processSingleSignOnUrl(singleSignOnPlatform);
    }
    this.addAdvisorToolsItem(logo, redirectUrl);
  }

  addAdvisorToolsItem(logo: string, redirectUrl: string): void {
    this.advisorItems.push(new AdvisorToolsItem(logo, redirectUrl));
  }

  // GET PROPOSAL PROFILE FROM SOLIS SESSION AND PROCESS IT
  getProposalProfile() {
    const proposalProfile = this.solisSessionService.localSession.get<ProposalProfileModel>(SessionKeys.proposalProfile);
    if (proposalProfile) {
      this.processProposalProfile(proposalProfile);
    }
  }

  // PROCESS VIEWMODEL WITH PROPOSAL PROFILE DATA
  processProposalProfile(proposalProfile: ProposalProfileModel) {
    this.proposalName = proposalProfile.proposalName ?? '';
  }

  // CLEAR ALL PROPOSAL DATA
  clearCurrentProposal() {
    this.proposalSessionService.clearProposalFromSession();
    this.processProposalProfile(new ProposalProfileModel());
    if (this.isIxHubFlow) {
      this.navigationService.navigateHome();
    } else {
      this.navigationService.navigate(['products']);
    }

    this.sendEvent(EventNames.proposalCleared);
  }

  // CREATE POP UP FOR NAVIGATE OUT
  async popupNavigateTo(navigateUrl: string): Promise<void> {
    const messageHtml = navigateUrl.includes('lombard') ? this.lombardText : this.departText;

    const navigate = await this.modalService.showSimpleModal<boolean>({
      class: 'p-dialog-popup-width p-dialog-width',
      position: SimpleModalPosition.Top,
      title: this.modalHeaderText,
      icon: SimpleModalIcon.Warning,
      messageHtml,
      buttons: [
        {
          label: 'Confirm',
          class: 'btn btn-primary btn-caution',
          value: true
        },
        {
          label: 'Cancel',
          class: 'btn btn-secondary',
          value: false
        }
      ]
    });

    if (navigate) {
      setTimeout(() => {
        this.navigationService.navigateNewTab(navigateUrl);
      }, 100);
    } else {
      this.sendEvent(EventNames.privatePlacementSelectionModalAction);
    }
  }

  closeAndExit() {
    this.solisSessionService.end();
    this.postMessageService.sendCloseMessage();
  }

  private configureLayout(sections: string[]) {
    this.advisorToolsHidden = !sections.includes('advisor-tools');
    this.proposalInfoHidden = !sections.includes('proposal-info');
  }

  private processSingleSignOnUrl(platformCode: string) {
    const firm = this.solisSessionService.getActiveFirm()!;
    const user = this.solisSessionService.user!;
    return `${environment.embedWebBaseUrl}/single-sign-on?platformCode=${platformCode}&userName=${user.userName ?? ''}&firstName=${user.firstName ?? ''}&lastName=${user.lastName ?? ''}&email=${user.email ?? ''}&dtccMemberCode=${firm.dtccMemberCode!}`;
  }
}
