import { Injectable } from '@angular/core';
import { ModalService, ToastService } from 'framework';
import { GatewayApiClient } from '../gateway-api-client/gateway-api-client.service';
import { SolisSessionService } from '../session/solis-session.service';
import {
  FirmDto,
  PlatformCredentialsRequestDto,
  PlatformCredentialsResponseDto,
  UserDto,
  UserUpsertDto
} from '../gateway-api-client/dtos/infrastructure.dtos';
import { SolisSessionTeamMember, SolisSessionTeamMemberRole } from '../session/solis-session-service.models';
import { ModalTextInputComponent } from '../../components/modal-text-input/modal-text-input.component';
import { WebHelper } from '../../helpers/web/web.helper';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private solisSessionService: SolisSessionService,
    private apiClient: GatewayApiClient,
    private modalService: ModalService,
    private toastService: ToastService
  ) {}

  async getSessionUserOrPrimaryTeamMember(firm: FirmDto, productTraining: boolean = false): Promise<SolisSessionTeamMember | null> {
    // When going to product Training we need to use the session user.
    // Otherwise if the primary advisor is different than the session user we use them.
    const sessionUser = this.solisSessionService.user!;
    const primary = this.solisSessionService.team.filter((x) => x.role == SolisSessionTeamMemberRole.Primary)[0]!;
    const useSessionUser = sessionUser.ssn === primary.ssn || productTraining;
    const user = useSessionUser ? sessionUser : primary;
    let agentName = user.firstName ? user.firstName + ' ' + user.lastName : user.lastName;

    // check if the user has an NPN value. This is the only required field for validation with Simon
    if (!user.npn || user.npn === undefined || user.npn.length === 0) {
      // When using the logged in user and not the primary advisor of the team we can update NPN if required.
      if (useSessionUser) {
        const newNpn = await this.modalService.show<string>(ModalTextInputComponent, {
          data: {
            header: 'NPN Required',
            maxChars: 10,
            minWidth: 50,
            subHeader: 'Please enter your NPN number to continue.'
          }
        });

        // If NPN was provided
        if (newNpn) {
          // Validate provided NPN without changing npn of user in session
          let tempUser = Object.assign({}, sessionUser);
          tempUser.npn = newNpn;
          let userValidation = await this.isValidUser(tempUser, firm.dtccMemberCode!);

          if (!userValidation.validationResult) {
            await this.modalService.message(
              WebHelper.formatErrorMessage(`iCapital Product Training authentication failed for ${agentName}`),
              'Account Validation Failed'
            );
            return null;
          }

          // save the validated NPN to the session user
          const upsertUser = JSON.parse(JSON.stringify(sessionUser)) as UserUpsertDto;
          upsertUser.firmDtccMemberCode = firm.dtccMemberCode;
          upsertUser.firmType = firm.type;
          upsertUser.npn = newNpn;
          this.toastService.progress('Saving NPN...');
          let updatedUser = await this.upsertUser(sessionUser.id!, upsertUser);

          // "user" will be null if it failed to save
          if (updatedUser) {
            this.solisSessionService.user = updatedUser;
            this.toastService.clear();
            this.toastService.success('User saved');
          } else {
            this.toastService.clear();
            this.toastService.error(WebHelper.formatErrorMessage('Unknown error saving user'));
          }
          return updatedUser;
        }
      } else {
        // This is for marketplace so since we are using the primary team member we can't update the NPN only fail
        await this.modalService.message(
          WebHelper.formatErrorMessage(`Authentication failed for ${agentName}`),
          'Account Information Missing'
        );
        return null;
      }
    }

    //Validate the user
    this.toastService.progress('Validating User');
    let userValidation = await this.isValidUser(user, firm.dtccMemberCode!);
    this.toastService.clear();
    if (!userValidation.validationResult) {
      await this.modalService.message(WebHelper.formatErrorMessage(`Authentication failed for ${agentName}`), 'Account Validation Failed');
      return null;
    }
    return user;
  }

  private async isValidUser(user: SolisSessionTeamMember, firmDtccMemberCode: string): Promise<PlatformCredentialsResponseDto> {
    let request = new PlatformCredentialsRequestDto();
    request.firstName = user.firstName;
    request.lastName = user.lastName;
    request.emailAddress = user.email;
    request.userId = user.npn;
    request.firmId = firmDtccMemberCode;

    let failureResponse = new PlatformCredentialsResponseDto();
    failureResponse.validationResult = false;
    failureResponse.message = WebHelper.formatErrorMessage(
      "Platform Credentials Validation service didn't provide a response or could not be reached"
    );
    const response = await this.apiClient.validatePlatformCredentials('SIMON', request);
    return response.result ?? failureResponse;
  }

  private async upsertUser(userId: string, user: UserUpsertDto): Promise<UserDto | null> {
    const updateResponse = await this.apiClient.updateUser(userId, user);
    return updateResponse.result;
  }
}
