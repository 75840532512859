import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

import { GatewayApiClient } from '../gateway-api-client/gateway-api-client.service';
import { LaunchPayloadDto } from '../gateway-api-client/dtos/client-application.dtos';
import {
  FirmDto as ProposalFirmDto,
  ProposalDetailDto,
  ProposalDetailPartyPersonDto,
  ProposalDetailUpsertDto,
  ProposalDto,
  ProposalUpsertDto
} from '../gateway-api-client/dtos/proposal.dtos';
import {
  ProposalCaseStatusType,
  ProposalDetailStatusType,
  ProposalPartyRoleType,
  ProposalStatusType
} from '../gateway-api-client/dtos/proposal.enums';

import {
  AnnuityDetailModel,
  AnnuityDetailOverlayModel,
  AnnuityDetailRiderModel,
  AnnuityDetailSubaccountModel
} from '../../models/annuity-detail.models';
import { ClientProfileModel } from '../../models/client-profile.models';
import { AnnuityProfileModel } from '../../models/annuity-profile.models';
import { ProposalTeamModel } from '../../models/proposal-team.models';
import { ProposalProfileModel } from '../../models/proposal-profile.models';
import { ProposalPackageModel } from './proposal.model';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {
  constructor(private apiClient: GatewayApiClient) {}

  getProposalCaseStatus(statusCode?: ProposalCaseStatusType): string {
    switch (statusCode) {
      case ProposalCaseStatusType.AccountOpeningReady:
        return 'Account Opening Ready';
      case ProposalCaseStatusType.AppDataEntry:
        return 'App Data Entry';
      case ProposalCaseStatusType.AppInSignatures:
        return 'App In Signatures';
      case ProposalCaseStatusType.AppSentToReview:
        return 'App Sent To Review';
      case ProposalCaseStatusType.AppPendingReview:
        return 'App Pending Review';
      case ProposalCaseStatusType.AppSubmittedToCarrier:
        return 'App Submitted To Carrier';
      case ProposalCaseStatusType.FinalIllustrationRejected:
        return 'Final Illustration Rejected';
      case ProposalCaseStatusType.IllustrationInProgress:
        return 'Illustration In Progress';
      case ProposalCaseStatusType.IllustrationSubmittedToAdvisor:
        return 'Submitted to Advisor';
      case ProposalCaseStatusType.IllustrationMarkedFinal:
        return 'Illustration Marked Final';
      case ProposalCaseStatusType.IllustrationRequestCancelled:
        return 'Illustration Request Cancelled';
      default:
        return (statusCode || '').toString();
    }
  }

  createAnnuityProfileFromProposalDetail(proposalDetail: ProposalDetailDto): AnnuityProfileModel {
    const annuitant = this.processProposalDetailPerson(
      proposalDetail.persons?.find((party) => party.proposalPartyRoleType === ProposalPartyRoleType.Annuitant)
    );
    const jointAnnuitant = this.processProposalDetailPerson(
      proposalDetail.persons?.find((party) => party.proposalPartyRoleType === ProposalPartyRoleType.JointAnnuitant)
    );
    const jointOwner = this.processProposalDetailPerson(
      proposalDetail.persons?.find((party) => party.proposalPartyRoleType === ProposalPartyRoleType.JointOwner)
    );
    const beneficiary = this.processProposalDetailPerson(
      proposalDetail.persons?.find((party) => party.proposalPartyRoleType === ProposalPartyRoleType.Beneficiary)
    );

    const hasJointAnnuitant = jointAnnuitant !== undefined || proposalDetail.hasJointAnnuitant;
    const isAnnuitantOwner = annuitant ? annuitant.isOwner : proposalDetail.isAnnuitantOwner;
    let compensationType: string[] = [];
    if (proposalDetail.compensationTypeId) {
      compensationType.push(proposalDetail.compensationTypeId.toString());
    }

    // Initialize dates of birth
    const annuitantDateOfBirth = annuitant ? annuitant.dateOfBirth : proposalDetail.annuitantDateOfBirth;
    const jointAnnuitantDateOfBirth = jointAnnuitant ? jointAnnuitant.dateOfBirth : proposalDetail.jointAnnuitantDateOfBirth;
    const jointOwnerDateOfBirth = jointOwner ? jointOwner.dateOfBirth : proposalDetail.jointOwnerDateOfBirth;

    // Initialize relationships
    const annuitantRelationshipToOwner = annuitant ? annuitant.relationshipToOwner : proposalDetail.annuitantRelationshipToOwner;
    const jointAnnuitantRelationshipToAnnuitant = jointAnnuitant
      ? jointAnnuitant?.relationshipToAnnuitant
      : proposalDetail.jointAnnuitantRelationshipToAnnuitant;
    const jointAnnuitantRelationshipToOwner = jointAnnuitant
      ? jointAnnuitant.relationshipToOwner
      : proposalDetail.jointAnnuitantRelationshipToOwner;
    const jointOwnerRelationshipToOwner = jointOwner ? jointOwner.relationshipToOwner : proposalDetail.jointOwnerRelationshipToOwner;

    // Owner can be Person or Organization. Initialize variables accordingly.
    const ownerPerson = this.processProposalDetailPerson(
      proposalDetail.persons?.find((party) => party.proposalPartyRoleType === ProposalPartyRoleType.Owner)
    );
    const ownerOrganization = proposalDetail.organizations?.find((party) => party.proposalPartyRoleType === ProposalPartyRoleType.Owner);
    const ownerDateOfBirth =
      ownerPerson && annuitant?.platformUniqueId !== ownerPerson?.platformUniqueId
        ? ownerPerson?.dateOfBirth
        : proposalDetail.ownerDateOfBirth;
    const primaryOwner = ownerPerson ? ownerPerson.proposalPartyType : ownerOrganization?.proposalPartyType;

    const annuityProfile: AnnuityProfileModel = new AnnuityProfileModel({
      premium: proposalDetail.premium,
      primaryOwner: primaryOwner ? primaryOwner : proposalDetail.primaryOwner,
      ownershipType: proposalDetail.ownershipType,
      jurisdiction: proposalDetail.contractState,
      solicitationState: proposalDetail.solicitationState,
      brokerageAccountNumber: proposalDetail.brokerageAccountNumber,
      isAnnuitantOwner: isAnnuitantOwner,
      annuitantDateOfBirth: annuitantDateOfBirth,
      annuitantRelationshipToOwner: annuitantRelationshipToOwner,
      ownerDateOfBirth: ownerDateOfBirth,
      jointOwnerDateOfBirth: jointOwnerDateOfBirth,
      jointOwnerRelationshipToOwner: jointOwnerRelationshipToOwner,
      hasJointAnnuitant: hasJointAnnuitant,
      jointAnnuitantDateOfBirth: jointAnnuitantDateOfBirth,
      jointAnnuitantRelationshipToOwner: jointAnnuitantRelationshipToOwner,
      jointAnnuitantRelationshipToAnnuitant: jointAnnuitantRelationshipToAnnuitant,
      primaryBeneficiaryDateOfBirth: beneficiary?.dateOfBirth,
      primaryBeneficiaryRelationshipToOwner: beneficiary?.relationshipToOwner,
      primaryBeneficiaryRelationshipToAnnuitant: beneficiary?.relationshipToAnnuitant,
      taxQualificationType:
        proposalDetail.taxQualificationTypeId ?? null !== null
          ? `${proposalDetail.taxQualificationTypeId},${proposalDetail.taxQualificationSubTypeId}`
          : null,
      compensationType: compensationType
    });

    return annuityProfile;
  }

  createAnnuityDetailFromProposalDetail(proposalDetail: ProposalDetailDto): AnnuityDetailModel | undefined {
    if ((proposalDetail.product?.cusip ?? '') !== '' && (proposalDetail.product?.productCode ?? '') !== '') {
      const annuityDetail = new AnnuityDetailModel({
        cusip: proposalDetail.product!.cusip,
        productCode: proposalDetail.product!.productCode,
        dtccMemberCode: proposalDetail.product!.dtccMemberCode,
        morningstarCompanyId: proposalDetail.morningstarCompanyId,
        morningstarPolicyId: proposalDetail.product!.morningstarPolicyId,
        riders: proposalDetail.riders?.map((rider) => new AnnuityDetailRiderModel(rider)),
        // TODO: Overlays
        overlays: !!proposalDetail.product?.productOverlayCode
          ? [new AnnuityDetailOverlayModel({ riderSubaccountGroupKey: proposalDetail.product.productOverlayCode })]
          : [],
        subaccounts: proposalDetail.subaccounts?.map((sa) => new AnnuityDetailSubaccountModel(sa)),
        usesAdvancedIllustration: proposalDetail.product?.usesAdvancedIllustration,
        risks: proposalDetail.risks
      });
      return annuityDetail;
    }
    return undefined;
  }

  createClientProfileFromProposalDetail(proposalDetail: ProposalDetailDto, caseInstructions = ''): ClientProfileModel {
    const persons = proposalDetail.persons?.map((p) => this.processProposalDetailPerson(p)!);
    const organizations = proposalDetail.organizations?.map((org) => {
      org.trustFormationDate = org.trustFormationDate;
      org.isOwner = org.proposalPartyRoleType === ProposalPartyRoleType.Owner ? true : false;
      return org;
    });
    const annuitant = persons?.find((party) => party.proposalPartyRoleType === ProposalPartyRoleType.Annuitant)!;
    const jointAnnuitant = persons?.find((party) => party.proposalPartyRoleType === ProposalPartyRoleType.JointAnnuitant)!;
    const beneficiary = persons?.find((party) => party.proposalPartyRoleType === ProposalPartyRoleType.Beneficiary)!;

    const clientProfile = new ClientProfileModel({
      persons,
      organizations,
      ownershipType: proposalDetail.ownershipType,
      isOwnerAnnuitant: annuitant?.isOwner,
      doesJointAnnuitantExist: jointAnnuitant !== undefined,
      doesBeneficiaryExist: beneficiary !== undefined,
      caseInstructions
    });
    return clientProfile;
  }

  createProposalTeamFromProposalDetail(proposalDetail: ProposalDetailDto): ProposalTeamModel {
    const proposalTeam = new ProposalTeamModel({
      team: proposalDetail.persons
        ?.filter(
          (person) =>
            person.proposalPartyRoleType === ProposalPartyRoleType.PrimaryAdvisor ||
            person.proposalPartyRoleType === ProposalPartyRoleType.TeamMember
        )
        .map((p) => ({
          firstName: p.firstName,
          lastName: p.lastName,
          ssn: p.ssn,
          email: p.email,
          initialAllocation: p.initialAllocation,
          finalAllocation: p.finalAllocation,
          residentStateCode: p.residentStateCode,
          includeInProposal: p.includeInProposal,
          platformUniqueId: p.platformUniqueId,
          proposalPartyType: p.proposalPartyType,
          proposalPartyRoleType: p.proposalPartyRoleType,
          discretionSelection: p.discretionSelection ?? null,
          addresses: []
        }))
    });
    return proposalTeam;
  }

  async upsertProposalPackage(
    proposalUpsert: ProposalUpsertDto,
    proposalDetailUpsert: ProposalDetailUpsertDto
  ): Promise<ProposalPackageModel> {
    let proposalDetail: ProposalDetailDto | null = null;

    const proposal = await this.upsertProposal(proposalUpsert!);

    if (proposal) {
      proposalDetail = await this.upsertProposalDetail(proposalUpsert.platformProposalId!, proposalDetailUpsert);
    }

    return {
      proposal: proposal ?? undefined,
      proposalDetail: proposalDetail ?? undefined
    };
  }

  async upsertProposalPackageFromModels(
    proposalProfile: ProposalProfileModel,
    annuityProfile: AnnuityProfileModel,
    annuityDetails?: AnnuityDetailModel,
    clientProfile?: ClientProfileModel,
    activeFirm?: ProposalFirmDto,
    activePlatformCode?: string,
    launchPayload?: LaunchPayloadDto,
    isOffPlatform: boolean = false
  ): Promise<ProposalDetailDto | null> {
    let proposalDetail: ProposalDetailDto | null = null;

    const proposalCreateDto = this.createProposalUpsertDto(
      proposalProfile,
      clientProfile,
      activeFirm,
      activePlatformCode,
      launchPayload,
      isOffPlatform
    );

    const proposal = await this.upsertProposal(proposalCreateDto);

    if (proposal) {
      // Proposal successfully created or grabbed. we update solis session models and then create the new detail
      proposalDetail = await this.upsertProposalDetailFromModels(
        proposal.platformProposalId!,
        annuityProfile,
        proposalProfile.proposalDetailId ?? undefined,
        annuityDetails,
        clientProfile,
        launchPayload,
        isOffPlatform,
        activePlatformCode
      );
    }

    return proposalDetail;
  }

  async getProposal(platformProposalId: string): Promise<ProposalDto | null> {
    const response = await this.apiClient.getProposal(platformProposalId);

    if (response.isSuccessStatusCode) {
      return response.result;
    }

    return null;
  }

  private createProposalUpsertDto(
    proposalProfile: ProposalProfileModel,
    clientProfile?: ClientProfileModel,
    activeFirm?: ProposalFirmDto,
    activePlatformCode?: string,
    launchPayload?: LaunchPayloadDto,
    isOffPlatform: boolean = false
  ): ProposalUpsertDto {
    const dateString = formatDate(Date.now(), 'MMddYYYY', 'en-us');

    let proposalCreateName: string = proposalProfile.proposalName ?? '';

    // Only create the new name if it doesn't exist from launch payload
    if (proposalCreateName === '') {
      if (isOffPlatform) {
        proposalCreateName = `Off Platform ${dateString}`;
      } else {
        // All other proposal creations require an annuitant from client profile.
        const annuitant = clientProfile?.persons.find((p) => p.proposalPartyRoleType === ProposalPartyRoleType.Annuitant)!;
        proposalCreateName = `${annuitant.firstName} ${annuitant.lastName} ${dateString}`;
      }
    }

    // Create the DTO model and submit to the server
    const proposalUpsertDto: ProposalUpsertDto = {
      id: proposalProfile.proposalId!,
      dtccMemberCode: activeFirm?.dtccMemberCode,
      firmType: activeFirm?.type,
      platformCode: activePlatformCode,
      proposalStatusType: ProposalStatusType.ProductSelection,
      name: proposalCreateName,
      teamCode: launchPayload?.proposal?.teamCode,
      platformProposalId: proposalProfile.platformProposalId!,
      platformAuthenticationInfo: launchPayload?.platform?.platformAuthenticationId
    };

    return proposalUpsertDto;
  }

  private async upsertProposalDetailFromModels(
    platformProposalId: string,
    annuityProfile: AnnuityProfileModel,
    proposalDetailId?: string,
    annuityDetails?: AnnuityDetailModel,
    clientProfile?: ClientProfileModel,
    launchPayload?: LaunchPayloadDto,
    isOffPlatform: boolean = false,
    activePlatformCode?: string
  ): Promise<ProposalDetailDto | null> {
    const proposalDetailUpsertDto = this.upsertProposalDetailCreateDto(
      annuityProfile,
      proposalDetailId,
      annuityDetails,
      clientProfile,
      launchPayload,
      isOffPlatform,
      activePlatformCode
    );

    const proposalDetail = await this.upsertProposalDetail(platformProposalId, proposalDetailUpsertDto);

    return proposalDetail;
  }

  private upsertProposalDetailCreateDto(
    annuityProfile: AnnuityProfileModel,
    proposalDetailId?: string,
    annuityDetails?: AnnuityDetailModel,
    clientProfile?: ClientProfileModel,
    payload?: LaunchPayloadDto,
    isOffPlatform: boolean = false,
    activePlatformCode?: string
  ): ProposalDetailUpsertDto {
    // Prepare qualified plan type data if necessary
    let taxQualification: boolean | undefined;
    let qualifiedPlanTypeId: number | null = null;
    let qualifiedPlanSubTypeId: number | null = null;

    const taxQualificationValuesArray = annuityProfile.taxQualificationType?.split(',');
    if (taxQualificationValuesArray && taxQualificationValuesArray.length === 2) {
      qualifiedPlanTypeId = +taxQualificationValuesArray[0];
      qualifiedPlanSubTypeId = +taxQualificationValuesArray[1];
      taxQualification = qualifiedPlanTypeId === 1 ? false : true;
    } else {
      taxQualification = annuityProfile.taxQualification!;
    }

    const proposalDetailUpsertDto: ProposalDetailUpsertDto = {
      id: proposalDetailId,
      sourcePlatformCode: activePlatformCode,
      createdByPlatformCode: activePlatformCode,
      platformCustomerId: payload?.platform?.platformCustomerId,
      platformBranchId: payload?.platform?.platformBranchId,
      ownershipType: annuityProfile.ownershipType!,
      morningstarCompanyId: annuityDetails?.morningstarCompanyId,
      morningstarPolicyId: annuityDetails?.morningstarPolicyId,
      contractState: annuityProfile.jurisdiction!,
      solicitationState: annuityProfile.jurisdiction!, // this has logic to check for sessionType.Partner
      premium: annuityProfile.premium!,
      exchangeAmount: payload?.proposal?.exchangeAmount,
      rateOfReturn: payload?.proposal?.rateOfReturn,
      federalTaxRate: payload?.proposal?.federalTaxRate,
      exchangeIssueDate: payload?.proposal?.exchangeIssueDate,
      taxQualification: taxQualification,
      taxQualificationTypeId: qualifiedPlanTypeId,
      taxQualificationSubTypeId: qualifiedPlanSubTypeId,
      brokerageAccountNumber: annuityProfile.brokerageAccountNumber ?? payload?.proposal?.brokerageAccountNumber,
      aoeExtensions: payload?.proposal?.aoeExtensions,
      persons: clientProfile?.persons,
      organizations: clientProfile?.organizations,
      risks: annuityDetails?.risks
    };

    // Add riders to detail if any.
    if (annuityDetails?.riders) {
      proposalDetailUpsertDto.riders = annuityDetails!.riders.map((r) => ({ riderKey: r.riderKey }));
    }

    // Add subaccounts to detail if any.
    if (annuityDetails?.subaccounts) {
      proposalDetailUpsertDto.subaccounts = annuityDetails!.subaccounts.map((s) => ({
        subaccountKey: s.subaccountKey,
        subaccountSelectionType: s.subaccountSelectionType,
        allocationPct: s.allocationPct
      }));
    }

    // Add product to detail if cusip and productcode are passed.
    if (annuityDetails?.cusip && annuityDetails?.productCode) {
      proposalDetailUpsertDto.product = {
        cusip: annuityDetails!.cusip,
        productCode: annuityDetails!.productCode,
        platformProductId: payload?.platform?.platformProductId,
        isOffPlatform,
        productOverlayCode: annuityDetails!.overlays[0]?.riderSubaccountGroupKey
      };
    }

    if (payload?.proposal?.solicitationState) {
      proposalDetailUpsertDto.solicitationState = payload?.proposal?.solicitationState;
    }

    return proposalDetailUpsertDto;
  }

  private async upsertProposal(proposalUpsert: ProposalUpsertDto): Promise<ProposalDto | null> {
    const response = await this.apiClient.upsertProposal(proposalUpsert);

    if (response.isSuccessStatusCode) {
      return response.result;
    }

    return null;
  }

  private async upsertProposalDetail(
    platformProposalId: string,
    proposalDetailUpsert: ProposalDetailUpsertDto
  ): Promise<ProposalDetailDto | null> {
    const response = await this.apiClient.upsertProposalDetail(platformProposalId, proposalDetailUpsert);

    if (response.isSuccessStatusCode) {
      return response.result;
    }

    return null;
  }

  validateProposalDetail(proposalDetail: ProposalDetailDto): boolean {
    let isValid = true;
    let isIXHubEarlyProposal = proposalDetail.proposalDetailStatus === ProposalDetailStatusType.Marketplace;
    isValid = isValid && (isIXHubEarlyProposal || (proposalDetail.persons?.length ?? 0) > 0);
    if (!proposalDetail.product?.isOffPlatform) {
      isValid =
        isValid &&
        (isIXHubEarlyProposal ||
          proposalDetail.persons?.find((p) => p.proposalPartyRoleType === ProposalPartyRoleType.Annuitant) !== undefined);
    }
    return isValid;
  }

  // PROCESS DETAIL PERSON DATA TO MAKE WORK IN APP
  private processProposalDetailPerson(person?: ProposalDetailPartyPersonDto): ProposalDetailPartyPersonDto | undefined {
    if (person) {
      person.dateOfBirth = person.dateOfBirth;
      person.isOwner = person.isOwner ?? false;
    }
    return person;
  }
}
