export enum FirmType {
  DtccDistributor = 'DtccDistributor',
  Oid = 'Oid',
  Imo = 'Imo'
}

export enum AddressType {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

export enum SettingEntityType {
  Default = 'Default',
  Platform = 'Platform',
  Jurisdiction = 'Jurisdiction',
  CarrierGroup = 'CarrierGroup',
  Carrier = 'Carrier',
  Imo = 'Imo',
  Oid = 'Oid',
  DtccDistributor = 'DtccDistributor',
  DtccProductType = 'DtccProductType',
  Cusip = 'Cusip',
  ProductCode = 'ProductCode',
  Rider = 'Rider',
  Subaccount = 'Subaccount'
}

export enum SettingValueType {
  Boolean = 'Boolean',
  String = 'String',
  Integer = 'Integer',
  Decimal = 'Decimal',
  Json = 'Json',
  CdnFile = 'CdnFile',
  File = 'File'
}
