import { HttpClient, HttpContext, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmptyError, lastValueFrom } from 'rxjs';

import { GatewayApiResponse, GatewayApiResultResponse } from './gateway-api-response';
import { PagedResult } from './paging/paged-result';

import { LaunchDto, SolisSessionDto, SolisTermsAcceptedDto, SolisUserPreferencesDto } from './dtos/client-application.dtos';

import {
  BenefitTypeDto,
  BenefitTypesSearchCriteriaDto,
  CarrierDto,
  CarrierSearchCriteriaDto,
  CaseSummaryDto,
  ContractDefinitionDto,
  ContractDefinitionSearchCriteriaDto,
  ContractDefinitionSummaryDto,
  ContractDefinitionSummarySearchCriteriaDto,
  ContractRecommendationDto,
  ContractRecommendationSummaryDto,
  ContractTransactionDto,
  CreditingStrategyDto,
  CreditingStrategySearchCriteriaDto,
  FilterDto,
  FilterSearchCriteriaDto,
  GetProductDetailDto,
  OffPlatformProductDto,
  ProductComparePdfAttachmentDto,
  ComparisonPdfCreateDto,
  ProductDetailDto,
  ProductFeeDto,
  ProductSummaryDto,
  ProductSummarySearchCriteriaDto,
  ProposalSummaryDto,
  RiderDto,
  RiderSearchCriteriaDto,
  RiskCalculationGetDto,
  RiskCalculationResultBase,
  SubaccountDto,
  SubaccountSearchCriteriaDto
} from './dtos/datamart.dtos';
import { ProductSortType } from './dtos/datamart.enums';

import {
  PartialWithdrawalQuoteCreateDto,
  PartialWithdrawalQuoteDto,
  PositionsRequestCreateDto,
  TransactionCancelDto,
  TransactionCancelResponseDto,
  TransactionConfigurationDto,
  TransactionCreateDto,
  TransactionCreateResponseDto,
  TransactionDto,
  TransactionsSearchCriteriaDto,
  TransactionStatusDto,
  TransactionStatusUpdateDto,
  FinancialTransactionSummaryDto,
  ValueInquiryDto,
  WithdrawalQuoteCreateDto,
  WithdrawalQuoteDto
} from './dtos/financial-transactions.dtos';

import {
  FirmDto,
  FormDefinitionDto,
  FormSubmissionCreateDto,
  FormSubmissionDto,
  JurisdictionDto,
  ManageUserPersonasDto,
  SettingDto,
  SettingSearchCriteriaDto,
  TeamDto,
  UserDto,
  UserImpersonationsDto,
  UserInfoDto,
  UserSearchCriteriaDto,
  UserUpsertDto,
  PlatformCredentialsRequestDto,
  PlatformCredentialsResponseDto,
  PersonaDto
} from './dtos/infrastructure.dtos';
import { FirmType } from './dtos/infrastructure.enums';

import { LicenseCheckUpsertDto, LicenseCheckResultDto } from './dtos/licensing.dtos';
import { LicenseCheckWorkflowStepType } from './dtos/licensing.enums';

import { PlacementDto, PlacementCreateDto, PlacementProductDto } from './dtos/private-placement.dtos';
import { PlacementDestination } from './dtos/private-placement.enums';

import { ProductDocumentContentDto, ProductDocumentDto, ProductDocumentContentSearchCriteriaDto } from './dtos/product.dtos';

import {
  ApplicationStatusDto,
  ProposalCaseAssignDto,
  ProposalCaseAttachmentCreateDto,
  ProposalCaseAttachmentDto,
  ProposalCaseCreateDto,
  ProposalCaseDto,
  SearchFiltersDto,
  ProposalCaseFiltersCriteriaDto,
  ProposalCaseFirmSummaryDto,
  ProposalCaseNoteCreateUpdateDto,
  ProposalCaseNoteDto,
  ProposalCaseSearchCriteriaDto,
  ProposalCaseStatusUpdateDto,
  ProposalDetailDocumentIllustrationCreateDto,
  ProposalDetailDocumentIllustrationDto,
  ProposalDetailDocumentIllustrationStatusDto,
  ProposalDetailDto,
  ProposalDetailPackageDto,
  ProposalDetailUpsertDto,
  ProposalDto,
  ProposalPackageExistsDto,
  ProposalSearchCriteriaDto,
  ProposalUpsertDto
} from './dtos/proposal.dtos';

import { SUPPRESS_ERROR_CODES, REDIRECT_ON_404 } from '../interceptors/http-error.interceptor';
import { API_CALL_NAME } from '../interceptors/api-activity.interceptor';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GatewayApiClient {
  constructor(private httpClient: HttpClient) {}

  /* #region Client Application  */
  async addProductFavorite(cusip: string, productCode: string): Promise<GatewayApiResponse> {
    try {
      const resultObservable = this.httpClient.post(
        `${environment.gatewayApiBaseUrl}/v1/client-application/client-states/solis/favorites/products/${cusip}-${productCode}`,
        null,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.addProductFavorite.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResponse.fromHttpResponse(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResponse.fromHttpErrorResponse(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResponse.cancelled();
      }

      throw ex;
    }
  }

  async addSubaccountFavorite(morningstarPolicyId: string, morningstarInvestmentVehicleId: string): Promise<GatewayApiResponse> {
    try {
      const resultObservable = this.httpClient.post(
        `${environment.gatewayApiBaseUrl}/v1/client-application/client-states/solis/favorites/subaccounts/${morningstarPolicyId}-${morningstarInvestmentVehicleId}`,
        null,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.addSubaccountFavorite.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResponse.fromHttpResponse(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResponse.fromHttpErrorResponse(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResponse.cancelled();
      }

      throw ex;
    }
  }

  async getLaunch(launchId: string): Promise<GatewayApiResultResponse<LaunchDto>> {
    try {
      const resultObservable = this.httpClient.get<LaunchDto>(
        `${environment.gatewayApiBaseUrl}/v1/client-application/launches/${launchId}`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getLaunch.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<LaunchDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<LaunchDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getSession(sessionId: string, key: string | null = null): Promise<GatewayApiResultResponse<SolisSessionDto>> {
    try {
      let params = new HttpParams();
      if (key) {
        params = params.set('key', key);
      }

      const resultObservable = this.httpClient.get<SolisSessionDto>(
        `${environment.gatewayApiBaseUrl}/v1/client-application/client-states/solis/sessions/${sessionId}?${params.toString()}`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getSession.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<SolisSessionDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<SolisSessionDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getUserPreferences(): Promise<GatewayApiResultResponse<SolisUserPreferencesDto>> {
    try {
      const resultObservable = this.httpClient.get<SolisUserPreferencesDto>(
        `${environment.gatewayApiBaseUrl}/v1/client-application/client-states/solis/user-preferences`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getUserPreferences.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<SolisUserPreferencesDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<SolisUserPreferencesDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async removeProductFavorite(cusip: string, productCode: string): Promise<GatewayApiResponse> {
    try {
      const resultObservable = this.httpClient.delete(
        `${environment.gatewayApiBaseUrl}/v1/client-application/client-states/solis/favorites/products/${cusip}-${productCode}`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.removeProductFavorite.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResponse.fromHttpResponse(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResponse.fromHttpErrorResponse(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResponse.cancelled();
      }

      throw ex;
    }
  }

  async removeSubaccountFavorite(morningstarPolicyId: string, morningstarInvestmentVehicleId: string): Promise<GatewayApiResponse> {
    try {
      const resultObservable = this.httpClient.delete(
        `${environment.gatewayApiBaseUrl}/v1/client-application/client-states/solis/favorites/subaccounts/${morningstarPolicyId}-${morningstarInvestmentVehicleId}`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.removeSubaccountFavorite.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResponse.fromHttpResponse(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResponse.fromHttpErrorResponse(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResponse.cancelled();
      }

      throw ex;
    }
  }

  async getTermsAccepted(version: string): Promise<GatewayApiResultResponse<SolisTermsAcceptedDto>> {
    try {
      const resultObservable = this.httpClient.get<SolisTermsAcceptedDto>(
        `${environment.gatewayApiBaseUrl}/v1/client-application/client-states/solis/terms-accepted/${version}`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getTermsAccepted.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<SolisTermsAcceptedDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<SolisTermsAcceptedDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async upsertTermsAccepted(termsAccepted: SolisTermsAcceptedDto): Promise<GatewayApiResponse> {
    try {
      const resultObservable = this.httpClient.post(
        `${environment.gatewayApiBaseUrl}/v1/client-application/client-states/solis/terms-accepted`,
        termsAccepted,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.upsertTermsAccepted.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResponse.fromHttpResponse(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResponse.fromHttpErrorResponse(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResponse.cancelled();
      }

      throw ex;
    }
  }

  async upsertUserPreferences(userPreferences: SolisUserPreferencesDto): Promise<GatewayApiResponse> {
    try {
      const resultObservable = this.httpClient.post(
        `${environment.gatewayApiBaseUrl}/v1/client-application/client-states/solis/user-preferences`,
        userPreferences,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.upsertUserPreferences.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResponse.fromHttpResponse(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResponse.fromHttpErrorResponse(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResponse.cancelled();
      }

      throw ex;
    }
  }

  async upsertSession(sessionId: string, session: SolisSessionDto): Promise<GatewayApiResponse> {
    try {
      const resultObservable = this.httpClient.patch(
        `${environment.gatewayApiBaseUrl}/v1/client-application/client-states/solis/sessions/${sessionId}`,
        session,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.upsertSession.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResponse.fromHttpResponse(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResponse.fromHttpErrorResponse(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResponse.cancelled();
      }

      throw ex;
    }
  }

  /* #endregion */

  /* #region Datamart  */

  async getCaseSummary(dtccMemberCode?: string, platformCode?: string): Promise<GatewayApiResultResponse<CaseSummaryDto>> {
    let params = new HttpParams();

    if (dtccMemberCode) {
      params = params.set('dtccMemberCode', dtccMemberCode);
    }

    if (platformCode) {
      params = params.set('platformCode', platformCode);
    }

    let url: string;
    try {
      const resultObservable = this.httpClient.get<CaseSummaryDto>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/cases/summary?${params.toString()}`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getCaseSummary.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<CaseSummaryDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<CaseSummaryDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getContractDefinition(
    contractNumber: string,
    platformCode: string,
    platformModelId: string,
    platformContractId: string,
    platformAuthenticationId: string,
    platformAdvisorId: string,
    firmDtccMemberCode: string,
    carrierCode: string
  ): Promise<GatewayApiResultResponse<ContractDefinitionDto>> {
    try {
      let params = new HttpParams();
      params = params.set('platformCode', platformCode);
      params = params.set('platformModelId', platformModelId);
      params = params.set('platformContractId', platformContractId);
      params = params.set('platformAuthenticationId', platformAuthenticationId);
      params = params.set('platformAdvisorId', platformAdvisorId);
      params = params.set('firmDtccMemberCode', firmDtccMemberCode);
      params = params.set('carrierCode', carrierCode);

      const resultObservable = this.httpClient.get<ContractDefinitionDto>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/contracts/${contractNumber}?${params.toString()}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getContractDefinition.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ContractDefinitionDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ContractDefinitionDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getContractRecommendations(
    limit: number,
    offset: number,
    sortField: string,
    sortOrder: string
  ): Promise<GatewayApiResultResponse<PagedResult<ContractRecommendationDto>>> {
    try {
      let params = new HttpParams();
      params = params.set('limit', limit);
      params = params.set('offset', offset);
      params = params.set('sort', sortOrder === 'asc' ? sortField : '-' + sortField);

      const resultObservable = this.httpClient.get<PagedResult<ContractRecommendationDto>>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/contracts/recommendations?${params.toString()}`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getContractRecommendations.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<PagedResult<ContractRecommendationDto>>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PagedResult<ContractRecommendationDto>>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getContractRecommendationsSummary(): Promise<GatewayApiResultResponse<ContractRecommendationSummaryDto>> {
    try {
      const resultObservable = this.httpClient.get<ContractRecommendationSummaryDto>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/contracts/recommendations/summary`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getContractRecommendationsSummary.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ContractRecommendationSummaryDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ContractRecommendationSummaryDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getContractTransactions(contractNumber: string): Promise<GatewayApiResultResponse<ContractTransactionDto[]>> {
    try {
      const resultObservable = this.httpClient.get<ContractTransactionDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/contracts/${contractNumber}/transactions`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getContractTransactions.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ContractTransactionDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ContractTransactionDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getOffPlatformProducts(dtccMemberCode: string, platformCode: string): Promise<GatewayApiResultResponse<OffPlatformProductDto[]>> {
    try {
      let params = new HttpParams();
      params = params.set('platformCode', platformCode);
      const resultObservable = this.httpClient.get<OffPlatformProductDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/products/off-platform-products/${dtccMemberCode}?${params.toString()}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getOffPlatformProducts.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<OffPlatformProductDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<OffPlatformProductDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getProductDetails(searchCriteria: GetProductDetailDto): Promise<GatewayApiResultResponse<ProductDetailDto>> {
    try {
      const resultObservable = this.httpClient.post<ProductDetailDto>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/products/product-details`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProductDetails.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProductDetailDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProductDetailDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getProductFee(cusip: string, productCode: string): Promise<GatewayApiResultResponse<ProductFeeDto>> {
    try {
      const resultObservable = this.httpClient.get<ProductFeeDto>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/products/${cusip}-${productCode}/fee`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProductFee.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProductFeeDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProductFeeDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getProposalSummary(
    dtccMemberCode: string,
    firmType: FirmType | undefined,
    platformCode?: string
  ): Promise<GatewayApiResultResponse<ProposalSummaryDto>> {
    try {
      let params = new HttpParams();
      params = params.set('dtccMemberCode', dtccMemberCode);

      if (firmType) {
        params = params.set('firmType', firmType);
      }

      if (platformCode) {
        params = params.set('platformCode', platformCode);
      }

      const resultObservable = this.httpClient.get<ProposalSummaryDto>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/proposals/summary?${params.toString()}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProposalSummary.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalSummaryDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalSummaryDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getRiskCalculation(riskCalculationGetDto: RiskCalculationGetDto): Promise<GatewayApiResultResponse<RiskCalculationResultBase>> {
    try {
      const resultObservable = this.httpClient.post<RiskCalculationResultBase>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/risks`,
        riskCalculationGetDto,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getRiskCalculation.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<RiskCalculationResultBase>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<RiskCalculationResultBase>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchBenefitTypes(searchCriteria: BenefitTypesSearchCriteriaDto): Promise<GatewayApiResultResponse<BenefitTypeDto[]>> {
    try {
      const resultObservable = this.httpClient.post<BenefitTypeDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/benefit-types/search`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchBenefitTypes.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<BenefitTypeDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<BenefitTypeDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchCarriers(searchCriteria: CarrierSearchCriteriaDto): Promise<GatewayApiResultResponse<CarrierDto[]>> {
    try {
      const resultObservable = this.httpClient.post<CarrierDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/carriers/search`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchCarriers.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<CarrierDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<CarrierDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchContractDefinitions(
    searchCriteria: ContractDefinitionSearchCriteriaDto,
    limit: number,
    offset: number,
    sortField: string,
    sortOrder: string
  ): Promise<GatewayApiResultResponse<PagedResult<ContractDefinitionDto>>> {
    try {
      let params = new HttpParams();
      params = params.set('limit', limit);
      params = params.set('offset', offset);
      params = params.set('sort', sortOrder === 'asc' ? sortField : '-' + sortField);
      const resultObservable = this.httpClient.post<PagedResult<ContractDefinitionDto>>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/contracts/search?${params.toString()}`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchContractDefinitions.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<PagedResult<ContractDefinitionDto>>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PagedResult<ContractDefinitionDto>>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchContractDefinitionsSummary(
    searchCriteria: ContractDefinitionSummarySearchCriteriaDto
  ): Promise<GatewayApiResultResponse<ContractDefinitionSummaryDto>> {
    try {
      const resultObservable = this.httpClient.post<ContractDefinitionSummaryDto>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/contracts/search/summary`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchContractDefinitions.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<ContractDefinitionSummaryDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ContractDefinitionSummaryDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchCreditingStrategies(
    searchCriteria: CreditingStrategySearchCriteriaDto
  ): Promise<GatewayApiResultResponse<CreditingStrategyDto[]>> {
    try {
      const resultObservable = this.httpClient.post<CreditingStrategyDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/crediting-strategies/search`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchCreditingStrategies.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<CreditingStrategyDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<CreditingStrategyDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchFilters(searchCriteria: FilterSearchCriteriaDto): Promise<GatewayApiResultResponse<FilterDto[]>> {
    try {
      const resultObservable = this.httpClient.post<FilterDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/filters/search`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchFilters.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<FilterDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<FilterDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchProductSummaries(
    searchCriteria: ProductSummarySearchCriteriaDto,
    limit: number,
    offset: number,
    productSort: ProductSortType
  ): Promise<GatewayApiResultResponse<PagedResult<ProductSummaryDto>>> {
    try {
      let params = new HttpParams();
      params = params.set('limit', limit);
      params = params.set('offset', offset);
      params = params.set('productSort', productSort);

      const resultObservable = this.httpClient.post<PagedResult<ProductSummaryDto>>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/products/product-summaries/search?${params.toString()}`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchProductSummaries.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<PagedResult<ProductSummaryDto>>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PagedResult<ProductSummaryDto>>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchRiders(searchCriteria: RiderSearchCriteriaDto, limit: number, offset: number): Promise<GatewayApiResultResponse<RiderDto[]>> {
    try {
      let params = new HttpParams();
      params = params.set('limit', limit);
      params = params.set('offset', offset);

      const resultObservable = this.httpClient.post<RiderDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/riders/search?${params.toString()}`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchRiders.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<RiderDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<RiderDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchSubaccounts(
    searchCriteria: SubaccountSearchCriteriaDto,
    limit: number,
    offset: number
  ): Promise<GatewayApiResultResponse<SubaccountDto[]>> {
    try {
      let params = new HttpParams();
      params = params.set('limit', limit);
      params = params.set('offset', offset);

      const resultObservable = this.httpClient.post<SubaccountDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/subaccounts/search?${params.toString()}`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchSubaccounts.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<SubaccountDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<SubaccountDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }
  /* #endregion */

  /* #region Financial Transactions */
  async getFinancialTransactionSummary(platformCode?: string): Promise<GatewayApiResultResponse<FinancialTransactionSummaryDto>> {
    let params = new HttpParams();

    if (platformCode) {
      params = params.set('platformCode', platformCode);
    }

    try {
      const resultObservable = this.httpClient.get<FinancialTransactionSummaryDto>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/financial-transactions/summary?${params.toString()}`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getFinancialTransactionSummary.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<FinancialTransactionSummaryDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<FinancialTransactionSummaryDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  /* #endregion */

  /* #region Infrastructure */
  async createFormSubmission(submissionDto: FormSubmissionCreateDto): Promise<GatewayApiResultResponse<FormSubmissionDto>> {
    try {
      const resultObservable = this.httpClient.post<FormSubmissionDto>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/form-submissions/`,
        submissionDto,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.createFormSubmission.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<FormSubmissionDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<FormSubmissionDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async createUser(userUpsertDto: UserUpsertDto): Promise<GatewayApiResultResponse<UserDto>> {
    try {
      const resultObservable = this.httpClient.post<UserDto>(`${environment.gatewayApiBaseUrl}/v1/infrastructure/users/`, userUpsertDto, {
        observe: 'response',
        context: new HttpContext().set(SUPPRESS_ERROR_CODES, [422]).set(API_CALL_NAME, this.createUser.name)
      });

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<UserDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<UserDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getDistributorFirms(dtccMemberCode: string, firmType: string = ''): Promise<GatewayApiResultResponse<FirmDto[]>> {
    try {
      const resultObservable = this.httpClient.get<FirmDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/firms/${dtccMemberCode}/dtcc-distributor-firms?firmType=${firmType}`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getDistributorFirms.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<FirmDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<FirmDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getFirm(dtccMemberCode: string, firmType: string, redirectOn404: boolean = false): Promise<GatewayApiResultResponse<FirmDto>> {
    try {
      const resultObservable = this.httpClient.get<FirmDto>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/firms/${dtccMemberCode}-${firmType}`,
        {
          observe: 'response',
          context: new HttpContext().set(REDIRECT_ON_404, redirectOn404).set(API_CALL_NAME, this.getFirm.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<FirmDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<FirmDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getFormDefinition(code: String): Promise<GatewayApiResultResponse<FormDefinitionDto>> {
    try {
      const resultObservable = this.httpClient.get<FormDefinitionDto>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/form-definitions/${code}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getFormDefinition.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<FormDefinitionDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<FormDefinitionDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getFormSubmission(submissionId: String): Promise<GatewayApiResultResponse<FormSubmissionDto>> {
    try {
      const resultObservable = this.httpClient.get<FormSubmissionDto>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/form-submissions/${submissionId}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getFormSubmission.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<FormSubmissionDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<FormSubmissionDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getGuidanceDeskFirms(dtccMemberCode: string): Promise<GatewayApiResultResponse<FirmDto[]>> {
    try {
      const resultObservable = this.httpClient.get<FirmDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/firms/${dtccMemberCode}/guidance-desk-firms`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getGuidanceDeskFirms.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<FirmDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<FirmDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getJurisdictions(): Promise<GatewayApiResultResponse<JurisdictionDto[]>> {
    try {
      const resultObservable = this.httpClient.get<JurisdictionDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/reference-data/jurisdictions`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getJurisdictions.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<JurisdictionDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<JurisdictionDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getTeam(dtccMemberCode: string, firmType: string, teamCode: string): Promise<GatewayApiResultResponse<TeamDto>> {
    try {
      let params = new HttpParams();
      params = params.set('dtccMemberCode', dtccMemberCode);
      params = params.set('firmType', firmType);
      params = params.set('teamCode', teamCode);

      const resultObservable = this.httpClient.get<TeamDto>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/users/team?${params.toString()}`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getTeam.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<TeamDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<TeamDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getUser(userId: string, redirectOn404: boolean = false): Promise<GatewayApiResultResponse<UserDto>> {
    try {
      const resultObservable = this.httpClient.get<UserDto>(`${environment.gatewayApiBaseUrl}/v1/infrastructure/users/${userId}`, {
        observe: 'response',
        context: new HttpContext().set(REDIRECT_ON_404, redirectOn404).set(API_CALL_NAME, this.getUser.name)
      });

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<UserDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<UserDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getPersonas(entityType: string, entityCode: string): Promise<GatewayApiResultResponse<PersonaDto[]>> {
    try {
      const resultObservable = this.httpClient.get<PersonaDto[]>(`${environment.gatewayApiBaseUrl}/v1/infrastructure/users/personas?entityType=${entityType}&entityCode=${entityCode}`, {
        observe: 'response',
        context: new HttpContext().set(API_CALL_NAME, this.getPersonas.name)
      });

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<PersonaDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PersonaDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async manageUserPersonas(userId: string, entityType: string, entityCode: string, capabilities: ManageUserPersonasDto): Promise<GatewayApiResultResponse<UserDto>> {
    try {
      const resultObservable = this.httpClient.post<UserDto>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/users/${userId}/personas?entityType=${entityType}&entityCode=${entityCode}`,
        capabilities,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.manageUserPersonas.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<UserDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<UserDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async validatePlatformCredentials(
    platformCode: string,
    platformCredentialsDto: PlatformCredentialsRequestDto
  ): Promise<GatewayApiResultResponse<PlatformCredentialsResponseDto>> {
    try {
      const resultObservable = this.httpClient.post<PlatformCredentialsResponseDto>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/users/validate-platform-credentials?platformCode=${platformCode}`,
        platformCredentialsDto,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.validatePlatformCredentials.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<PlatformCredentialsResponseDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PlatformCredentialsResponseDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async resetPassword(userId: string): Promise<GatewayApiResponse> {
    try {
      const resultObservable = this.httpClient.post(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/users/${userId}/reset-password`,
        null,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.resetPassword.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResponse.fromHttpResponse(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResponse.fromHttpErrorResponse(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResponse.cancelled();
      }

      throw ex;
    }
  }

  async resolveSettings(searchCriteria: SettingSearchCriteriaDto): Promise<GatewayApiResultResponse<SettingDto[]>> {
    try {
      const resultObservable = this.httpClient.post<SettingDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/settings`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.resolveSettings.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<SettingDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<SettingDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchUsers(userSearchCriteria: UserSearchCriteriaDto): Promise<GatewayApiResultResponse<UserInfoDto[]>> {
    try {
      const resultObservable = this.httpClient.post<UserInfoDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/users/search`,
        userSearchCriteria,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.searchUsers.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<UserInfoDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<UserInfoDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async upsertImpersonations(
    userId: string,
    userImpersonationUpsertDto: UserImpersonationsDto
  ): Promise<GatewayApiResultResponse<UserDto>> {
    try {
      const resultObservable = this.httpClient.post<UserDto>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/users/${userId}/impersonations`,
        userImpersonationUpsertDto,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.upsertImpersonations.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<UserDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<UserDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async updateUser(userId: string, userUpsertDto: UserUpsertDto): Promise<GatewayApiResultResponse<UserDto>> {
    try {
      const resultObservable = this.httpClient.put<UserDto>(
        `${environment.gatewayApiBaseUrl}/v1/infrastructure/users/${userId}`,
        userUpsertDto,
        {
          observe: 'response',
          context: new HttpContext().set(SUPPRESS_ERROR_CODES, [422]).set(API_CALL_NAME, this.updateUser.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<UserDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<UserDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  /* #endregion */

  /* #region Licensing  */
  async getLicenseCheck(
    requestId: string,
    workflowStep: LicenseCheckWorkflowStepType
  ): Promise<GatewayApiResultResponse<LicenseCheckResultDto>> {
    try {
      const resultObservable = this.httpClient.get<LicenseCheckResultDto>(
        `${environment.gatewayApiBaseUrl}/v1/licensing/license-checks/${requestId}?workflowStep=${workflowStep}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getLicenseCheck.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<LicenseCheckResultDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<LicenseCheckResultDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async upsertLicenseCheck(licenseCheck: LicenseCheckUpsertDto): Promise<GatewayApiResultResponse<LicenseCheckResultDto>> {
    try {
      const resultObservable = this.httpClient.post<LicenseCheckResultDto>(
        `${environment.gatewayApiBaseUrl}/v1/licensing/license-checks`,
        licenseCheck,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.upsertLicenseCheck.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<LicenseCheckResultDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<LicenseCheckResultDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }
  /* #endregion */

  /* #region Product */
  async getProductDocumentEffectiveContent(
    productDocumentId: string,
    dtccMemberCode: string,
    includeContent = false
  ): Promise<GatewayApiResultResponse<ProductDocumentContentDto>> {
    try {
      let params = new HttpParams();
      if (dtccMemberCode.length > 0) {
        params = params.set('dtccMemberCode', dtccMemberCode);
      }
      if (includeContent) {
        params = params.set('includeContent', true);
      }
      const queryString = params.keys().length > 0 ? `?${params.toString()}` : '';
      const resultObservable = this.httpClient.get<ProductDocumentContentDto>(
        `${environment.gatewayApiBaseUrl}/v1/product/product-documents/${productDocumentId}/effective-content${queryString}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProductDocumentEffectiveContent.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<ProductDocumentContentDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProductDocumentContentDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchProductDocumentsContent(
    searchCriteria: ProductDocumentContentSearchCriteriaDto
  ): Promise<GatewayApiResultResponse<ProductDocumentDto[]>> {
    try {
      const resultObservable = this.httpClient.post<ProductDocumentDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/product/product-documents/contents/search`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchProductDocumentsContent.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<ProductDocumentDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProductDocumentDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  /* #endregion */

  /* #region Private Placement  */
  async createPlacement(placement: PlacementCreateDto, destination: PlacementDestination): Promise<GatewayApiResultResponse<PlacementDto>> {
    try {
      const resultObservable = this.httpClient.post<PlacementDto>(
        `${environment.gatewayApiBaseUrl}/v1/private-placement/placements?destination=${destination}`,
        placement,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.createPlacement.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<PlacementDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PlacementDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getPlacement(placementId: string): Promise<GatewayApiResultResponse<PlacementDto>> {
    try {
      const resultObservable = this.httpClient.get<PlacementDto>(
        `${environment.gatewayApiBaseUrl}/v1/private-placement/placements/${placementId}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getPlacement.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<PlacementDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PlacementDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getPlacementProduct(cusip: string): Promise<GatewayApiResultResponse<PlacementProductDto>> {
    try {
      const resultObservable = this.httpClient.get<PlacementProductDto>(
        `${environment.gatewayApiBaseUrl}/v1/private-placement/products/${cusip}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getPlacementProduct.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<PlacementProductDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PlacementProductDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getPlacementProducts(): Promise<GatewayApiResultResponse<PlacementProductDto[]>> {
    try {
      const resultObservable = this.httpClient.get<PlacementProductDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/private-placement/products`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.getPlacementProducts.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<PlacementProductDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PlacementProductDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getPlacements(userId: string, dtccMemberCode: string, firmType: FirmType): Promise<GatewayApiResultResponse<PlacementDto[]>> {
    try {
      const resultObservable = this.httpClient.get<PlacementDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/private-placement/placements?userId=${userId}&dtccMemberCode=${dtccMemberCode}&firmType=${firmType}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getPlacements.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<PlacementDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PlacementDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  /* #endregion */

  /* #region Proposals */

  async assignProposalCase(
    proposalCaseId: string,
    assignUserDto: ProposalCaseAssignDto
  ): Promise<GatewayApiResultResponse<ProposalCaseDto>> {
    try {
      const resultObservable = this.httpClient.put<ProposalCaseDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/${proposalCaseId}/assign`,
        assignUserDto,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.assignProposalCase.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalCaseDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalCaseDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async assignProposalCases(
    assignUserDto: ProposalCaseAssignDto,
    proposalCaseId: string
  ): Promise<GatewayApiResultResponse<ProposalCaseDto>> {
    try {
      const resultObservable = this.httpClient.put<ProposalCaseDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/${proposalCaseId}/assign`,
        assignUserDto,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.assignProposalCases.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalCaseDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalCaseDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async cancelProposalCase(
    proposalCaseId: string,
    status: ProposalCaseStatusUpdateDto | null = null
  ): Promise<GatewayApiResultResponse<ProposalCaseDto>> {
    try {
      const resultObservable = this.httpClient.put<ProposalCaseDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/${proposalCaseId}/cancel`,
        null,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.cancelProposalCase.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalCaseDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalCaseDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async createProposalCase(proposalCase: ProposalCaseCreateDto): Promise<GatewayApiResultResponse<ProposalCaseDto>> {
    try {
      const resultObservable = this.httpClient.post<ProposalCaseDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases`,
        proposalCase,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.createProposalCase.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalCaseDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalCaseDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async createProposalCaseAttachments(
    attachments: ProposalCaseAttachmentCreateDto[],
    proposalCaseId: string
  ): Promise<GatewayApiResultResponse<ProposalCaseAttachmentDto[]>> {
    try {
      const resultObservable = this.httpClient.post<ProposalCaseAttachmentDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/${proposalCaseId}/attachments`,
        attachments,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.createProposalCaseAttachments.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalCaseAttachmentDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalCaseAttachmentDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async createProposalProductComparePdf(
    generatePdf: ComparisonPdfCreateDto
  ): Promise<GatewayApiResultResponse<ProductComparePdfAttachmentDto>> {
    try {
      const resultObservable = this.httpClient.post<ProductComparePdfAttachmentDto>(
        `${environment.gatewayApiBaseUrl}/v1/datamart/products/comparison-pdf`,
        generatePdf,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.createProposalProductComparePdf.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<ProductComparePdfAttachmentDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProductComparePdfAttachmentDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async createProposalCaseNote(
    proposalCaseId: string,
    caseNote: ProposalCaseNoteCreateUpdateDto
  ): Promise<GatewayApiResultResponse<ProposalCaseNoteDto>> {
    try {
      const resultObservable = this.httpClient.post<ProposalCaseNoteDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/${proposalCaseId}/note`,
        caseNote,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.createProposalCaseNote.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalCaseNoteDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalCaseNoteDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async createProposalDetailIllustration(
    platformProposalId: string,
    proposalDetailId: string,
    proposalDetailDocumentIllustration: ProposalDetailDocumentIllustrationCreateDto
  ): Promise<GatewayApiResultResponse<ProposalDetailDocumentIllustrationDto>> {
    try {
      const resultObservable = this.httpClient.post<ProposalDetailDocumentIllustrationDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposals/${platformProposalId}/details/${proposalDetailId}/illustration`,
        proposalDetailDocumentIllustration,
        {
          observe: 'response',
          context: new HttpContext().set(SUPPRESS_ERROR_CODES, [422]).set(API_CALL_NAME, this.createProposalDetailIllustration.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalDetailDocumentIllustrationDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalDetailDocumentIllustrationDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async deleteProposalCase(proposalCaseId: string): Promise<GatewayApiResponse> {
    try {
      const resultObservable = this.httpClient.delete(`${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/${proposalCaseId}`, {
        observe: 'response',
        context: new HttpContext().set(API_CALL_NAME, this.deleteProposalCase.name)
      });

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResponse.fromHttpResponse(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResponse.fromHttpErrorResponse(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResponse.cancelled();
      }

      throw ex;
    }
  }

  async getProposal(platformProposalId: string): Promise<GatewayApiResultResponse<ProposalDto>> {
    try {
      const resultObservable = this.httpClient.get<ProposalDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposals/${platformProposalId}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProposal.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getProposalCase(proposalCaseId: string): Promise<GatewayApiResultResponse<ProposalCaseDto>> {
    try {
      const resultObservable = this.httpClient.get<ProposalCaseDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/${proposalCaseId}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProposalCase.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalCaseDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalCaseDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getProposalCaseAttachment(
    proposalCaseId: string,
    proposalCaseAttachmentId: string,
    includeContent = false
  ): Promise<GatewayApiResultResponse<ProposalCaseAttachmentDto>> {
    try {
      const contentString = includeContent ? '?includeContent=true' : '';
      const resultObservable = this.httpClient.get<ProposalCaseAttachmentDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/${proposalCaseId}/attachments/${proposalCaseAttachmentId}${contentString}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProposalCaseAttachment.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalCaseAttachmentDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalCaseAttachmentDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getProposalCaseAttachments(
    proposalCaseId: string,
    includeContent = false
  ): Promise<GatewayApiResultResponse<ProposalCaseAttachmentDto[]>> {
    try {
      const contentString = includeContent ? '?includeContent=true' : '';
      const resultObservable = this.httpClient.get<ProposalCaseAttachmentDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/${proposalCaseId}/attachments${contentString}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProposalCaseAttachments.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalCaseAttachmentDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalCaseAttachmentDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getProposalCases(dtccMemberCode: string): Promise<GatewayApiResultResponse<ProposalCaseFirmSummaryDto[]>> {
    try {
      const resultObservable = this.httpClient.get<ProposalCaseFirmSummaryDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/firm-summary/${dtccMemberCode}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProposalCases.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalCaseFirmSummaryDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalCaseFirmSummaryDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getProposalDetailPackage(
    platformProposalId: string,
    proposalDetailId: string,
    includeContent = false
  ): Promise<GatewayApiResultResponse<ProposalDetailPackageDto>> {
    try {
      const contentString = includeContent ? '?includeContent=true' : '';
      const resultObservable = this.httpClient.get<ProposalDetailPackageDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposals/${platformProposalId}/details/${proposalDetailId}/package${contentString}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProposalDetails.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalDetailPackageDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalDetailPackageDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getProposalDetails(platformProposalId: string): Promise<GatewayApiResultResponse<ProposalDetailPackageDto[]>> {
    try {
      const resultObservable = this.httpClient.get<ProposalDetailPackageDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposals/${platformProposalId}/details`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProposalDetails.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalDetailPackageDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalDetailPackageDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getProposalPackageExists(
    platformProposalId: string | null,
    proposalDetailId: string | null
  ): Promise<GatewayApiResultResponse<ProposalPackageExistsDto>> {
    try {
      let params = new HttpParams();

      if (platformProposalId) {
        params = params.set('platformProposalId', platformProposalId);
      }

      if (proposalDetailId) {
        params = params.set('proposalDetailId', proposalDetailId);
      }

      const resultObservable = this.httpClient.get<ProposalPackageExistsDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposals/exists?${params.toString()}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getProposalPackageExists.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalPackageExistsDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalPackageExistsDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async refreshProposalDetailApplicationStatus(
    platformProposalId: string,
    proposalDetailId: string
  ): Promise<GatewayApiResultResponse<ApplicationStatusDto>> {
    try {
      const resultObservable = this.httpClient.post<ApplicationStatusDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposals/${platformProposalId}/details/${proposalDetailId}/refresh-application-status`,
        null,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.refreshProposalDetailApplicationStatus.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ApplicationStatusDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ApplicationStatusDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async reinitializeProposalDetail(platformProposalId: string): Promise<GatewayApiResultResponse<ProposalDetailDto>> {
    try {
      const resultObservable = this.httpClient.post<ProposalDetailDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposals/${platformProposalId}/reinitialize-details`,
        null,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.reinitializeProposalDetail.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalDetailDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalDetailDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async removeProposalCaseAttachment(proposalCaseId: string, proposalCaseAttachmentId: string): Promise<GatewayApiResponse> {
    try {
      const resultObservable = this.httpClient.delete(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/${proposalCaseId}/attachments/${proposalCaseAttachmentId}`,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.removeProposalCaseAttachment.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResponse.fromHttpResponse(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResponse.fromHttpErrorResponse(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchProposalCases(
    searchCriteria: ProposalCaseSearchCriteriaDto,
    limit: number,
    offset: number,
    sortField: string,
    sortOrder: string,
    filters: string | undefined = undefined
  ): Promise<GatewayApiResultResponse<PagedResult<ProposalCaseDto>>> {
    try {
      let params = new HttpParams();
      params = params.set('limit', limit);
      params = params.set('offset', offset);
      params = params.set('sort', sortOrder === 'asc' ? sortField : '-' + sortField);
      if (filters) {
        params = params.set('filter', filters);
      }

      const resultObservable = this.httpClient.post<PagedResult<ProposalCaseDto>>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/search?${params.toString()}`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchProposalCases.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<PagedResult<ProposalCaseDto>>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PagedResult<ProposalCaseDto>>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchProposalCasesFilters(searchCriteria: ProposalCaseFiltersCriteriaDto): Promise<GatewayApiResultResponse<SearchFiltersDto>> {
    try {
      const resultObservable = this.httpClient.post<SearchFiltersDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposal-cases/search/filters`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchProposalCasesFilters.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<SearchFiltersDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<SearchFiltersDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchProposals(
    searchCriteria: ProposalSearchCriteriaDto,
    limit: number,
    offset: number,
    sortField: string,
    sortOrder: string
  ): Promise<GatewayApiResultResponse<PagedResult<ProposalDto>>> {
    try {
      let params = new HttpParams();
      // TODO: Add this back for lazy loading of proposals
      // params = params.set('limit', limit);
      // params = params.set('offset', offset);
      params = params.set('sort', sortOrder === 'asc' ? sortField : '-' + sortField);

      const resultObservable = this.httpClient.post<PagedResult<ProposalDto>>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposals/search?${params.toString()}`,
        searchCriteria,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.searchProposals.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<PagedResult<ProposalDto>>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PagedResult<ProposalDto>>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async updateProposalDetailIllustrationStatus(
    platformProposalId: string,
    proposalDetailId: string,
    illustrationStatusDto: ProposalDetailDocumentIllustrationStatusDto
  ): Promise<GatewayApiResultResponse<ProposalDetailDto>> {
    try {
      const resultObservable = this.httpClient.put<ProposalDetailDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposals/${platformProposalId}/details/${proposalDetailId}/illustration/status`,
        illustrationStatusDto,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.updateProposalDetailIllustrationStatus.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalDetailDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalDetailDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async unlockProposal(platformProposalId: string): Promise<GatewayApiResultResponse<ProposalDto>> {
    try {
      const resultObservable = this.httpClient.post<ProposalDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposals/${platformProposalId}/unlock`,
        null,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.unlockProposal.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async upsertProposal(proposal: ProposalUpsertDto): Promise<GatewayApiResultResponse<ProposalDto>> {
    try {
      const resultObservable = this.httpClient.post<ProposalDto>(`${environment.gatewayApiBaseUrl}/v1/proposal/proposals`, proposal, {
        observe: 'response',
        context: new HttpContext().set(API_CALL_NAME, this.upsertProposal.name)
      });

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async upsertProposalDetail(
    platformProposalId: string,
    proposalDetail: ProposalDetailUpsertDto
  ): Promise<GatewayApiResultResponse<ProposalDetailDto>> {
    try {
      const resultObservable = this.httpClient.post<ProposalDetailDto>(
        `${environment.gatewayApiBaseUrl}/v1/proposal/proposals/${platformProposalId}/details`,
        proposalDetail,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.upsertProposalDetail.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ProposalDetailDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ProposalDetailDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  /* #endregion */

  /* #region Transactions */

  async createTransaction(transactionCreate: TransactionCreateDto): Promise<GatewayApiResultResponse<TransactionCreateResponseDto>> {
    try {
      const resultObservable = this.httpClient.post<TransactionCreateResponseDto>(
        `${environment.gatewayApiBaseUrl}/v1/financial-transaction/transactions`,
        transactionCreate,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.createTransaction.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<TransactionCreateResponseDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<TransactionCreateResponseDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async getTransactionConfiguration(
    dtccMemberCode: string,
    contractNumber: string,
    platformCode: string,
    platformTransactionType?: string
  ): Promise<GatewayApiResultResponse<TransactionConfigurationDto>> {
    try {
      let params = new HttpParams();
      params = params.set('dtccMemberCode', dtccMemberCode);
      params = params.set('contractNumber', contractNumber);
      params = params.set('platformCode', platformCode);

      if (platformTransactionType) {
        params = params.set('platformTransactionType', platformTransactionType);
      }

      const resultObservable = this.httpClient.get<TransactionConfigurationDto>(
        `${environment.gatewayApiBaseUrl}/v1/financial-transaction/transactions/configurations?${params.toString()}`,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.getTransactionConfiguration.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<TransactionConfigurationDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<TransactionConfigurationDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async searchTransactions(searchCriteria: TransactionsSearchCriteriaDto): Promise<GatewayApiResultResponse<TransactionDto[]>> {
    try {
      const resultObservable = this.httpClient.post<TransactionDto[]>(
        `${environment.gatewayApiBaseUrl}/v1/financial-transaction/transactions/search`,
        searchCriteria,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.searchTransactions.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<TransactionDto[]>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<TransactionDto[]>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async updateTransactionStatus(
    transactionStatusUpdate: TransactionStatusUpdateDto
  ): Promise<GatewayApiResultResponse<TransactionStatusDto>> {
    try {
      const resultObservable = this.httpClient.patch<TransactionStatusDto>(
        `${environment.gatewayApiBaseUrl}/v1/financial-transaction/transactions/status`,
        transactionStatusUpdate,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.updateTransactionStatus.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<TransactionStatusDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<TransactionStatusDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async cancelTransaction(transactionCancel: TransactionCancelDto): Promise<GatewayApiResultResponse<TransactionCancelResponseDto>> {
    try {
      const resultObservable = this.httpClient.post<TransactionCancelResponseDto>(
        `${environment.gatewayApiBaseUrl}/v1/financial-transaction/transactions/cancel`,
        transactionCancel,
        {
          observe: 'response',
          context: new HttpContext().set(API_CALL_NAME, this.cancelTransaction.name)
        }
      );

      const httpResponse = await lastValueFrom(resultObservable);
      return GatewayApiResultResponse.fromHttpResponse<TransactionCancelResponseDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<TransactionCancelResponseDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  /* #endregion */

  /* #region Value Inquiries */

  async createPartialWithdrawalQuote(
    partialWithdrawalQuoteCreate: PartialWithdrawalQuoteCreateDto,
    useCachedValuesInquiry: boolean = false
  ): Promise<GatewayApiResultResponse<PartialWithdrawalQuoteDto>> {
    try {
      const resultObservable = this.httpClient.post<PartialWithdrawalQuoteDto>(
        `${environment.gatewayApiBaseUrl}/v1/financial-transaction/value-inquiries/partial-withdrawal?useCachedValuesInquiry=${useCachedValuesInquiry}`,
        partialWithdrawalQuoteCreate,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.createPartialWithdrawalQuote.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<PartialWithdrawalQuoteDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<PartialWithdrawalQuoteDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async createPositionsRequest(
    positionsRequestCreate: PositionsRequestCreateDto,
    useCachedValuesInquiry: boolean = false
  ): Promise<GatewayApiResultResponse<ValueInquiryDto>> {
    try {
      const resultObservable = this.httpClient.post<ValueInquiryDto>(
        `${environment.gatewayApiBaseUrl}/v1/financial-transaction/value-inquiries/positions?useCachedValuesInquiry=${useCachedValuesInquiry}`,
        positionsRequestCreate,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.createPositionsRequest.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<ValueInquiryDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<ValueInquiryDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  async createWithdrawalQuote(
    withdrawalQuoteCreate: WithdrawalQuoteCreateDto,
    useCachedValuesInquiry: boolean = false
  ): Promise<GatewayApiResultResponse<WithdrawalQuoteDto>> {
    try {
      const resultObservable = this.httpClient.post<WithdrawalQuoteDto>(
        `${environment.gatewayApiBaseUrl}/v1/financial-transaction/value-inquiries/withdrawal-quote?useCachedValuesInquiry=${useCachedValuesInquiry}`,
        withdrawalQuoteCreate,
        { observe: 'response', context: new HttpContext().set(API_CALL_NAME, this.createWithdrawalQuote.name) }
      );

      const httpResponse = await lastValueFrom(resultObservable);

      return GatewayApiResultResponse.fromHttpResponse<WithdrawalQuoteDto>(httpResponse);
    } catch (ex: unknown) {
      if (ex instanceof HttpErrorResponse) {
        return GatewayApiResultResponse.fromHttpErrorResponse<WithdrawalQuoteDto>(ex);
      }

      if (ex instanceof EmptyError) {
        return GatewayApiResultResponse.cancelled();
      }

      throw ex;
    }
  }

  /* #endregion */
}
