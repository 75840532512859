export class RequestNames {
  static readonly getAnnuityProfileCompletionStatus = 'get-annuity-profile-completion-status';
  static readonly getAnnuityProfileStatusForOffPlatform = 'get-annuity-profile-status-for-off-platform';
  static readonly getProductDetailSelectionState = 'get-product-detail-selection-state';
  static readonly getProductDetailItemModels = 'get-product-detail-item-models';
  static readonly getProductDetailSummaryState = 'get-product-detail-summary-state';
  static readonly processProductDetailSelection = 'process-product-detail-selection';
  static readonly processSubaccountAutoAllocation = 'process-subaccount-auto-allocation';
  static readonly loadProductDetailSelectionState = 'load-product-detail-selection-state';
  static readonly reprocessProductDetailSelection = 'reprocess-product-detail-selection';
  static readonly validateProductDetailSelections = 'validate-product-detail-selections';
  static readonly checkArrangementTypeSelected = 'check-arrangement-type-selected';
  static readonly validateProductDetailAllocations = 'validate-product-detail-allocations';
  static readonly validateProductDetailForms = 'validate-product-detail-forms';
  static readonly saveProductDetailSelections = 'save-product-detail-selections';
  static readonly sendEmbedMessage = 'send-embed-message';
  static readonly setDynamicFilters = 'set-dynamic-filters';
  static readonly setPageTitle = 'set-page-title';
  static readonly addAnnuityCompareItem = 'add-annuity-compare-item';
  static readonly executeMarketplaceAction = 'execute-marketplace-action';
}
