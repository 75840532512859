import { Component, Injector } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BreadcrumbModule } from 'primeng/breadcrumb';

import { ComponentBase } from 'framework';

import { PartnerSubHeaderViewModel } from './partner-sub-header.view-models';

@Component({
  selector: 'app-partner-sub-header',
  standalone: true,
  imports: [RouterModule, BreadcrumbModule],
  providers: [PartnerSubHeaderViewModel],
  templateUrl: './partner-sub-header.component.html',
  styleUrls: ['./partner-sub-header.component.scss']
})
export class PartnerSubHeaderComponent extends ComponentBase<PartnerSubHeaderViewModel> {
  constructor(
    protected override injector: Injector,
    viewModel: PartnerSubHeaderViewModel
  ) {
    super(injector, viewModel);
  }
}
