<div class="subnavbar-sticky sub-nav-bg-scroll container-fluid card rounded-0">
  <div class="row my-2">
    <div class="col-md-6 col-lg-5 px-sm-smallscreen-0">
      <h6 class="mb-0 pt-0 fw-bold">{{ viewModel.subheaderTitle }}</h6>
      <p-breadcrumb
        [hidden]="viewModel.breadcrumbItems.length === 0 && !viewModel.home"
        styleClass="border-none p-0 f-breadcrumb"
        [model]="viewModel.breadcrumbItems"
        [home]="viewModel.home">
        <ng-template pTemplate="separator">
          <span class="breadcrumb-separator" [hidden]="viewModel.breadcrumbItems.length === 0">/</span>
        </ng-template>
      </p-breadcrumb>
    </div>

    <div class="col-md-6 col-lg-7 text-end px-sm-smallscreen-0 pt-lg-2 pt-md-2">
      <div class="right-side-section">
        <!-- TO BE ADDED WHEN READY TO ADD ANNUITY AND ADVISOR TOOLS SECTIONS
          @if (hasRoute('annuity-compare')) {
            <button class="btn btn-primary btn-sm me-2" (click)="addAnnuity()" [disabled]="annuities?.length==3">Add Annuity</button>
            <button class="btn btn-primary btn-sm me-2" [disabled]="annuities?.length==3">Add Replacement</button>
          }
        -->
        @if (viewModel.isEmbeddedSession) {
          <span class="fa-solid fa-xmark me-2 cursor close-and-exit" (click)="viewModel.closeAndExit()"></span>
        }
        @if (!viewModel.proposalInfoHidden) {
          @if (viewModel.isProposalAvailable) {
            <p class="proposal-title mb-0 me-2 d-inline">{{ viewModel.proposalName }}</p>
          }
          @if (viewModel.isProposalAvailable && !viewModel.isEmbeddedSession) {
            <button (click)="viewModel.clearCurrentProposal()" class="btn btn-primary proposal-button me-2">
              {{ viewModel.isIxHubFlow ? 'Close Current Proposal' : 'Clear Current Proposal' }}
            </button>
          }
        }
        @if (!viewModel.advisorToolsHidden && viewModel.advisorItems.length > 0) {
          <p-dropdown
            scrollHeight="max-height"
            styleClass="form-control advisor-tools"
            panelStyleClass="advisor-tools-panel"
            dropdownIcon="fa-solid fa-angle-down"
            [options]="viewModel.advisorItems">
            <ng-template pTemplate="selectedItem">
              <i class="fa-regular fa-briefcase bold" style="padding-right: 8px"></i>
              <span class="tools-text">{{ viewModel.advisorToolsTitle }}</span>
            </ng-template>
            <ng-template let-image pTemplate="item">
              <div class="ui-helper-clearfix" class="template-border">
                <span (click)="viewModel.popupNavigateTo(image.redirectUrl)">
                  <img class="img-fluid rounded" [src]="image.logo" [alt]="viewModel.advisorToolsTitle" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="dropdownicon">
              <i class="fa-solid fa-angle-down"></i>
            </ng-template>
          </p-dropdown>
        }
      </div>
    </div>
  </div>
</div>
