import { v4 as uuidv4 } from 'uuid';

export class WebHelper {
  // IF THIS NEEDS TO BE USED, PLEASE UNCOMMENT
  // static deepCopy(obj: any): any {
  //   let copy: any;

  //   // Handle the 3 simple types, and null or undefined
  //   if (null == obj || 'object' != typeof obj) return obj;

  //   // Handle Date
  //   if (obj instanceof Date) {
  //     copy = new Date();
  //     copy.setTime(obj.getTime());
  //     return copy;
  //   }

  //   // Handle Array
  //   if (obj instanceof Array) {
  //     copy = [];
  //     for (let i = 0, len = obj.length; i < len; i++) {
  //       copy[i] = this.deepCopy(obj[i]);
  //     }
  //     return copy;
  //   }

  //   // Handle Object
  //   if (obj instanceof Object) {
  //     copy = {};
  //     for (let attr in obj) {
  //       if (obj.hasOwnProperty(attr)) {
  //         copy[attr] = this.deepCopy(obj[attr]);
  //       }
  //     }
  //     return copy;
  //   }

  //   throw new Error("Unable to copy obj! Its type isn't supported.");
  // }

  static getRandomElement(arr: any[]): any {
    return arr.length ? arr[Math.floor(Math.random() * arr.length)] : undefined;
  }

  static getTableValueClass(number: number | null): { [key: string]: boolean } {
    const classObj = {
      'positive-value': false,
      'negative-value': false,
      'empty-data': false
    };
    if (number === null) {
      classObj['empty-data'] = true;
    } else {
      classObj['positive-value'] = number >= 0;
      classObj['negative-value'] = number < 0;
    }
    return classObj;
  }

  static generateUuid(): string {
    return uuidv4();
  }

  static formatErrorMessage(message: string, suffix: string = '(IX)'): string {
    return `${message.trim()} ${suffix}`;
  }
}
