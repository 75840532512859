import { Injectable } from '@angular/core';

import { BusEvent, EventBus, ToastService } from 'framework';

import { ProposalService } from '../proposal/proposal.service';

import { SolisSessionService } from '../session/solis-session.service';
import { SessionKeys } from '../../constants/session-keys';

import { GatewayApiClient } from '../gateway-api-client/gateway-api-client.service';
import { ProposalDetailDto, ProposalDto } from '../gateway-api-client/dtos/proposal.dtos';

import { EventNames } from '../../constants/event-names';
import { ProposalProfileChangedEvent } from '../../events/proposal-profile.events';

import { ProposalProfileModel } from '../../models/proposal-profile.models';

@Injectable({
  providedIn: 'root'
})
export class ProposalSessionService {
  constructor(
    private solisSessionService: SolisSessionService,
    private proposalService: ProposalService,
    private apiClient: GatewayApiClient,
    private toastService: ToastService,
    private eventBus: EventBus
  ) {}

  clearProposalFromSession(): void {
    // Null out relevant keys
    [
      SessionKeys.annuityProfile,
      SessionKeys.annuityDetails,
      SessionKeys.clientProfile,
      SessionKeys.proposalFlowDetail,
      SessionKeys.proposalProfile,
      SessionKeys.proposalTeam,
      SessionKeys.selectedGuidanceDesk,
      SessionKeys.advisorRiskData,
      SessionKeys.createProposalOptions,
      SessionKeys.insuranceExchangeData
    ].forEach((key: string) => this.solisSessionService.localSession.set(key, null));

    // Some session items require default values. This will re-initalize them
    this.solisSessionService.reset();
  }

  async processProposalToSessionById(platformProposalId: string, proposalDetailId: string): Promise<ProposalDetailDto | null> {
    let proposal: ProposalDto | null = null;
    const response = await this.apiClient.getProposal(platformProposalId);

    if (response.isSuccessStatusCode) {
      proposal = response.result!;
    }

    if (proposal) {
      const proposalDetailResponse = await this.apiClient.getProposalDetailPackage(platformProposalId, proposalDetailId);

      if (proposalDetailResponse.isSuccessStatusCode) {
        const result = await this.processProposalToSession(proposal, proposalDetailResponse.result!);

        if (result) {
          return proposalDetailResponse.result!;
        }
      }
    }

    return null;
  }

  async processProposalToSession(proposal: ProposalDto, proposalDetail: ProposalDetailDto): Promise<boolean> {
    // We can only process proposal details that have valid data; exit if selected detail does not.
    if (!this.proposalService.validateProposalDetail(proposalDetail)) {
      this.toastService.error('Invalid proposal details found');
      return false;
    }

    // If success, update solis with proposal activity data.
    const isOffPlatform = proposalDetail.product?.isOffPlatform ?? false;
    const annuityProfile = this.proposalService.createAnnuityProfileFromProposalDetail(proposalDetail);
    const annuityDetails = this.proposalService.createAnnuityDetailFromProposalDetail(proposalDetail);
    const clientProfile = !isOffPlatform ? this.proposalService.createClientProfileFromProposalDetail(proposalDetail) : null;
    const proposalTeam = !isOffPlatform ? this.proposalService.createProposalTeamFromProposalDetail(proposalDetail) : null;
    const proposalProfile = new ProposalProfileModel({
      proposalId: proposalDetail.proposalId,
      platformProposalId: proposal.platformProposalId,
      proposalDetailId: proposalDetail.id,
      proposalName: proposal.name,
      proposalCaseId: proposal.proposalCase?.id,
      proposalCaseAgentUserId: proposal.proposalCase?.assignedToUser?.id
    });

    // Update solis session with all proposal data
    this.solisSessionService.localSession.set(SessionKeys.insuranceWorkbenchFlow, null);
    this.solisSessionService.localSession.set(SessionKeys.annuityProfile, annuityProfile);
    this.solisSessionService.localSession.set(SessionKeys.annuityDetails, annuityDetails);
    this.solisSessionService.localSession.set(SessionKeys.clientProfile, clientProfile);
    this.solisSessionService.localSession.set(SessionKeys.proposalTeam, proposalTeam);
    this.solisSessionService.localSession.set(SessionKeys.proposalProfile, proposalProfile);
    // This will make changes in the app to components that use the proposal data
    this.eventBus.emit(new BusEvent(EventNames.proposalProfileChanged, new ProposalProfileChangedEvent(proposalProfile)));

    return true;
  }
}
