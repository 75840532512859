import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  ActivatedRoute,
  NavigationBehaviorOptions,
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  Router,
  UrlTree
} from '@angular/router';
import { filter } from 'rxjs';

import { BusEvent, EventBus } from 'framework';

import { SolisSessionService } from '../session/solis-session.service';
import { SolisSessionType } from '../session/solis-session-service.enums';

import { StringHelper } from '../../helpers/string/string.helper';

import { EventNames } from '../../constants/event-names';
import { SessionKeys } from '../../constants/session-keys';

import { ADVISOR_ROOT_PREFIX, PARTNER_ROOT_PREFIX } from '../../app-routes';
import { WebHelper } from '../../helpers/web/web.helper';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private homePathKey: string = 'homeRoute';
  private history: string[] = [];

  public lastSuccessfulNavigationStartUrl: string = '';

  // Set subscription for adding history of user process
  constructor(
    private router: Router,
    private solisSessionService: SolisSessionService,
    private location: Location,
    private eventBus: EventBus,
    private activatedRoute: ActivatedRoute
  ) {}

  initialize(): void {
    // NavigationStart
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
      const navigationEvent = event as NavigationStart;

      if (!this.isErrorPage(navigationEvent.url)) {
        // If we're not going to the errors page, save this navigation
        // Below if we end up on the errors page, we'll replace the /errors/:code url
        // with this url
        this.lastSuccessfulNavigationStartUrl = navigationEvent.url;

        // If this is the result of a back button being pressed in the browser, remove the current and last history item
        // Navigation end will re-add the one we are going to
        if (navigationEvent.navigationTrigger === 'popstate' && this.history.length > 0) {
          this.history.pop();
        }
      }
    });

    // NavigationEnd
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      const navigationEvent = event as NavigationEnd;

      if (this.isErrorPage(navigationEvent.urlAfterRedirects)) {
        // If we're landing on the error page, we don't want to show the /error/:code route
        // We want to show the url that the user tried to land on that caused the error instead
        this.location.replaceState(this.lastSuccessfulNavigationStartUrl);
      } else {
        // If the top history item is not the incoming URL add it to history
        if (this.history[this.history.length - 1] !== navigationEvent.urlAfterRedirects) {
          this.history.push(navigationEvent.urlAfterRedirects);
        }
        this.emitPageTitleChanged();
      }
    });
  }

  setHomePath(path: string): void {
    this.assertNavigationInitialized();

    this.solisSessionService.localSession.set(this.homePathKey, this.cleanRoute(path));
  }

  getHomePath(): string {
    return this.solisSessionService.localSession.get<string>(this.homePathKey) || '';
  }

  isHomePage(): boolean {
    const homeRoute = '/' + this.getHomePath();
    return `${this.getLocation().pathname}${this.getLocation().search}` === homeRoute;
  }

  navigateNewTab(path: string): void {
    const currentHomePath = this.getHomePath();
    const currentBreadcrumbHistory = this.solisSessionService.localSession.get(SessionKeys.breadcrumbs);
    this.solisSessionService.localSession.set(this.homePathKey, this.cleanRoute(path));
    this.solisSessionService.localSession.set(SessionKeys.breadcrumbs, []);
    window.open(path, '_blank');
    this.solisSessionService.localSession.set(this.homePathKey, currentHomePath);
    this.solisSessionService.localSession.set(SessionKeys.breadcrumbs, currentBreadcrumbHistory);
  }

  navigateHome(extras?: NavigationExtras): Promise<boolean> {
    this.assertNavigationInitialized();

    const homeRoute = this.buildHomeRoute();

    return this.router.navigateByUrl(homeRoute, extras);
  }

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    const prefixUrl = this.buildUrl([this.getRoutePrefix()]);

    let isAbsolute = false;

    if (commands && commands.length > 0) {
      isAbsolute = commands[0].toString().startsWith('/');
    }

    // Insert a segment for the prefix for absolute routing requests
    if (isAbsolute) {
      // Check to see if user already put on the prefix
      // Root prefix doesn't count
      if (commands[0].toLowerCase() !== prefixUrl.toLowerCase() && prefixUrl !== '/') {
        commands.splice(0, 0, prefixUrl);
      }

      // Need to strip off leading slashes for all items after the first
      if (commands.length > 1) {
        for (let i = 1; i < commands.length; i++) {
          commands[i] = this.cleanRoute(commands[i]);
        }
      }
    }

    return this.router.navigate(commands, extras);
  }

  navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions): Promise<boolean> {
    return this.router.navigateByUrl(url, extras);
  }

  // GO BACK TO PREVIOUS PAGE
  navigateBack(backupRoute = '/'): void {
    // If history exists go back to latest position, otherwise go home
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(backupRoute);
    }
  }

  buildHomeRoute() {
    return this.buildUrl([this.getRoutePrefix(), this.getHomePath()]);
  }

  reloadPage(currentUrl: string) {
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }

  private emitPageTitleChanged() {
    let title = '';
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    if (route.snapshot && route.snapshot && route.snapshot.title) {
      title = route.snapshot.title;
    }
    this.eventBus.emit(new BusEvent<string>(EventNames.pageTitleChanged, title));
  }

  private buildUrl(parts: string[]) {
    let url = parts
      .filter((p) => p)
      .map((p) => this.cleanRoute(p))
      .join('/');

    return `/${url}`;
  }

  private cleanRoute(route: string): string {
    return StringHelper.trimString(route, '/');
  }

  private getRoutePrefix(): string {
    switch (this.solisSessionService.sessionType!) {
      case SolisSessionType.Advisor:
        return ADVISOR_ROOT_PREFIX;
      case SolisSessionType.Partner:
        return PARTNER_ROOT_PREFIX;
      default:
        throw Error(WebHelper.formatErrorMessage('Unknown session type'));
    }
  }

  private isErrorPage(url: string): boolean {
    return url.includes('errors');
  }

  private assertNavigationInitialized(): void {
    const sessionType = this.solisSessionService.sessionType;

    if (!sessionType) {
      throw Error(
        WebHelper.formatErrorMessage(
          'Attempted to navigate home before a session type has been set. Make sure that an active firm has been set before calling this.'
        )
      );
    }
  }

  private getLocation() {
    return window.location;
  }
}
