<div class="wrapper" id="FIDxWrapper">
  <app-global></app-global>
  <app-advisor-navbar [hidden]="!viewModel.isNavbarVisible"></app-advisor-navbar>
  <app-impersonation [hidden]="!viewModel.isImpersonationVisible"></app-impersonation>
  <app-advisor-sub-header [hidden]="!viewModel.isSubHeaderVisible"></app-advisor-sub-header>
  <div #advisorContent class="main-panel">
    <router-outlet></router-outlet>
  </div>
  <app-advisor-footer [hidden]="!viewModel.isFooterVisible"></app-advisor-footer>
</div>
