import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpContextToken } from '@angular/common/http';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from 'framework';

/* istanbul ignore next */
export const REDIRECT_ON_404 = new HttpContextToken<boolean>(() => false);
/* istanbul ignore next */
export const SUPPRESS_ERROR_CODES = new HttpContextToken<number[]>(() => []);

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (!(errorResponse.error instanceof ErrorEvent)) {
          if (errorResponse.status === 403) {
            this.router.navigate(['errors', '403']);
            return EMPTY;
          }

          if (errorResponse.status === 404 && request.context.get(REDIRECT_ON_404) === true) {
            this.router.navigate(['errors', '404']);
            return EMPTY;
          }

          const suppressCodes = request.context.get(SUPPRESS_ERROR_CODES) as number[];

          if (suppressCodes.some((c) => c === errorResponse.status)) {
            return throwError(() => errorResponse) as Observable<HttpEvent<any>>;
          }

          let errorMessage = '<ul>';

          // If BadRequest or UnprocessableEntity, we'll get error details
          if (errorResponse.status === 400 || errorResponse.status === 422) {
            const errorsMap = new Map<string, Array<string>>(Object.entries(errorResponse.error.errors));

            errorsMap.forEach((entries, key) => {
              errorMessage += `<li>${key}<ul>`;
              entries.forEach((entry) => {
                errorMessage += `<li>${entry}</li>`;
              });
              errorMessage += '</ul></li>';
            });
          }

          errorMessage += '</ul>';
          // Server side error
          this.toastService.error(errorMessage, `Unexpected API Response: ${errorResponse.status}`);
        }

        return throwError(() => errorResponse) as Observable<HttpEvent<any>>;
      })
    );
  }
}
