import { Component, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ToggleButtonModule } from 'primeng/togglebutton';

import { ComponentBase } from 'framework';

import { PartnerNavbarViewModel } from './partner-navbar.view-models';

@Component({
  selector: 'app-partner-navbar',
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule, NgbModule, ToggleButtonModule],
  providers: [PartnerNavbarViewModel],
  templateUrl: './partner-navbar.component.html',
  styleUrls: ['./partner-navbar.component.scss']
})
export class PartnerNavbarComponent extends ComponentBase<PartnerNavbarViewModel> {
  constructor(protected override injector: Injector, viewModel: PartnerNavbarViewModel) {
    super(injector, viewModel);
  }
}
