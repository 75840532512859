import { Component, Injector } from '@angular/core';

import { ComponentBase } from 'framework';

import { ImpersonationViewModel } from './impersonation.view-models';

@Component({
  selector: 'app-impersonation',
  standalone: true,
  imports: [],
  providers: [ImpersonationViewModel],
  templateUrl: './impersonation.component.html',
  styleUrls: ['./impersonation.component.scss']
})
export class ImpersonationComponent extends ComponentBase<ImpersonationViewModel> {
  constructor(
    protected override injector: Injector,
    viewModel: ImpersonationViewModel
  ) {
    super(injector, viewModel);
  }
}
