import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { EventBus } from '../event-bus/event-bus';
import { BusRequest } from '../event-bus/event-bus.models';
import { Injector } from '@angular/core';

export class SubscriptionService {
  private subscriptionGroup: Subscription;
  private registeredRequests: string[] = [];
  private injector: Injector;
  private _eventBus: EventBus | null = null;
  private _route: ActivatedRoute | null = null;
  private _router: Router | null = null;

  constructor(injector: Injector) {
    this.injector = injector;
    this.subscriptionGroup = new Subscription();
  }

  private get eventBus(): EventBus {
    this._eventBus = this._eventBus ?? this.injector.get(EventBus);
    return this._eventBus;
  }

  private get route(): ActivatedRoute {
    this._route = this._route ?? this.injector.get(ActivatedRoute);
    return this._route;
  }

  private get router(): Router {
    this._router = this._router ?? this.injector.get(Router);
    return this._router;
  }

  public destroy() {
    this.subscriptionGroup.unsubscribe();

    this.registeredRequests.forEach((request) => {
      this.eventBus.unregisterRequestHandler(request);
    });

    this.registeredRequests = [];
  }

  public subscribeEvent<T>(eventType: string, action: any, replayCurrentValueOnSubscribe: boolean = false) {
    const subscription = this.eventBus.on<T>(eventType, action, replayCurrentValueOnSubscribe);
    this.subscribe(subscription);
  }

  public subscribeRouteParamMap(next: (value: any) => void) {
    const subscription = this.route.paramMap.subscribe(next);
    this.subscribe(subscription);
  }

  public subscribeRouteQueryParams(next: (value: any) => void) {
    const subscription = this.route.queryParams.subscribe(next);
    this.subscribe(subscription);
  }

  public subscribeRouterEvent(next: (value: any) => void) {
    const subscription = this.router.events.subscribe(next);
    this.subscribe(subscription);
  }

  public subscribeObservable<T>(observable: Observable<T>, next: (value: any) => void) {
    const subscription = observable.subscribe(next);
    this.subscribe(subscription);
  }

  private subscribe(subscription: Subscription) {
    this.subscriptionGroup.add(subscription);
  }

  public registerRequestHandler<U = void, V = void>(request: string, requestHandler: (request: BusRequest<U>) => V) {
    this.eventBus.registerRequestHandler(request, requestHandler);
    this.registeredRequests.push(request);
  }
}
