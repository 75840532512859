import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { IModalValueProvider } from 'framework';

import { SolisFormViewModelBase } from '../../solis-form-view-model.base';

@Injectable()
export class TermsAndConditionsViewModel extends SolisFormViewModelBase implements IModalValueProvider<any> {
  public valueSubject!: Subject<any | undefined>; // TODO: DTO object to be sent to backend
  header: string = 'Terms & Conditions';
  minWidth?: number;
  maxWidth?: number;

  termsUrl: string = 'https://fidx.io/terms';
  privacyUrl: string = 'https://fidx.io/privacy';

  get minWidthCalc(): number {
    return this.minWidth || 300;
  }

  get maxWidthCalc(): number {
    return this.maxWidth || 400;
  }

  constructor() {
    super();
  }

  async initialize() {}

  override initializeForm(formBuilder: FormBuilder) {
    return formBuilder.group({
      terms: [, [Validators.requiredTrue]],
      privacy: [, [Validators.requiredTrue]]
    });
  }

  async submitForm() {
    this.submit(async () => {
      this.valueSubject.next(this.isFormValid());
    });
  }
}
