import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private activeTimeout: NodeJS.Timeout | null = null;

  constructor(private messageService: MessageService) {}

  success(message?: string, header?: string): void {
    this.messageService.clear('progress');
    this.sendBasicMessage('success', message, header);
  }

  info(message?: string, header?: string): void {
    this.messageService.clear('progress');
    this.sendBasicMessage('info', message, header);
  }

  warn(message?: string, header?: string): void {
    this.messageService.clear('progress');
    this.sendBasicMessage('warn', message, header);
  }

  error(message?: string, header?: string): void {
    this.messageService.clear('progress');
    this.sendBasicMessage('error', message, header);
  }

  progress(message?: string, timeoutMs: number = 500): void {
    this.activeTimeout = setTimeout(() => {
      this.messageService.add({
        severity: 'info',
        detail: message,
        closable: false,
        sticky: true,
        contentStyleClass: 'in-progress',
        key: 'progress'
      });
    }, timeoutMs);
  }

  clear(key: string = ''): void {
    if (this.activeTimeout) {
      clearTimeout(this.activeTimeout);
      this.activeTimeout = null;
    }

    key.length > 0 ? this.messageService.clear(key) : this.messageService.clear();
  }

  private sendBasicMessage(severity: string, message?: string, header?: string): void {
    this.messageService.add({ severity: severity, summary: header, detail: message, key: 'toast' });
  }
}
