import { WebHelper } from '../../helpers/web/web.helper';
import { SettingDto } from '../gateway-api-client/dtos/infrastructure.dtos';

export class SettingsGetter {
  constructor(private settings: SettingDto[] = []) {}

  // GET VALUE FOR SETTING BY KEY AND TYPE
  getSettingValue<T>(key: string, required: boolean = true): T | null {
    const setting = this.settings!.filter((s) => s.key!.toLowerCase() === key.toLowerCase())[0];

    if (!setting) {
      if (required) {
        throw new Error(WebHelper.formatErrorMessage(`Required setting '${key}' was not found. Check server setting configuration.`));
      } else {
        return null;
      }
    }

    return <T | null>(setting.value ?? null);
  }
}
