import { SettingEntityDto, SettingSearchCriteriaDto } from '../gateway-api-client/dtos/infrastructure.dtos';
import { SettingEntityType } from '../gateway-api-client/dtos/infrastructure.enums';

export class SettingsSearchBuilder {
  private keys: string[] = [];
  private tags: string[] = [];
  private entities: SettingEntityDto[] = [];

  withKeys(...keys: string[]): SettingsSearchBuilder {
    this.keys.push(...keys);
    return this;
  }

  withTags(...tags: string[]): SettingsSearchBuilder {
    this.tags.push(...tags);
    return this;
  }

  withEntity(type: SettingEntityType, code: string): SettingsSearchBuilder {
    const entity = new SettingEntityDto();
    entity.type = type;
    entity.code = code;

    this.entities.push(entity);

    return this;
  }

  build(): SettingSearchCriteriaDto {
    const searchCriteria = new SettingSearchCriteriaDto();
    searchCriteria.keys?.push(...this.keys);
    searchCriteria.tags?.push(...this.tags);
    searchCriteria.entities?.push(...this.entities);

    return searchCriteria;
  }
}
