import { Injectable } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  ɵFormGroupValue,
  FormGroupDirective,
  ValidatorFn,
  AbstractControl,
  Validators,
  FormArray
} from '@angular/forms';
import { Observable } from 'rxjs';
import { FormHelper } from '../helpers/form/form.helper';
import { SolisViewModelBase } from './solis-view-model.base';

@Injectable()
export abstract class SolisFormViewModelBase extends SolisViewModelBase {
  public formGroupName: string = '';
  public formArrayName: string = '';

  public form!: FormGroup;
  // BARRY TODO need to evaulate this thing
  showForm: boolean = false;

  public isSubmitting = false;

  constructor() {
    super();
  }

  // override this if you are creating the FormGroup, otherwise it is retrieved from the rootFormGroup
  public initializeForm(formBuilder: FormBuilder): FormGroup {
    return new FormGroup({});
  }

  public initializeFormData(formGroup: FormGroup) {}

  public async submit(customSubmitFunc: (value: IFormViewModelControl) => Promise<any>): Promise<void> {
    if (this.isFormValid()) {
      this.isSubmitting = true;

      await customSubmitFunc(this.form.getRawValue());

      this.isSubmitting = false;
    }
  }

  public onClickCursorEvent(event: any, type: string): void {
    FormHelper.setCursorPosition(event, type);
  }

  // UPDATE AND SET VALIDATORS FOR LIST OF FORM CONTROLS
  updateSyncValidators(keys: string[] = [], validators: ValidatorFn[] | null = null, formGroup: FormGroup = this.form): void {
    keys.forEach((key) => formGroup.controls[key]?.setValidators(validators));
  }

  // UPDATE CONTROL VALUE AND VALIDITY IF ENABLED
  updateControlValidity(keys: string[] = [], formGroup: FormGroup = this.form): void {
    keys.forEach((key) => formGroup.controls[key]?.enabled && formGroup.controls[key]?.updateValueAndValidity());
  }

  /* #region Accessors to get at the AbstractControls but with reduced functionality ***********************************/

  public control(controlName: string): IFormViewModelControl {
    return new FormViewModelControl(this.form.controls[controlName]);
  }

  public controls(): Record<string, IFormViewModelControl> {
    return Object.entries(this.form.controls).reduce(
      (acc, [key, control]) => {
        acc[key] = new FormViewModelControl(control) as IFormViewModelControl;
        return acc;
      },
      {} as Record<string, IFormViewModelControl>
    );
  }

  public groupedControl(groupName: string, controlName: string): IFormViewModelControl | null {
    const control = this.form.controls[groupName]?.get(controlName);
    return control ? new FormViewModelControl(control) : null;
  }

  public arrayGroup(groupName: string): FormArray | null {
    return (this.form.controls[groupName] as FormArray) ?? null;
  }

  public indexedControl(index: number, controlName: string): IFormViewModelControl | null {
    const formArray = (this.formArrayName !== '' ? this.form.controls[this.formArrayName] : this.form.controls) as FormArray;
    const control = formArray?.controls ? formArray?.controls[index]?.get(controlName) : null;
    return control ? new FormViewModelControl(control) : null;
  }

  /* #endregion ******************************************************************/

  /* #region Form Methods ******************************************************************/
  public getFormControlNames(): string[] {
    return Object.keys(this.form.controls);
  }

  public hasForm(): boolean {
    return !!this.form;
  }

  public isFormEnabled(): boolean {
    return this.form.enabled;
  }

  public isFormValid(): boolean {
    return this.form.valid;
  }

  public patchFormValue(value: ɵFormGroupValue<any>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.form.patchValue(value, options);
  }

  public setFormDisabled() {
    this.form.disable();
  }

  public setFormEnabled() {
    this.form.enable();
  }

  public setFormAllAsTouched() {
    this.form.markAllAsTouched();
  }

  public getFormValue() {
    return this.form.value;
  }

  // CHECK IF FORM CONTROL OF GROUP AND/OR FORM IS TOUCHED AND INVALID
  public isIndexedControlTouchedAndInvalid(index: number, controlName: string): boolean {
    const control = this.indexedControl(index, controlName);
    return control !== null && (control.pending || control.touched) && control.invalid;
  }

  public isFormTouchedAndInvalid(groupName: string, controlName: string): boolean {
    const control = this.groupedControl(groupName, controlName);
    return control !== null && (control.pending || control.touched) && control.invalid;
  }

  public isControlTouchedAndInvalid(controlName: string): boolean {
    const control = this.control(controlName);
    return control !== null && (control.pending || control.touched) && control.invalid;
  }

  // UPDATE STATUS OF A FORM GROUP (IF EXISTS), AND ENABLE OR DISABLE GROUP BASED ON PARAMETERS
  public updateFormStatus(groupName: string, isEnabled: boolean, markAsUntouchedFields: string[] = []): void {
    if (this.form.controls[groupName]) {
      if (isEnabled) {
        this.control(groupName).enable();
        // this.enableFormControlsByKey([groupName]);
        markAsUntouchedFields.forEach((key) => this.groupedControl(groupName, key)?.markAsUntouched());
      } else {
        this.control(groupName).disable();
        // this.disableFormControlsByKey([groupName]);
      }
    }
  }

  // UPDATE ANY CONDITIONAL REQUIREMENTS FOR THE FORM GROUP
  updateConditionalRequirements(groupName: string, markAllTouched?: boolean): void {
    const group = groupName !== '' ? this.form.controls[groupName] : this.form;
    if (group) {
      let hasValue = false;
      const list = Object.keys((group as FormGroup).controls);
      for (let key of list) {
        if ((group.get(key)?.value ?? '') !== '') {
          hasValue = true;
          break;
        }
      }
      list.forEach((key) => {
        hasValue ? group.get(key)!.addValidators(Validators.required) : group.get(key)!.removeValidators(Validators.required);
        group.get(key)!.updateValueAndValidity({ emitEvent: false });
      });
      if (hasValue && markAllTouched !== false) {
        group.markAllAsTouched();
      }
    }
  }

  /* #endregion ******************************************************************/

  /* #region Internal Functionality that should not be called or overridden in a FormViewModelBase implementation */

  protected override internalInitialize() {
    super.internalInitialize();
    this.internalInitializeForm();
  }

  private internalInitializeForm() {
    let rootFormGroup: FormGroupDirective;

    if (this.formGroupName.length == 0) {
      // see if the component is going to build its formGroup
      const form = this.initializeForm(new FormBuilder());
      if (form && Object.keys(form.controls).length > 0) {
        this.form = form;
      }
    }

    if (!this.form) {
      try {
        rootFormGroup = this.injector.get(FormGroupDirective);
      } catch (error) {
        console.error('FormGroupDirective must be added to the providers in the component');
        throw error;
      }

      // If formgroup name is passed use that to get the formgroup name for the component
      if (this.formGroupName.length > 0) {
        /* istanbul ignore next */
        const formGroup = rootFormGroup.control.get(this.formGroupName) ?? null;
        if (formGroup !== null) {
          this.form = formGroup as FormGroup;
          this.showForm = true;
        }
      }

      // No formgroup name is passed, assume it is root formgroup of the application
      if (!this.form) {
        this.form = rootFormGroup.control;
      }
    }

    this.initializeFormData(this.form);
  }

  /* #endregion */
}

export interface IFormViewModelControl {
  readonly isDefined: boolean;
  readonly value: any;
  readonly valueChanges: Observable<any>;
  readonly touched: boolean;

  get disabled(): boolean;
  get enabled(): boolean;
  get invalid(): boolean;
  get valid(): boolean;
  get pending(): boolean;
  get required(): boolean;
  get hasErrorRequired(): boolean;

  disable(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void;
  enable(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void;

  clearValidators(): void;
  hasValidator(validator: ValidatorFn): boolean;
  removeValidators(validators: ValidatorFn | ValidatorFn[]): void;
  setValidators(validators: ValidatorFn | ValidatorFn[] | null): void;
  addValidators(validators: ValidatorFn | ValidatorFn[]): void;

  setValue(value: any, options?: Object): void;
  markAsTouched(): void;
  markAsUntouched(): void;
  updateValueAndValidity(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void;

  hasError(error: string): boolean;
  patch(value: any, options?: Object): void;
}

export class FormViewModelControl implements IFormViewModelControl {
  private control: AbstractControl;

  constructor(control: AbstractControl) {
    this.control = control;
  }

  /* #region Passthrough functionality for AbstractControl **********************/

  get isDefined(): boolean {
    return (this.control ?? null) !== null;
  }

  get value(): any {
    return this.control.value;
  }
  get valueChanges(): Observable<any> {
    return this.control.valueChanges;
  }
  get touched(): boolean {
    return this.control.touched;
  }
  get disabled(): boolean {
    return this.control.disabled;
  }
  get enabled(): boolean {
    return this.control.enabled;
  }
  get invalid(): boolean {
    return this.control.invalid;
  }
  get valid(): boolean {
    return this.control.valid;
  }
  get pending(): boolean {
    return this.control.pending;
  }
  disable(opts?: { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined } | undefined): void {
    this.control.disable(opts);
  }
  enable(opts?: { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined } | undefined): void {
    this.control.enable(opts);
  }
  clearValidators(): void {
    this.control.clearValidators();
  }
  hasValidator(validator: ValidatorFn): boolean {
    return this.control.hasValidator(validator);
  }
  removeValidators(validators: ValidatorFn | ValidatorFn[]): void {
    this.control.removeValidators(validators);
  }
  setValidators(validators: ValidatorFn | ValidatorFn[] | null): void {
    this.control.setValidators(validators);
  }
  addValidators(validators: ValidatorFn | ValidatorFn[]): void {
    this.control.addValidators(validators);
  }
  setValue(value: any, options?: Object | undefined): void {
    this.control.setValue(value, options);
  }
  markAsTouched(): void {
    this.control.markAsTouched();
  }
  markAsUntouched(): void {
    this.control.markAsUntouched();
    this.control.markAsPristine();
  }
  updateValueAndValidity(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}): void {
    this.control.updateValueAndValidity(opts);
  }

  /* #endregion *****************************************************************/

  /* #region Extended functionality for IFormViewModelControl *******************/

  public get required(): boolean {
    return this.control?.hasValidator(Validators.required);
  }

  public get hasErrorRequired(): boolean {
    return this.hasError('required');
  }

  public hasError(error: string): boolean {
    return this.control?.hasError(error);
  }

  public patch(value: any, options?: Object) {
    this.control?.patchValue(value, options);
  }

  /* #endregion *****************************************************************/
}
