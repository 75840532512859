import { Component, Injector } from '@angular/core';
import { ComponentBase } from 'framework';
import { PartnerFooterViewModel } from './partner-footer.view-models';

@Component({
  selector: 'app-partner-footer',
  standalone: true,
  imports: [],
  providers: [PartnerFooterViewModel],
  templateUrl: './partner-footer.component.html',
  styleUrls: ['./partner-footer.component.scss']
})
export class PartnerFooterComponent extends ComponentBase<PartnerFooterViewModel> {
  constructor(protected override injector: Injector, viewModel: PartnerFooterViewModel) {
    super(injector, viewModel);
  }
}
