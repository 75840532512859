import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { BusEvent, EventBus } from 'framework';

import { EventNames } from '../../constants/event-names';
import { ApiActivityChangedEvent, RequestApiCallCancelEvent } from './models/api-activity.events';

export const API_CALL_NAME = new HttpContextToken<string | null>(() => null);

@Injectable()
export class ApiActivityInterceptor implements HttpInterceptor {
  private static readonly unknownCallName: string = 'unknown';
  private readonly inFlightRequestSubjects: Map<string, Subject<void>[]> = new Map<string, Subject<void>[]>();

  constructor(private eventBus: EventBus) {
    this.eventBus.on<RequestApiCallCancelEvent>(EventNames.requestApiCallCancel, (data: RequestApiCallCancelEvent) => {
      this.cancelApiCalls(data);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Requests are tracked by the call name. Add name to map if present
    let apiCallName = request.context.get(API_CALL_NAME);

    if (!apiCallName) {
      apiCallName = ApiActivityInterceptor.unknownCallName;
    }

    if (!this.inFlightRequestSubjects.has(apiCallName)) {
      // Initialize with empty subject array if key not present
      this.inFlightRequestSubjects.set(apiCallName, []);
    }

    // Create a subject to track this request
    const requestSubject = new Subject<void>();
    this.inFlightRequestSubjects.get(apiCallName)!.push(requestSubject);

    // Emit event indicating api activity
    this.emitApiActivityEvent();

    return next.handle(request).pipe(
      finalize(() => {
        this.updateInFlightRequests(apiCallName, requestSubject);

        this.emitApiActivityEvent();
      }),
      // If next() is called on a request subject, it will immediately complete the observable.
      // This is used above in the constructor to cancel in-flight requests
      takeUntil(requestSubject)
    );
  }

  private cancelApiCalls(data: RequestApiCallCancelEvent): void {
    // Note below that takeUntil is used on the request observable.
    // When we call next() on the in-flight request subjects, it will
    // cause the request observables to finish immediately with
    // an empty result
    let subjectsToCancel: Subject<void>[] = [];

    if (!data || data.apiCallNames.length === 0) {
      // Cancel all calls
      subjectsToCancel = [...this.inFlightRequestSubjects.values()].flat();
    } else {
      data.apiCallNames.forEach((apiCallName) => {
        const subjects = this.inFlightRequestSubjects.get(apiCallName);

        if (subjects) {
          subjectsToCancel.push(...subjects);
        }
      });
    }

    subjectsToCancel.forEach((s) => s.next());
  }

  private updateInFlightRequests(apiCallName: string | null, requestSubject: Subject<void>): void {
    // Remove subject tracking this request and emit api activity event
    let requestsByName = this.inFlightRequestSubjects.get(apiCallName!)!;
    requestsByName = requestsByName.filter((s) => s !== requestSubject);

    if (requestsByName.length > 0) {
      this.inFlightRequestSubjects.set(apiCallName!, requestsByName);
    } else {
      this.inFlightRequestSubjects.delete(apiCallName!);
    }
  }

  private emitApiActivityEvent(): void {
    const inFlightCalls = Array.from(this.inFlightRequestSubjects.keys());

    this.eventBus.emit(new BusEvent<ApiActivityChangedEvent>(EventNames.apiActivityChanged, new ApiActivityChangedEvent(inFlightCalls)));
  }
}
