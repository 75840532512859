import { Routes } from '@angular/router';
import { annuityCompareGuard } from '../../services/guards/advisor/annuity-compare/annuity-compare.guard';

/* istanbul ignore next */
export const PartnerRoutes: Routes = [
  {
    path: '',
    redirectTo: 'cases',
    pathMatch: 'full'
  },
  {
    path: 'cases',
    title: 'Cases',
    data: { layout: ['navbar', 'footer'] },
    loadComponent: () => import('../../pages/partner/cases/cases-page.component').then((m) => m.CasesPageComponent)
  },
  {
    path: 'cases/:caseProposalId/details',
    title: 'Case Details',
    data: { breadcrumb: { label: 'Case Details' } },
    loadComponent: () => import('../../pages/partner/case-details/case-details-page.component').then((m) => m.CaseDetailsPageComponent)
  },
  {
    path: 'cases/create',
    title: 'Create Case',
    data: { breadcrumb: { label: 'Create Case' } },
    loadComponent: () => import('../../pages/partner/create-case/create-case-page.component').then((m) => m.CreateCasePageComponent)
  },
  {
    path: 'cases/:caseProposalId/attach',
    title: 'Attach Documents',
    data: { breadcrumb: { label: 'Attach Documents' } },
    loadComponent: () =>
      import('../../pages/partner/attach-documents/attach-documents-page.component').then((m) => m.AttachDocumentsPageComponent)
  },
  {
    path: 'cases/:caseProposalId/illustrations/:platformAndDetailId/details',
    title: 'Illustration Details',
    data: { breadcrumb: { label: 'Illustration Details' } },
    loadComponent: () =>
      import('../../pages/partner/illustration-details/illustration-details-page.component').then((m) => m.IllustrationDetailsPageComponent)
  },
  {
    path: 'review',
    title: 'Review',
    data: { breadcrumb: { label: 'Review', topLevelNavigation: true } },
    loadComponent: () => import('../../pages/shared/review/review-page.component').then((m) => m.ReviewPageComponent)
  },
  {
    path: 'support',
    title: 'Support',
    data: { breadcrumb: { label: 'Support', topLevelNavigation: true } },
    loadComponent: () => import('../../pages/partner/support/support-page.component').then((m) => m.SupportPageComponent)
  },

  // SHARED PAGES
  {
    path: 'products',
    title: 'Select a Product',
    data: {
      breadcrumb: { label: 'Annuity Selection' }
    },
    loadComponent: () =>
      import('../../pages/shared/product-selection/product-selection-page.component').then((m) => m.ProductSelectionPageComponent)
  },
  {
    path: 'products/:cusipProductCode/details',
    title: 'Annuity Details',
    data: {
      breadcrumb: { label: 'Annuity Details' }
    },
    loadComponent: () => import('../../pages/shared/product-detail/product-detail-page.component').then((m) => m.ProductDetailPageComponent)
  },
  {
    path: 'products/:cusipProductCode/research',
    title: 'Research & Apply for an Annuity',
    data: {
      breadcrumb: { label: 'Research Center' }
    },
    loadComponent: () =>
      import('../../pages/shared/research-center/research-center-page.component').then((m) => m.ResearchCenterPageComponent)
  },
  {
    path: 'products/compare',
    canActivate: [annuityCompareGuard],
    title: 'Research & Apply for an Annuity',
    data: {
      breadcrumb: { label: 'Annuity Compare' }
    },
    loadComponent: () =>
      import('../../pages/shared/product-compare/product-compare-page.component').then((m) => m.ProductComparePageComponent)
  },
  {
    path: 'client-profile',
    title: 'Client Profile',
    data: {
      breadcrumb: { label: 'Client Profile' }
    },
    loadComponent: () => import('../../pages/shared/client-profile/client-profile-page.component').then((m) => m.ClientProfilePageComponent)
  },
  {
    path: 'proposal-team-management',
    title: 'Proposal Team Management',
    data: {
      breadcrumb: { label: 'Proposal Team Mgmt' }
    },
    loadComponent: () =>
      import('../../pages/shared/proposal-team-management/proposal-team-management-page.component').then(
        (m) => m.ProposalTeamManagementPageComponent
      )
  },
  {
    path: 'create-proposal',
    title: 'Creating Proposal',
    data: {
      breadcrumb: { label: 'Creating Proposal' }
    },
    canActivate: [],
    loadComponent: () =>
      import('../../pages/shared/create-proposal/create-proposal-page.component').then((m) => m.CreateProposalPageComponent)
  },
  {
    path: 'illustrations',
    title: 'Illustration',
    data: {
      breadcrumb: { label: 'Illustration' }
    },
    loadComponent: () => import('../../pages/shared/illustration/illustration-page.component').then((m) => m.IllustrationPageComponent)
  },
  {
    path: 'account-opening',
    title: 'Account Opening',
    data: {
      breadcrumb: { label: 'Account Opening' }
    },
    loadComponent: () =>
      import('../../pages/shared/account-opening/account-opening-page.component').then((m) => m.AccountOpeningPageComponent)
  },
  {
    path: 'profiles/:userId',
    title: 'Profile Settings',
    data: { breadcrumb: { label: 'Profile Settings' } },
    loadComponent: () =>
      import('../../pages/shared/profile-settings/profile-settings-page.component').then((m) => m.ProfileSettingsPageComponent)
  },
  {
    path: 'users',
    title: 'Users',
    data: { breadcrumb: { label: 'User List', topLevelNavigation: true }, capabilities: { all: ['solis_user_manager'] } },
    loadComponent: () => import('../../pages/shared/profiles-list/profiles-list-page.component').then((m) => m.ProfilesListPageComponent)
  },
  {
    path: 'users/add',
    title: 'Add User',
    data: {
      breadcrumb: { label: 'Add User' }
    },
    loadComponent: () =>
      import('../../pages/shared/profile-settings/profile-settings-page.component').then((m) => m.ProfileSettingsPageComponent)
  },
  {
    path: 'users/:userId',
    title: 'Profile Settings',
    data: { breadcrumb: { label: 'Profile Settings' } },
    loadComponent: () =>
      import('../../pages/shared/profile-settings/profile-settings-page.component').then((m) => m.ProfileSettingsPageComponent)
  },
  {
    path: 'account-setup',
    title: 'Account Setup',
    data: {
      layout: ['footer']
    },
    loadComponent: () => import('../../pages/shared/account-setup/account-setup-page.component').then((m) => m.AccountSetupPageComponent)
  }
];
