import { ProposalDetailPartyOrganizationDto, ProposalDetailPartyPersonDto } from '../services/gateway-api-client/dtos/proposal.dtos';

interface IClientProfileModel {
  persons?: ProposalDetailPartyPersonDto[];
  organizations?: ProposalDetailPartyOrganizationDto[];
  dtccMemberCode?: string | null;
  ownershipType?: string | null;
  isOwnerAnnuitant?: boolean | null;
  doesJointAnnuitantExist?: boolean;
  doesBeneficiaryExist?: boolean;
  isPartnerIntegration?: boolean;
  caseInstructions?: string;
  lockForm?: boolean;
}

export class ClientProfileModel implements IClientProfileModel {
  persons: ProposalDetailPartyPersonDto[];
  organizations: ProposalDetailPartyOrganizationDto[];
  dtccMemberCode?: string;
  ownershipType: string;
  isOwnerAnnuitant: boolean;
  doesJointAnnuitantExist: boolean;
  doesBeneficiaryExist: boolean;
  caseInstructions: string;

  constructor(options: IClientProfileModel = {}) {
    this.persons = options.persons || [];
    this.organizations = options.organizations || [];
    this.dtccMemberCode = options.dtccMemberCode ?? undefined;
    this.ownershipType = options.ownershipType || '';
    this.isOwnerAnnuitant = options.isOwnerAnnuitant || false;
    this.doesJointAnnuitantExist = options.doesJointAnnuitantExist || false;
    this.doesBeneficiaryExist = options.doesBeneficiaryExist || false;
    this.caseInstructions = options.caseInstructions || '';
  }
}
